@import url(//fonts.googleapis.com/css?family=Roboto:300,400,400italic,500,700);
@charset "UTF-8";
/*!
 *
 * Centric - Bootstrap Admin Template
 *
 * Version: 1.7
 * Author: @themicon_co
 * Website: http://themicon.co
 * License: https://wrapbootstrap.com/help/licenses
 *
 */

/*//Primary: #133d55*/
/*//Primary dark: #005829*/
/*//Accent: #ff5722*/


/**
 * Bootstrap Reset
 */
*:focus {
  outline: 0 !important;
}
a,
.btn-link {
  outline: none !important;
  color: #133d55;
  -webkit-transition: color 0.2s ease;
  transition: color 0.2s ease;
}
a:focus,
.btn-link:focus,
a:hover,
.btn-link:hover {
  color: #133d55;
  cursor: pointer;
}
a.text-muted:hover,
.btn-link.text-muted:hover,
a.text-muted:focus,
.btn-link.text-muted:focus {
  color: #898989;
}
.text-muted {
  color: #a2a2a2;
}
hr {
  border-top: 1px solid rgba(162, 162, 162, 0.36);
}
code {
  margin-left: 1px;
  margin-right: 1px;
  -webkit-font-smoothing: auto;
  padding: 0.125em 0.35em;
  border-radius: 2px;
  font-size: 1.4rem;
  background: rgba(0, 0, 0, 0.045);
  color: #106CC8;
}
pre {
  background-color: rgba(162, 162, 162, 0.12);
  border-color: rgba(162, 162, 162, 0.26);
  color: inherit;
}
.img-thumbnail {
  border-radius: 1px;
  border: 0;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.14), 0 4px 5px 0 rgba(0, 0, 0, 0.098), 0 1px 10px 0 rgba(0, 0, 0, 0.014);
}
.page-header {
  border-bottom-color: rgba(162, 162, 162, 0.06);
}
.breadcrumb {
  font-weight: normal;
  border-radius: 0;
  color: #a2a2a2;
  padding: 20px 20px;
  background-color: #fff;
  border-bottom: 1px solid rgba(162, 162, 162, 0.16);
}
.btn {
  outline: none !important;
  border-radius: 2px;
  -webkit-transition-property: background-color, color, box-shadow;
  transition-property: background-color, color, box-shadow;
  -webkit-transition-duration: .1s, .2s, .2s;
  transition-duration: .1s, .2s, .2s;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
}
.btn:active,
.btn.active {
  box-shadow: 0 0 0 #000;
}
.btn-default {
  color: #6C7177;
  background-color: #fff;
  border-color: #ECEFF1;
}
.btn-default:focus,
.btn-default.focus {
  color: #6C7177;
  background-color: #f5f5f5;
  border-color: #e0e5e8;
}
.btn-default:hover {
  color: #6C7177;
  background-color: #f5f5f5;
  border-color: #e0e5e8;
}
.btn-default:active,
.btn-default.active,
.open > .dropdown-toggle.btn-default {
  color: #6C7177;
  background-color: #ebebeb;
  border-color: #e9edef;
}
.btn-default:active:hover,
.btn-default.active:hover,
.open > .dropdown-toggle.btn-default:hover,
.btn-default:active:focus,
.btn-default.active:focus,
.open > .dropdown-toggle.btn-default:focus,
.btn-default:active.focus,
.btn-default.active.focus,
.open > .dropdown-toggle.btn-default.focus {
  color: #6C7177;
  background-color: #ebebeb;
  border-color: #e9edef;
}
.btn-default:active,
.btn-default.active,
.open > .dropdown-toggle.btn-default {
  background-image: none;
}
.btn-default.disabled,
.btn-default[disabled],
fieldset[disabled] .btn-default,
.btn-default.disabled:hover,
.btn-default[disabled]:hover,
fieldset[disabled] .btn-default:hover,
.btn-default.disabled:focus,
.btn-default[disabled]:focus,
fieldset[disabled] .btn-default:focus,
.btn-default.disabled.focus,
.btn-default[disabled].focus,
fieldset[disabled] .btn-default.focus,
.btn-default.disabled:active,
.btn-default[disabled]:active,
fieldset[disabled] .btn-default:active,
.btn-default.disabled.active,
.btn-default[disabled].active,
fieldset[disabled] .btn-default.active {
  background-color: #fff;
  border-color: #ECEFF1;
}
.btn-default .badge {
  color: #fff;
  background-color: #6C7177;
}
.btn-primary {
  color: #133d55;
  background-color: #e2edee;
  border-color: #e2edee;
}
.btn-primary:focus,
.btn-primary.focus {
  color: #133d55;
  background-color: #e2edee;
  border-color: #e2edee;
}
.btn-primary:hover {
  color: #133d55;
  background-color: #e2edee;
  border-color: #e2edee;
}
.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
  color: #133d55;
  background-color: #e2edee;
  border-color: #e2edee;
}
.btn-primary:active:hover,
.btn-primary.active:hover,
.open > .dropdown-toggle.btn-primary:hover,
.btn-primary:active:focus,
.btn-primary.active:focus,
.open > .dropdown-toggle.btn-primary:focus,
.btn-primary:active.focus,
.btn-primary.active.focus,
.open > .dropdown-toggle.btn-primary.focus {
  color: #133d55;
  background-color: #e2edee;
  border-color: #e2edee;
}
.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
  background-image: none;
}
.btn-primary.disabled,
.btn-primary[disabled],
fieldset[disabled] .btn-primary,
.btn-primary.disabled:hover,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary:hover,
.btn-primary.disabled:focus,
.btn-primary[disabled]:focus,
fieldset[disabled] .btn-primary:focus,
.btn-primary.disabled.focus,
.btn-primary[disabled].focus,
fieldset[disabled] .btn-primary.focus,
.btn-primary.disabled:active,
.btn-primary[disabled]:active,
fieldset[disabled] .btn-primary:active,
.btn-primary.disabled.active,
.btn-primary[disabled].active,
fieldset[disabled] .btn-primary.active {
  background-color: #e2edee;
  border-color: #e2edee;
}
.btn-primary .badge {
  color: #e2edee;
  background-color: #133d55;
}
.btn-success {
  color: #fff;
  background-color: #4CAF50;
  border-color: #4CAF50;
}
.btn-success:focus,
.btn-success.focus {
  color: #fff;
  background-color: #46a149;
  border-color: #46a149;
}
.btn-success:hover {
  color: #fff;
  background-color: #46a149;
  border-color: #46a149;
}
.btn-success:active,
.btn-success.active,
.open > .dropdown-toggle.btn-success {
  color: #fff;
  background-color: #409343;
  border-color: #4aab4e;
}
.btn-success:active:hover,
.btn-success.active:hover,
.open > .dropdown-toggle.btn-success:hover,
.btn-success:active:focus,
.btn-success.active:focus,
.open > .dropdown-toggle.btn-success:focus,
.btn-success:active.focus,
.btn-success.active.focus,
.open > .dropdown-toggle.btn-success.focus {
  color: #fff;
  background-color: #409343;
  border-color: #4aab4e;
}
.btn-success:active,
.btn-success.active,
.open > .dropdown-toggle.btn-success {
  background-image: none;
}
.btn-success.disabled,
.btn-success[disabled],
fieldset[disabled] .btn-success,
.btn-success.disabled:hover,
.btn-success[disabled]:hover,
fieldset[disabled] .btn-success:hover,
.btn-success.disabled:focus,
.btn-success[disabled]:focus,
fieldset[disabled] .btn-success:focus,
.btn-success.disabled.focus,
.btn-success[disabled].focus,
fieldset[disabled] .btn-success.focus,
.btn-success.disabled:active,
.btn-success[disabled]:active,
fieldset[disabled] .btn-success:active,
.btn-success.disabled.active,
.btn-success[disabled].active,
fieldset[disabled] .btn-success.active {
  background-color: #4CAF50;
  border-color: #4CAF50;
}
.btn-success .badge {
  color: #4CAF50;
  background-color: #fff;
}
.btn-info {
  color: #fff;
  background-color: #03A9F4;
  border-color: #03A9F4;
}
.btn-info:focus,
.btn-info.focus {
  color: #fff;
  background-color: #039be0;
  border-color: #039be0;
}
.btn-info:hover {
  color: #fff;
  background-color: #039be0;
  border-color: #039be0;
}
.btn-info:active,
.btn-info.active,
.open > .dropdown-toggle.btn-info {
  color: #fff;
  background-color: #038dcc;
  border-color: #03a6ef;
}
.btn-info:active:hover,
.btn-info.active:hover,
.open > .dropdown-toggle.btn-info:hover,
.btn-info:active:focus,
.btn-info.active:focus,
.open > .dropdown-toggle.btn-info:focus,
.btn-info:active.focus,
.btn-info.active.focus,
.open > .dropdown-toggle.btn-info.focus {
  color: #fff;
  background-color: #038dcc;
  border-color: #03a6ef;
}
.btn-info:active,
.btn-info.active,
.open > .dropdown-toggle.btn-info {
  background-image: none;
}
.btn-info.disabled,
.btn-info[disabled],
fieldset[disabled] .btn-info,
.btn-info.disabled:hover,
.btn-info[disabled]:hover,
fieldset[disabled] .btn-info:hover,
.btn-info.disabled:focus,
.btn-info[disabled]:focus,
fieldset[disabled] .btn-info:focus,
.btn-info.disabled.focus,
.btn-info[disabled].focus,
fieldset[disabled] .btn-info.focus,
.btn-info.disabled:active,
.btn-info[disabled]:active,
fieldset[disabled] .btn-info:active,
.btn-info.disabled.active,
.btn-info[disabled].active,
fieldset[disabled] .btn-info.active {
  background-color: #03A9F4;
  border-color: #03A9F4;
}
.btn-info .badge {
  color: #03A9F4;
  background-color: #fff;
}
.btn-warning {
  color: #fff;
  background-color: #FFB300;
  border-color: #FFB300;
}
.btn-warning:focus,
.btn-warning.focus {
  color: #fff;
  background-color: #eba500;
  border-color: #eba500;
}
.btn-warning:hover {
  color: #fff;
  background-color: #eba500;
  border-color: #eba500;
}
.btn-warning:active,
.btn-warning.active,
.open > .dropdown-toggle.btn-warning {
  color: #fff;
  background-color: #d69600;
  border-color: #faaf00;
}
.btn-warning:active:hover,
.btn-warning.active:hover,
.open > .dropdown-toggle.btn-warning:hover,
.btn-warning:active:focus,
.btn-warning.active:focus,
.open > .dropdown-toggle.btn-warning:focus,
.btn-warning:active.focus,
.btn-warning.active.focus,
.open > .dropdown-toggle.btn-warning.focus {
  color: #fff;
  background-color: #d69600;
  border-color: #faaf00;
}
.btn-warning:active,
.btn-warning.active,
.open > .dropdown-toggle.btn-warning {
  background-image: none;
}
.btn-warning.disabled,
.btn-warning[disabled],
fieldset[disabled] .btn-warning,
.btn-warning.disabled:hover,
.btn-warning[disabled]:hover,
fieldset[disabled] .btn-warning:hover,
.btn-warning.disabled:focus,
.btn-warning[disabled]:focus,
fieldset[disabled] .btn-warning:focus,
.btn-warning.disabled.focus,
.btn-warning[disabled].focus,
fieldset[disabled] .btn-warning.focus,
.btn-warning.disabled:active,
.btn-warning[disabled]:active,
fieldset[disabled] .btn-warning:active,
.btn-warning.disabled.active,
.btn-warning[disabled].active,
fieldset[disabled] .btn-warning.active {
  background-color: #FFB300;
  border-color: #FFB300;
}
.btn-warning .badge {
  color: #FFB300;
  background-color: #fff;
}
.btn-danger {
  color: #fff;
  background-color: #F44336;
  border-color: #F44336;
}
.btn-danger:focus,
.btn-danger.focus {
  color: #fff;
  background-color: #f33123;
  border-color: #f33123;
}
.btn-danger:hover {
  color: #fff;
  background-color: #f33123;
  border-color: #f33123;
}
.btn-danger:active,
.btn-danger.active,
.open > .dropdown-toggle.btn-danger {
  color: #fff;
  background-color: #f21f0f;
  border-color: #f43e31;
}
.btn-danger:active:hover,
.btn-danger.active:hover,
.open > .dropdown-toggle.btn-danger:hover,
.btn-danger:active:focus,
.btn-danger.active:focus,
.open > .dropdown-toggle.btn-danger:focus,
.btn-danger:active.focus,
.btn-danger.active.focus,
.open > .dropdown-toggle.btn-danger.focus {
  color: #fff;
  background-color: #f21f0f;
  border-color: #f43e31;
}
.btn-danger:active,
.btn-danger.active,
.open > .dropdown-toggle.btn-danger {
  background-image: none;
}
.btn-danger.disabled,
.btn-danger[disabled],
fieldset[disabled] .btn-danger,
.btn-danger.disabled:hover,
.btn-danger[disabled]:hover,
fieldset[disabled] .btn-danger:hover,
.btn-danger.disabled:focus,
.btn-danger[disabled]:focus,
fieldset[disabled] .btn-danger:focus,
.btn-danger.disabled.focus,
.btn-danger[disabled].focus,
fieldset[disabled] .btn-danger.focus,
.btn-danger.disabled:active,
.btn-danger[disabled]:active,
fieldset[disabled] .btn-danger:active,
.btn-danger.disabled.active,
.btn-danger[disabled].active,
fieldset[disabled] .btn-danger.active {
  background-color: #F44336;
  border-color: #F44336;
}
.btn-danger .badge {
  color: #F44336;
  background-color: #fff;
}
.text-primary {
  color: #e2edee;
}
.text-success {
  color: #4CAF50;
}
.text-info {
  color: #03A9F4;
}
.text-warning {
  color: #FFB300;
}
.text-danger {
  color: #F44336;
}
.pagination > li > a,
.pagination > li > span {
  color: inherit;
  background-color: rgba(240, 240, 240, 0.06);
  border-color: rgba(162, 162, 162, 0.16);
}
.pagination > li > span {
  cursor: default;
}
.pagination > li > span:hover,
.pagination > li > span:focus {
  background-color: transparent !important;
}
.pagination > li > a:focus,
.pagination > li > a:hover {
  background-color: #e2edee;
  border-color: #e2edee;
  color: #fff;
}
.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover {
  background-color: #e2edee;
  border-color: #e2edee;
  color: #fff;
}
.pagination > .disabled > span,
.pagination > .disabled > span:hover,
.pagination > .disabled > span:focus,
.pagination > .disabled > a,
.pagination > .disabled > a:hover,
.pagination > .disabled > a:focus {
  background-color: rgba(240, 240, 240, 0.06);
  border-color: rgba(162, 162, 162, 0.16);
}
.pager > li > a,
.pager > li > span {
  color: inherit;
  background-color: rgba(240, 240, 240, 0.06);
  border-color: rgba(162, 162, 162, 0.36);
}
.pager > li > a:hover,
.pager > li > span:hover,
.pager > li > a:focus,
.pager > li > span:focus {
  background-color: rgba(162, 162, 162, 0.16);
}
.pager .disabled > a,
.pager .disabled > a:focus,
.pager .disabled > a:hover,
.pager .disabled > span {
  background-color: rgba(162, 162, 162, 0.16);
}
.label-primary {
  background-color: #e2edee;
}
.label-info {
  background-color: #03A9F4;
}
.label-success {
  background-color: #4CAF50;
}
.label-warning {
  background-color: #FFB300;
}
.label-danger {
  background-color: #F44336;
}
.alert-primary {
  background-color: #e2edee;
  border-color: #e2edee;
  color: #fff;
}
.alert-primary hr {
  border-top-color: #2a7aff;
}
.alert-primary .alert-link {
  color: #e6e6e6;
}
.alert-primary a,
.alert-primary .alert-link {
  color: rgba(255, 255, 255, 0.58);
}
.alert-success {
  background-color: #4CAF50;
  border-color: #4CAF50;
  color: #fff;
}
.alert-success hr {
  border-top-color: #449d48;
}
.alert-success .alert-link {
  color: #e6e6e6;
}
.alert-success a,
.alert-success .alert-link {
  color: rgba(255, 255, 255, 0.58);
}
.alert-info {
  background-color: #03A9F4;
  border-color: #03A9F4;
  color: #fff;
}
.alert-info hr {
  border-top-color: #0398db;
}
.alert-info .alert-link {
  color: #e6e6e6;
}
.alert-info a,
.alert-info .alert-link {
  color: rgba(255, 255, 255, 0.58);
}
.alert-warning {
  background-color: #FFB300;
  border-color: #FFB300;
  color: #fff;
}
.alert-warning hr {
  border-top-color: #e6a100;
}
.alert-warning .alert-link {
  color: #e6e6e6;
}
.alert-warning a,
.alert-warning .alert-link {
  color: rgba(255, 255, 255, 0.58);
}
.alert-danger {
  background-color: #F44336;
  border-color: #F44336;
  color: #fff;
}
.alert-danger hr {
  border-top-color: #f32c1e;
}
.alert-danger .alert-link {
  color: #e6e6e6;
}
.alert-danger a,
.alert-danger .alert-link {
  color: rgba(255, 255, 255, 0.58);
}
.alert-dismissable .close,
.alert-dismissible .close {
  color: rgba(0, 0, 0, 0.75);
}
.list-group .list-group-item {
  color: inherit;
  background-color: transparent;
  border-color: rgba(162, 162, 162, 0.12);
}
.list-group .list-group-item.active {
  color: #fff;
}
.list-group .list-group-item.disabled,
.list-group .list-group-item.disabled:focus,
.list-group .list-group-item.disabled:hover {
  background-color: rgba(162, 162, 162, 0.12);
  color: inherit;
}
.panel > .list-group .list-group-item {
  border-top: 0;
}
a.list-group-item:focus,
a.list-group-item:hover,
button.list-group-item:focus,
button.list-group-item:hover {
  background-color: transparent;
  color: #e2edee;
}
.list-group-item.active,
.list-group-item.active:focus,
.list-group-item.active:hover {
  background-color: #03A9F4;
  border-color: #03A9F4;
}
.panel {
  border-radius: 3px;
  border: 1px solid rgba(162, 162, 162, 0.16);
}
.panel .panel-heading {
  font-weight: 500;
  padding: 16px 24px;
  border-bottom: 1px solid rgba(162, 162, 162, 0.16);
}
.panel .panel-footer {
  background-color: rgba(162, 162, 162, 0.1);
  border-color: rgba(162, 162, 162, 0.1);
}
.panel-group .panel .panel-heading {
  padding: 12px 24px;
}
.panel-group .panel .panel-title a {
  text-decoration: none;
}
.panel-default {
  border-color: rgba(162, 162, 162, 0.26);
}
.panel-default > .panel-heading {
  color: #333;
  background-color: rgba(162, 162, 162, 0.075);
  border-color: rgba(162, 162, 162, 0.26);
}
.panel-default > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: rgba(162, 162, 162, 0.26);
}
.panel-default > .panel-heading .badge {
  color: rgba(162, 162, 162, 0.075);
  background-color: #333;
}
.panel-default > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: rgba(162, 162, 162, 0.26);
}
.panel-primary {
  border-color: rgba(68, 138, 255, 0.15);
}
.panel-primary > .panel-heading {
  color: #fff;
  background-color: #e2edee;
  border-color: #e2edee;
}
.panel-primary > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: rgba(68, 138, 255, 0.15);
}
.panel-primary > .panel-heading .badge {
  color: #e2edee;
  background-color: #fff;
}
.panel-primary > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: rgba(68, 138, 255, 0.15);
}
.panel-success {
  border-color: rgba(76, 175, 80, 0.15);
}
.panel-success > .panel-heading {
  color: #fff;
  background-color: #4CAF50;
  border-color: #4CAF50;
}
.panel-success > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: rgba(76, 175, 80, 0.15);
}
.panel-success > .panel-heading .badge {
  color: #4CAF50;
  background-color: #fff;
}
.panel-success > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: rgba(76, 175, 80, 0.15);
}
.panel-info {
  border-color: rgba(3, 169, 244, 0.1);
}
.panel-info > .panel-heading {
  color: #fff;
  background-color: #03A9F4;
  border-color: #03A9F4;
}
.panel-info > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: rgba(3, 169, 244, 0.1);
}
.panel-info > .panel-heading .badge {
  color: #03A9F4;
  background-color: #fff;
}
.panel-info > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: rgba(3, 169, 244, 0.1);
}
.panel-warning {
  border-color: rgba(255, 179, 0, 0.1);
}
.panel-warning > .panel-heading {
  color: #fff;
  background-color: #FFB300;
  border-color: #FFB300;
}
.panel-warning > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: rgba(255, 179, 0, 0.1);
}
.panel-warning > .panel-heading .badge {
  color: #FFB300;
  background-color: #fff;
}
.panel-warning > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: rgba(255, 179, 0, 0.1);
}
.panel-danger {
  border-color: rgba(244, 67, 54, 0.1);
}
.panel-danger > .panel-heading {
  color: #fff;
  background-color: #F44336;
  border-color: #F44336;
}
.panel-danger > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: rgba(244, 67, 54, 0.1);
}
.panel-danger > .panel-heading .badge {
  color: #F44336;
  background-color: #fff;
}
.panel-danger > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: rgba(244, 67, 54, 0.1);
}
.well {
  border: 1px solid rgba(162, 162, 162, 0.16);
  background-color: #fff;
}
.jumbotron {
  border: 1px solid rgba(162, 162, 162, 0.12);
  background-color: #fff;
}
.jumbotron p {
  font-weight: 300;
}
@media only screen and (min-width: 768px) {
  .jumbotron {
    padding: 30px 15px;
  }
}
.nav-tabs {
  padding-bottom: 1px;
}
.nav-tabs > li > a,
.nav-tabs.nav-justified > li > a {
  padding: 16px 24px;
  background-color: transparent;
  border-radius: 0;
  border: 0;
  color: inherit;
}
.nav-tabs > li > a:hover,
.nav-tabs.nav-justified > li > a:hover,
.nav-tabs > li > a:focus,
.nav-tabs.nav-justified > li > a:focus {
  border-bottom-color: rgba(162, 162, 162, 0.36);
  background-color: transparent;
  color: inherit;
}
.nav-tabs.nav-justified > li > a {
  margin-bottom: 2px;
  border-bottom-color: rgba(162, 162, 162, 0.16);
}
.nav-tabs > li.active {
  color: #133d55;
}
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:focus,
.nav-tabs > li.active > a:hover,
.nav-tabs.nav-justified > .active > a,
.nav-tabs.nav-justified > .active > a:hover,
.nav-tabs.nav-justified > .active > a:focus {
  border: 0;
  border-bottom: 2px solid;
  color: inherit;
  background-color: transparent;
}
.tab-content {
  padding: 10px 20px;
}
.nav-pills > li.active > a,
.nav-pills > li.active > a:focus,
.nav-pills > li.active > a:hover {
  background-color: #133d55;
}
.nav-pills > li + li {
  margin-left: 5px;
}
.nav > li > a:focus,
.nav > li > a:hover {
  background-color: rgba(162, 162, 162, 0.12);
}
.form-control {
  box-shadow: 0 0 0 #000 !important;
}
.form-control:focus {
  border-color: #aaa;
}
@media only screen and (max-width: 1199px) {
  input[type="text"],
  input[type="email"],
  input[type="search"],
  input[type="password"] {
    -webkit-appearance: none;
  }
}
.has-success .help-block,
.has-success .control-label,
.has-success .radio,
.has-success .checkbox,
.has-success .radio-inline,
.has-success .checkbox-inline,
.has-success.radio label,
.has-success.checkbox label,
.has-success.radio-inline label,
.has-success.checkbox-inline label {
  color: #4CAF50;
}
.has-success .form-control {
  border-color: #4CAF50;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.has-success .form-control:focus {
  border-color: #3d8b40;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #92cf94;
}
.has-success .input-group-addon {
  color: #4CAF50;
  border-color: #4CAF50;
  background-color: #4CAF50;
}
.has-success .form-control-feedback {
  color: #4CAF50;
}
.has-warning .help-block,
.has-warning .control-label,
.has-warning .radio,
.has-warning .checkbox,
.has-warning .radio-inline,
.has-warning .checkbox-inline,
.has-warning.radio label,
.has-warning.checkbox label,
.has-warning.radio-inline label,
.has-warning.checkbox-inline label {
  color: #FFB300;
}
.has-warning .form-control {
  border-color: #FFB300;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.has-warning .form-control:focus {
  border-color: #cc8f00;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ffd166;
}
.has-warning .input-group-addon {
  color: #FFB300;
  border-color: #FFB300;
  background-color: #FFB300;
}
.has-warning .form-control-feedback {
  color: #FFB300;
}
.has-error .help-block,
.has-error .control-label,
.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline,
.has-error.radio label,
.has-error.checkbox label,
.has-error.radio-inline label,
.has-error.checkbox-inline label {
  color: #F44336;
}
.has-error .form-control {
  border-color: #F44336;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.has-error .form-control:focus {
  border-color: #ea1c0d;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #f99d97;
}
.has-error .input-group-addon {
  color: #F44336;
  border-color: #F44336;
  background-color: #F44336;
}
.has-error .form-control-feedback {
  color: #F44336;
}
.input-sm,
select.input-sm {
  height: 31px;
}
legend {
  border-color: rgba(162, 162, 162, 0.25);
  color: inherit;
}
fieldset {
  padding-bottom: 20px;
  border-bottom: 1px dashed rgba(162, 162, 162, 0.25);
  margin-bottom: 20px;
}
fieldset.last-child,
fieldset:last-child {
  border-bottom: 0;
}
fieldset .form-group {
  margin-bottom: 0;
}
@media only screen and (max-width: 991px) {
  input[type="text"],
  input[type="email"],
  input[type="search"],
  input[type="password"] {
    -webkit-appearance: none;
  }
}
.table {
  font-weight: 400;
}
.table > thead > tr > th {
  border-bottom-width: 1px;
  border-color: rgba(162, 162, 162, 0.16);
  position: relative;
  vertical-align: bottom;
  text-overflow: ellipsis;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0;
  height: 48px;
  font-size: 12px;
  padding: 0 18px 0 18px !important;
  padding-bottom: 8px !important;
}
.table > tbody > tr > td {
  position: relative;
  vertical-align: middle;
  height: 48px !important;
  padding: 14px 18px;
  border-top: 1px solid rgba(0, 0, 0, 0.045);
}
.table > tbody + tbody {
  border-bottom-width: 1px;
}
.table-hover > tbody > tr:hover {
  background-color: rgba(162, 162, 162, 0.06);
}
.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: rgba(162, 162, 162, 0.06);
}
.table-bordered {
  border-color: rgba(162, 162, 162, 0.26);
}
.table-bordered > thead > tr > th,
.table-bordered > thead > tr > td {
  border-bottom-width: 1px;
}
.table-bordered > tbody > tr > td,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > td,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > thead > tr > th {
  border-color: rgba(162, 162, 162, 0.26);
}
.table > thead > tr > td.active,
.table > tbody > tr > td.active,
.table > tfoot > tr > td.active,
.table > thead > tr > th.active,
.table > tbody > tr > th.active,
.table > tfoot > tr > th.active,
.table > thead > tr.active > td,
.table > tbody > tr.active > td,
.table > tfoot > tr.active > td,
.table > thead > tr.active > th,
.table > tbody > tr.active > th,
.table > tfoot > tr.active > th {
  background-color: #e2edee;
}
.table-hover > tbody > tr > td.active:hover,
.table-hover > tbody > tr > th.active:hover,
.table-hover > tbody > tr.active:hover > td,
.table-hover > tbody > tr:hover > .active,
.table-hover > tbody > tr.active:hover > th {
  background-color: #c0ccd1;
}
.table > thead > tr > td.success,
.table > tbody > tr > td.success,
.table > tfoot > tr > td.success,
.table > thead > tr > th.success,
.table > tbody > tr > th.success,
.table > tfoot > tr > th.success,
.table > thead > tr.success > td,
.table > tbody > tr.success > td,
.table > tfoot > tr.success > td,
.table > thead > tr.success > th,
.table > tbody > tr.success > th,
.table > tfoot > tr.success > th {
  background-color: #4CAF50;
}
.table-hover > tbody > tr > td.success:hover,
.table-hover > tbody > tr > th.success:hover,
.table-hover > tbody > tr.success:hover > td,
.table-hover > tbody > tr:hover > .success,
.table-hover > tbody > tr.success:hover > th {
  background-color: #449d48;
}
.table > thead > tr > td.info,
.table > tbody > tr > td.info,
.table > tfoot > tr > td.info,
.table > thead > tr > th.info,
.table > tbody > tr > th.info,
.table > tfoot > tr > th.info,
.table > thead > tr.info > td,
.table > tbody > tr.info > td,
.table > tfoot > tr.info > td,
.table > thead > tr.info > th,
.table > tbody > tr.info > th,
.table > tfoot > tr.info > th {
  background-color: #03A9F4;
}
.table-hover > tbody > tr > td.info:hover,
.table-hover > tbody > tr > th.info:hover,
.table-hover > tbody > tr.info:hover > td,
.table-hover > tbody > tr:hover > .info,
.table-hover > tbody > tr.info:hover > th {
  background-color: #0398db;
}
.table > thead > tr > td.warning,
.table > tbody > tr > td.warning,
.table > tfoot > tr > td.warning,
.table > thead > tr > th.warning,
.table > tbody > tr > th.warning,
.table > tfoot > tr > th.warning,
.table > thead > tr.warning > td,
.table > tbody > tr.warning > td,
.table > tfoot > tr.warning > td,
.table > thead > tr.warning > th,
.table > tbody > tr.warning > th,
.table > tfoot > tr.warning > th {
  background-color: #FFB300;
}
.table-hover > tbody > tr > td.warning:hover,
.table-hover > tbody > tr > th.warning:hover,
.table-hover > tbody > tr.warning:hover > td,
.table-hover > tbody > tr:hover > .warning,
.table-hover > tbody > tr.warning:hover > th {
  background-color: #e6a100;
}
.table > thead > tr > td.danger,
.table > tbody > tr > td.danger,
.table > tfoot > tr > td.danger,
.table > thead > tr > th.danger,
.table > tbody > tr > th.danger,
.table > tfoot > tr > th.danger,
.table > thead > tr.danger > td,
.table > tbody > tr.danger > td,
.table > tfoot > tr.danger > td,
.table > thead > tr.danger > th,
.table > tbody > tr.danger > th,
.table > tfoot > tr.danger > th {
  background-color: #F44336;
}
.table-hover > tbody > tr > td.danger:hover,
.table-hover > tbody > tr > th.danger:hover,
.table-hover > tbody > tr.danger:hover > td,
.table-hover > tbody > tr:hover > .danger,
.table-hover > tbody > tr.danger:hover > th {
  background-color: #f32c1e;
}
tr.active,
tr.success,
tr.info,
tr.warning,
tr.danger {
  color: rgba(0, 0, 0, 0.67);
}
tr.active > td,
tr.success > td,
tr.info > td,
tr.warning > td,
tr.danger > td {
  border-top: 0 !important;
}
.progress {
  box-shadow: 0 0 0 #000;
  border-radius: 3px;
  border: 1px solid rgba(240, 240, 240, 0.1);
  background-color: rgba(162, 162, 162, 0.26);
}
.progress .progress-bar {
  box-shadow: 0 0 0 #000;
}
.progress.progress-sm {
  height: 16px;
}
.progress.progress-xs {
  height: 8px;
  border-radius: 0;
  border: 0;
}
.progress-bar {
  background-color: #e2edee;
}
.progress-bar-info {
  background-color: #03A9F4;
}
.progress-bar-success {
  background-color: #4CAF50;
}
.progress-bar-warning {
  background-color: #FFB300;
}
.progress-bar-danger {
  background-color: #F44336;
}
.popover {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.14), 0 2px 2px 0 rgba(0, 0, 0, 0.098), 0 1px 5px 0 rgba(0, 0, 0, 0.014);
  border-color: #ECEFF1;
  border-radius: 3px;
  color: #6C7177;
}
.popover .popover-title {
  line-height: 1;
}
.carousel .carousel-indicators {
  bottom: 0;
}
.carousel .carousel-control.left,
.carousel .carousel-control.right {
  background-image: none;
}
.carousel .carousel-control em {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 5;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
}
.dropdown-menu {
  top: 80%;
  border: 1px solid #ECEFF1;
  border-radius: 1px;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,.14),0 3px 1px -2px rgba(0,0,0,.2),0 1px 5px 0 rgba(0,0,0,.12);
}
.dropdown-menu > li > a {
  display: block;
  color: #263238;
}
.dropdown-menu > li > a:hover {
  background: #ECEFF1;
}
.dropdown-menu > li > a > em {
  margin-right: 10px;
}
.dropdown-header {
  color: #a1a2a3;
}
.navbar-default .navbar-nav .open .dropdown-menu > li > a {
  color: #666;
}
.navbar-default .navbar-nav .open .dropdown-menu > li > a:hover,
.navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
  color: #222;
}
.navbar,
.navbar .dropdown-menu {
  -webkit-filter: none !important;
          filter: none !important;
}
.badge {
  width: 20px;
  height: 20px;
  padding: 0;
  line-height: 20px;
  font-size: 12px;
  border-radius: 50%;
  color: #fff;
}
.has-badge {
  position: relative;
}
.has-badge .badge {
  position: absolute;
  top: 18%;
  right: 4px;
}

/**
 * Cards
 */
.card {
  position: relative;
  border-radius: 3px;
  background-color: #fff;
  color: #4F5256;
  border: 1px solid rgba(0, 0, 0, 0.12);
  margin-bottom: 8px;
}
@media only screen and (min-width: 480px) {
  .card {
    margin-bottom: 16px;
  }
}
@media only screen and (min-width: 992px) {
  .card {
    margin-bottom: 24px;
  }
}
.card .card-heading {
  padding: 16px;
  margin: 0;
}
.card .card-heading > .card-title {
  margin: 0;
  font-size: 18px;
}
.card .card-heading > .card-icon {
  float: right;
  color: rgba(255, 255, 255, 0.87);
  font-size: 20px;
}
.card .card-heading > small {
  color: rgba(162, 162, 162, 0.92);
  letter-spacing: .01em;
}
.card .card-body {
  position: relative;
  padding: 16px;
}
.card .card-footer {
  padding: 16px;
  border-top: 1px solid rgba(162, 162, 162, 0.12);
}
.card .card-item {
  position: relative;
  display: block;
  min-height: 120px;
}
.card .card-item > .card-item-text {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.35);
  margin: 0;
  color: #fff;
  padding: 8px;
}
.card .card-item > .card-item-text a {
  color: inherit;
}
.card .card-item > .card-item-image {
  display: block;
  width: 100%;
  height: 190px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.card .card-item.card-media {
  min-height: 280px;
  background-repeat: repeat;
  background-position: 50% 50%;
  background-size: cover;
  background-attachment: scroll;
  background-origin: padding-box;
}
.card .card-item.card-media .card-media-quote {
  padding: 16px;
  font-size: 35px;
}
@media only screen and (min-width: 768px) {
  .card .card-item.card-media .card-media-quote {
    font-size: 45px;
  }
}
.card .card-item.card-media .card-media-quote > a {
  color: inherit;
  text-decoration: none;
}
.card .card-item.card-media .card-media-quote:before {
  content: '\201C';
  display: block;
  font-size: 2em;
  line-height: 1;
  margin-top: 0.25em;
}
.card.card-transparent {
  background-color: transparent;
  border: 0;
  box-shadow: 0 0 0 #000;
}
.card .card-offset {
  position: relative;
  padding-bottom: 36px;
  z-index: 10;
}
.card .card-offset > .card-offset-item {
  position: absolute;
  top: -24px;
  left: 15px;
  right: 15px;
}
.card .card-toolbar {
  position: relative;
  width: 100%;
  min-height: 64px;
  font-size: 18px;
  line-height: 64px;
  padding-left: 22px;
  z-index: 2;
}
.card .card-subheader {
  padding: 16px 0 16px 16px;
  line-height: .75em;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: .01em;
  color: rgba(0, 0, 0, 0.54);
}
.card .card-subheader + .mda-list > .mda-list-item:first-child > .mda-list-item-text {
  padding-top: 16px;
}
.card .card-subheader + .mda-list > .mda-list-item:first-child > .mda-list-item-img,
.card .card-subheader + .mda-list > .mda-list-item:first-child > .mda-list-item-icon,
.card .card-subheader + .mda-list > .mda-list-item:first-child > .mda-list-item-initial {
  margin-top: 10px;
}
.card .card-divider {
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.card .card-divider + .card-offset {
  margin-top: -10px;
}
.card > .ui-datepicker,
.card > .ui-datepicker-responsive > .ui-datepicker {
  width: 100%;
  box-shadow: 0 0 0 #000;
  margin: 0;
}
.card > .ui-datepicker > table,
.card > .ui-datepicker-responsive > .ui-datepicker > table {
  width: 100%;
}
.card .editable-wrap {
  width: 100%;
}
.card > .list-group > .list-group-item {
  border-left: 0;
  border-right: 0;
}
.card > .list-group > .list-group-item:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.card > .list-group > .list-group-item:last-child {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom: 0;
}
.card > .table-responsive > .table,
.card > .table {
  margin-bottom: 0;
}
.card > .table-responsive {
  border: 0;
}
.card > .btn {
  border-radius: 0;
  width: 100%;
  padding-top: 16px;
  padding-bottom: 16px;
  text-align: center;
}
.card > .btn:last-child {
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}
.card.card-map {
  min-height: 280px;
}
.card.card-map .card-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border: 0;
  background-color: transparent;
}

/**
 * Easy Pie Chart
 */
.easypie-chart {
  display: inline-block;
  position: relative;
  padding: 0 6px;
}
.easypie-chart span,
.easypie-chart .percentage {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  margin-left: -50%;
  height: 30px;
  margin-top: -15px;
  line-height: 25px;
  text-align: center;
}
.easypie-chart span {
  font-size: 20px;
}
.easypie-chart canvas {
  max-width: 100%;
}

/**
 * Flot Chart
 */
.flot-chart {
  display: block;
  width: 100%;
  font-size: 12px;
}
@media only screen and (min-width: 768px) {
  .flot-chart {
    font-size: 14px;
  }
}
.flot-chart .legend > div,
.flot-chart .legend > table {
  top: 0 !important;
  background-color: transparent !important;
  color: inherit !important;
}
.flot-chart .legend > table tr td {
  padding: 3px;
}
.flot-chart .legend > table tr td:first-child {
  padding-left: 3px;
}
.flot-chart .legend > table tr td:last-child {
  padding-right: 3px;
}
.flot-chart .legend > table tr + tr td {
  padding-top: 0;
}
.flot-chart .legend > div:first-child {
  border-color: rgba(0, 0, 0, 0.1) !important;
}
.flot-chart .legend .legendColorBox > div,
.flot-chart .legend .legendColorBox > div > div {
  border-radius: 400px;
}
.flot-chart .legend .legendColorBox > div {
  border-color: rgba(162, 162, 162, 0.46) !important;
}
.flot-chart.flot-legend-left .legend > div,
.flot-chart.flot-legend-left .legend > table {
  left: 0 !important;
}
.flot-chart:not([height]) {
  height: 120px;
}
.flot-chart.flot-chart-lg {
  height: 275px;
}
.flot-chart.flot-chart-md {
  height: 190px;
}
.flot-chart.flot-chart-sm {
  height: 80px;
}
.flot-chart.flot-chart-xs {
  height: 60px;
}
.flot-chart-content {
  width: 100%;
  height: 100%;
}
.flot-pie-label {
  padding: 3px 5px;
  font-size: 10px;
  text-align: center;
  color: #fff;
}
#flotTip,
.flotTip {
  position: relative;
  padding: 8px 16px;
  font-size: 13px !important;
  font-weight: 600;
  border-radius: 2px !important;
  border-color: transparent !important;
  background-color: #455A64 !important;
  color: #fff;
  z-index: 5;
}

/**
 * Sparklines
 */
sparkline {
  display: block;
}
.jqstooltip {
  width: auto !important;
  height: auto !important;
}

/**
 * Google Material Colors (customization)
 */
.bg-pink-500 {
  background-color: #E91E63;
  color: #fff !important;
}
.bg-purple-400 {
  background-color: #AB47BC;
  color: #fff !important;
}
.bg-purple-500 {
  background-color: #9C27B0;
  color: #fff !important;
}
.bg-deep-purple-500 {
  background-color: #673AB7;
  color: #fff !important;
}
.bg-indigo-500 {
  background-color: #3F51B5;
  color: #fff !important;
}
.bg-green-50 {
  background-color: #E8F5E9;
  color: #fff !important;
}
.bg-green-500 {
  background-color: #4CAF50;
  color: #fff !important;
}
.bg-blue-500 {
  background-color: #133d55;
  color: #fff !important;
}
.bg-blue-grey-900 {
  background-color: #263238;
  color: #fff !important;
}
.bg-red-500 {
  background-color: #F44336;
  color: #fff !important;
}
.bg-primary,
.bg-red-800,
.bg-red-900,
.bg-pink-700,
.bg-pink-800,
.bg-pink-900,
.bg-purple,
.bg-purple-500,
.bg-purple-600,
.bg-purple-700,
.bg-purple-800,
.bg-purple-900,
.bg-deep-purple,
.bg-deep-purple-500,
.bg-deep-purple-600,
.bg-deep-purple-700,
.bg-deep-purple-800,
.bg-deep-purple-900,
.bg-deep-purple-a400,
.bg-deep-purple-a700,
.bg-indigo,
.bg-indigo-500,
.bg-indigo-600,
.bg-indigo-700,
.bg-indigo-800,
.bg-indigo-900,
.bg-indigo-a700,
.bg-blue-800,
.bg-blue-900,
.bg-light-blue-900,
.bg-cyan-900,
.bg-teal-800,
.bg-teal-900,
.bg-green-800,
.bg-green-900,
.bg-brown,
.bg-brown-500,
.bg-brown-600,
.bg-brown-700,
.bg-brown-800,
.bg-brown-900,
.bg-blue-grey-600,
.bg-blue-grey-700,
.bg-blue-grey-800,
.bg-blue-grey-900,
.bg-grey-600,
.bg-grey-700,
.bg-grey-800,
.bg-grey-900 {
  color: rgba(255, 255, 255, 0.97);
}
.bg-primary .text-muted,
.bg-red-800 .text-muted,
.bg-red-900 .text-muted,
.bg-pink-700 .text-muted,
.bg-pink-800 .text-muted,
.bg-pink-900 .text-muted,
.bg-purple .text-muted,
.bg-purple-500 .text-muted,
.bg-purple-600 .text-muted,
.bg-purple-700 .text-muted,
.bg-purple-800 .text-muted,
.bg-purple-900 .text-muted,
.bg-deep-purple .text-muted,
.bg-deep-purple-500 .text-muted,
.bg-deep-purple-600 .text-muted,
.bg-deep-purple-700 .text-muted,
.bg-deep-purple-800 .text-muted,
.bg-deep-purple-900 .text-muted,
.bg-deep-purple-a400 .text-muted,
.bg-deep-purple-a700 .text-muted,
.bg-indigo .text-muted,
.bg-indigo-500 .text-muted,
.bg-indigo-600 .text-muted,
.bg-indigo-700 .text-muted,
.bg-indigo-800 .text-muted,
.bg-indigo-900 .text-muted,
.bg-indigo-a700 .text-muted,
.bg-blue-800 .text-muted,
.bg-blue-900 .text-muted,
.bg-light-blue-900 .text-muted,
.bg-cyan-900 .text-muted,
.bg-teal-800 .text-muted,
.bg-teal-900 .text-muted,
.bg-green-800 .text-muted,
.bg-green-900 .text-muted,
.bg-brown .text-muted,
.bg-brown-500 .text-muted,
.bg-brown-600 .text-muted,
.bg-brown-700 .text-muted,
.bg-brown-800 .text-muted,
.bg-brown-900 .text-muted,
.bg-blue-grey-600 .text-muted,
.bg-blue-grey-700 .text-muted,
.bg-blue-grey-800 .text-muted,
.bg-blue-grey-900 .text-muted,
.bg-grey-600 .text-muted,
.bg-grey-700 .text-muted,
.bg-grey-800 .text-muted,
.bg-grey-900 .text-muted {
  color: rgba(255, 255, 255, 0.57);
}
.bg-primary .text-soft,
.bg-red-800 .text-soft,
.bg-red-900 .text-soft,
.bg-pink-700 .text-soft,
.bg-pink-800 .text-soft,
.bg-pink-900 .text-soft,
.bg-purple .text-soft,
.bg-purple-500 .text-soft,
.bg-purple-600 .text-soft,
.bg-purple-700 .text-soft,
.bg-purple-800 .text-soft,
.bg-purple-900 .text-soft,
.bg-deep-purple .text-soft,
.bg-deep-purple-500 .text-soft,
.bg-deep-purple-600 .text-soft,
.bg-deep-purple-700 .text-soft,
.bg-deep-purple-800 .text-soft,
.bg-deep-purple-900 .text-soft,
.bg-deep-purple-a400 .text-soft,
.bg-deep-purple-a700 .text-soft,
.bg-indigo .text-soft,
.bg-indigo-500 .text-soft,
.bg-indigo-600 .text-soft,
.bg-indigo-700 .text-soft,
.bg-indigo-800 .text-soft,
.bg-indigo-900 .text-soft,
.bg-indigo-a700 .text-soft,
.bg-blue-800 .text-soft,
.bg-blue-900 .text-soft,
.bg-light-blue-900 .text-soft,
.bg-cyan-900 .text-soft,
.bg-teal-800 .text-soft,
.bg-teal-900 .text-soft,
.bg-green-800 .text-soft,
.bg-green-900 .text-soft,
.bg-brown .text-soft,
.bg-brown-500 .text-soft,
.bg-brown-600 .text-soft,
.bg-brown-700 .text-soft,
.bg-brown-800 .text-soft,
.bg-brown-900 .text-soft,
.bg-blue-grey-600 .text-soft,
.bg-blue-grey-700 .text-soft,
.bg-blue-grey-800 .text-soft,
.bg-blue-grey-900 .text-soft,
.bg-grey-600 .text-soft,
.bg-grey-700 .text-soft,
.bg-grey-800 .text-soft,
.bg-grey-900 .text-soft {
  color: rgba(255, 255, 255, 0.26);
}
.bg-red,
.bg-red-500,
.bg-red-600,
.bg-red-700,
.bg-red-a200,
.bg-red-a400,
.bg-red-a700,
.bg-pink,
.bg-pink-500,
.bg-pink-600,
.bg-pink-a200,
.bg-pink-a400,
.bg-pink-a700,
.bg-purple-300,
.bg-purple-400,
.bg-purple-a200,
.bg-purple-a400,
.bg-purple-a700,
.bg-deep-purple-300,
.bg-deep-purple-400,
.bg-deep-purple-a200,
.bg-indigo-300,
.bg-indigo-400,
.bg-indigo-a200,
.bg-indigo-a400,
.bg-blue,
.bg-blue-500,
.bg-blue-600,
.bg-blue-700,
.bg-blue-a200,
.bg-blue-a400,
.bg-blue-a700,
.bg-light-blue,
.bg-light-blue-500,
.bg-light-blue-600,
.bg-light-blue-700,
.bg-light-blue-800,
.bg-light-blue-a700,
.bg-cyan,
.bg-cyan-500,
.bg-cyan-600,
.bg-cyan-700,
.bg-cyan-800,
.bg-teal,
.bg-teal-500,
.bg-teal-600,
.bg-teal-700,
.bg-green,
.bg-green-500,
.bg-green-600,
.bg-green-700,
.bg-light-green-800,
.bg-light-green-900,
.bg-lime-900,
.bg-orange-800,
.bg-orange-900,
.bg-deep-orange,
.bg-deep-orange-500,
.bg-deep-orange-600,
.bg-deep-orange-700,
.bg-deep-orange-800,
.bg-deep-orange-900,
.bg-deep-orange-a400,
.bg-deep-orange-a700,
.bg-brown-300,
.bg-brown-400,
.bg-blue-grey,
.bg-blue-grey-400,
.bg-blue-grey-500 {
  color: #fff;
}
.bg-red .text-muted,
.bg-red-500 .text-muted,
.bg-red-600 .text-muted,
.bg-red-700 .text-muted,
.bg-red-a200 .text-muted,
.bg-red-a400 .text-muted,
.bg-red-a700 .text-muted,
.bg-pink .text-muted,
.bg-pink-500 .text-muted,
.bg-pink-600 .text-muted,
.bg-pink-a200 .text-muted,
.bg-pink-a400 .text-muted,
.bg-pink-a700 .text-muted,
.bg-purple-300 .text-muted,
.bg-purple-400 .text-muted,
.bg-purple-a200 .text-muted,
.bg-purple-a400 .text-muted,
.bg-purple-a700 .text-muted,
.bg-deep-purple-300 .text-muted,
.bg-deep-purple-400 .text-muted,
.bg-deep-purple-a200 .text-muted,
.bg-indigo-300 .text-muted,
.bg-indigo-400 .text-muted,
.bg-indigo-a200 .text-muted,
.bg-indigo-a400 .text-muted,
.bg-blue .text-muted,
.bg-blue-500 .text-muted,
.bg-blue-600 .text-muted,
.bg-blue-700 .text-muted,
.bg-blue-a200 .text-muted,
.bg-blue-a400 .text-muted,
.bg-blue-a700 .text-muted,
.bg-light-blue .text-muted,
.bg-light-blue-500 .text-muted,
.bg-light-blue-600 .text-muted,
.bg-light-blue-700 .text-muted,
.bg-light-blue-800 .text-muted,
.bg-light-blue-a700 .text-muted,
.bg-cyan .text-muted,
.bg-cyan-500 .text-muted,
.bg-cyan-600 .text-muted,
.bg-cyan-700 .text-muted,
.bg-cyan-800 .text-muted,
.bg-teal .text-muted,
.bg-teal-500 .text-muted,
.bg-teal-600 .text-muted,
.bg-teal-700 .text-muted,
.bg-green .text-muted,
.bg-green-500 .text-muted,
.bg-green-600 .text-muted,
.bg-green-700 .text-muted,
.bg-light-green-800 .text-muted,
.bg-light-green-900 .text-muted,
.bg-lime-900 .text-muted,
.bg-orange-800 .text-muted,
.bg-orange-900 .text-muted,
.bg-deep-orange .text-muted,
.bg-deep-orange-500 .text-muted,
.bg-deep-orange-600 .text-muted,
.bg-deep-orange-700 .text-muted,
.bg-deep-orange-800 .text-muted,
.bg-deep-orange-900 .text-muted,
.bg-deep-orange-a400 .text-muted,
.bg-deep-orange-a700 .text-muted,
.bg-brown-300 .text-muted,
.bg-brown-400 .text-muted,
.bg-blue-grey .text-muted,
.bg-blue-grey-400 .text-muted,
.bg-blue-grey-500 .text-muted {
  color: rgba(255, 255, 255, 0.57);
}
.bg-red .text-soft,
.bg-red-500 .text-soft,
.bg-red-600 .text-soft,
.bg-red-700 .text-soft,
.bg-red-a200 .text-soft,
.bg-red-a400 .text-soft,
.bg-red-a700 .text-soft,
.bg-pink .text-soft,
.bg-pink-500 .text-soft,
.bg-pink-600 .text-soft,
.bg-pink-a200 .text-soft,
.bg-pink-a400 .text-soft,
.bg-pink-a700 .text-soft,
.bg-purple-300 .text-soft,
.bg-purple-400 .text-soft,
.bg-purple-a200 .text-soft,
.bg-purple-a400 .text-soft,
.bg-purple-a700 .text-soft,
.bg-deep-purple-300 .text-soft,
.bg-deep-purple-400 .text-soft,
.bg-deep-purple-a200 .text-soft,
.bg-indigo-300 .text-soft,
.bg-indigo-400 .text-soft,
.bg-indigo-a200 .text-soft,
.bg-indigo-a400 .text-soft,
.bg-blue .text-soft,
.bg-blue-500 .text-soft,
.bg-blue-600 .text-soft,
.bg-blue-700 .text-soft,
.bg-blue-a200 .text-soft,
.bg-blue-a400 .text-soft,
.bg-blue-a700 .text-soft,
.bg-light-blue .text-soft,
.bg-light-blue-500 .text-soft,
.bg-light-blue-600 .text-soft,
.bg-light-blue-700 .text-soft,
.bg-light-blue-800 .text-soft,
.bg-light-blue-a700 .text-soft,
.bg-cyan .text-soft,
.bg-cyan-500 .text-soft,
.bg-cyan-600 .text-soft,
.bg-cyan-700 .text-soft,
.bg-cyan-800 .text-soft,
.bg-teal .text-soft,
.bg-teal-500 .text-soft,
.bg-teal-600 .text-soft,
.bg-teal-700 .text-soft,
.bg-green .text-soft,
.bg-green-500 .text-soft,
.bg-green-600 .text-soft,
.bg-green-700 .text-soft,
.bg-light-green-800 .text-soft,
.bg-light-green-900 .text-soft,
.bg-lime-900 .text-soft,
.bg-orange-800 .text-soft,
.bg-orange-900 .text-soft,
.bg-deep-orange .text-soft,
.bg-deep-orange-500 .text-soft,
.bg-deep-orange-600 .text-soft,
.bg-deep-orange-700 .text-soft,
.bg-deep-orange-800 .text-soft,
.bg-deep-orange-900 .text-soft,
.bg-deep-orange-a400 .text-soft,
.bg-deep-orange-a700 .text-soft,
.bg-brown-300 .text-soft,
.bg-brown-400 .text-soft,
.bg-blue-grey .text-soft,
.bg-blue-grey-400 .text-soft,
.bg-blue-grey-500 .text-soft {
  color: rgba(255, 255, 255, 0.26);
}

/**
 * Animations
 */
[ui-view].ng-leave {
  display: none !important;
}
[ui-view].ng-leave.ng-fluid {
  display: block !important;
}
.ng-fluid.ng-animate {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.animated.infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}
.animated.hinge {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}
.ng-fadeIn.ng-enter {
  -webkit-animation: fadeIn 0.5s;
  animation: fadeIn 0.5s;
}
.ng-fadeIn.ng-leave {
  -webkit-animation: fadeOut 0.5s;
  animation: fadeOut 0.5s;
}
.ng-fadeInUpShort.ng-enter {
  -webkit-animation: fadeInUpShort 0.5s;
  animation: fadeInUpShort 0.5s;
}
.ng-fadeInUpShort.ng-leave {
  -webkit-animation: fadeOutDownShort 0.5s;
  animation: fadeOutDownShort 0.5s;
}
.ng-fadeInDownShort.ng-enter {
  -webkit-animation: fadeInDownShort 0.5s;
  animation: fadeInDownShort 0.5s;
}
.ng-fadeInDownShort.ng-leave {
  -webkit-animation: fadeOutUpShort 0.5s;
  animation: fadeOutUpShort 0.5s;
}
.ng-fadeInRightShort.ng-enter {
  -webkit-animation: fadeInRightShort 0.5s;
  animation: fadeInRightShort 0.5s;
}
.ng-fadeInRightShort.ng-leave {
  -webkit-animation: fadeOutLeftShort 0.5s;
  animation: fadeOutLeftShort 0.5s;
}
.ng-fadeInLeftShort.ng-enter {
  -webkit-animation: fadeInLeftShort 0.5s;
  animation: fadeInLeftShort 0.5s;
}
.ng-fadeInLeftShort.ng-leave {
  -webkit-animation: fadeOutRightShort 0.5s;
  animation: fadeOutRightShort 0.5s;
}
@-webkit-keyframes fadeInRightShort {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(18px, 0, 0);
    transform: translate3d(18px, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInRightShort {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(18px, 0, 0);
    transform: translate3d(18px, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.fadeInRightShort {
  -webkit-animation-name: fadeInRightShort;
  animation-name: fadeInRightShort;
}
@-webkit-keyframes fadeOutRightShort {
  from {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(18px, 0, 0);
    transform: translate3d(18px, 0, 0);
  }
}
@keyframes fadeOutRightShort {
  from {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(18px, 0, 0);
    transform: translate3d(18px, 0, 0);
  }
}
.fadeOutRightShort {
  -webkit-animation-name: fadeOutRightShort;
  animation-name: fadeOutRightShort;
}
@-webkit-keyframes fadeInLeftShort {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-18px, 0, 0);
    transform: translate3d(-18px, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInLeftShort {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-18px, 0, 0);
    transform: translate3d(-18px, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.fadeInLeftShort {
  -webkit-animation-name: fadeInLeftShort;
  animation-name: fadeInLeftShort;
}
@-webkit-keyframes fadeOutLeftShort {
  from {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(-18px, 0, 0);
    transform: translate3d(-18px, 0, 0);
  }
}
@keyframes fadeOutLeftShort {
  from {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(-18px, 0, 0);
    transform: translate3d(-18px, 0, 0);
  }
}
.fadeOutLeftShort {
  -webkit-animation-name: fadeOutLeftShort;
  animation-name: fadeOutLeftShort;
}
@-webkit-keyframes fadeOutUpShort {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
}
@keyframes fadeOutUpShort {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
}
.fadeOutUpShort {
  -webkit-animation-name: fadeOutUpShort;
  animation-name: fadeOutUpShort;
}
@-webkit-keyframes fadeInUpShort {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInUpShort {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.fadeInUpShort {
  -webkit-animation-name: fadeInUpShort;
  animation-name: fadeInUpShort;
}
@-webkit-keyframes fadeInDownShort {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInDownShort {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.fadeInDownShort {
  -webkit-animation-name: fadeInDownShort;
  animation-name: fadeInDownShort;
}
@-webkit-keyframes fadeOutDownShort {
  from {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
  }
}
@keyframes fadeOutDownShort {
  from {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
  }
}
.fadeOutDownShort {
  -webkit-animation-name: fadeOutDownShort;
  animation-name: fadeOutDownShort;
}
@-webkit-keyframes zoomInShort {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.95, 0.95, 0.95);
    transform: scale3d(0.95, 0.95, 0.95);
  }
  50% {
    opacity: 1;
  }
}
@keyframes zoomInShort {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.95, 0.95, 0.95);
    transform: scale3d(0.95, 0.95, 0.95);
  }
  50% {
    opacity: 1;
  }
}
.zoomInShort {
  -webkit-animation-name: zoomInShort;
  animation-name: zoomInShort;
}
@-webkit-keyframes zoomBack {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.5, 0.5, 0.5);
    transform: scale3d(0.5, 0.5, 0.5);
  }
  100% {
    opacity: 0;
  }
}
@keyframes zoomBack {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.5, 0.5, 0.5);
    transform: scale3d(0.5, 0.5, 0.5);
  }
  100% {
    opacity: 0;
  }
}
.zoomBack {
  -webkit-animation-name: zoomBack;
  animation-name: zoomBack;
}

/**
 * Buttons Extras
 */
.input-group-btn > .btn {
  border-color: rgba(0, 0, 0, 0.26);
}
.btn-inverse {
  color: #fff;
  background-color: #37474F;
  border-color: #37474F;
}
.btn-inverse:focus,
.btn-inverse.focus {
  color: #fff;
  background-color: #2f3c43;
  border-color: #2f3c43;
}
.btn-inverse:hover {
  color: #fff;
  background-color: #2f3c43;
  border-color: #2f3c43;
}
.btn-inverse:active,
.btn-inverse.active,
.open > .dropdown-toggle.btn-inverse {
  color: #fff;
  background-color: #263137;
  border-color: #35444c;
}
.btn-inverse:active:hover,
.btn-inverse.active:hover,
.open > .dropdown-toggle.btn-inverse:hover,
.btn-inverse:active:focus,
.btn-inverse.active:focus,
.open > .dropdown-toggle.btn-inverse:focus,
.btn-inverse:active.focus,
.btn-inverse.active.focus,
.open > .dropdown-toggle.btn-inverse.focus {
  color: #fff;
  background-color: #263137;
  border-color: #35444c;
}
.btn-inverse:active,
.btn-inverse.active,
.open > .dropdown-toggle.btn-inverse {
  background-image: none;
}
.btn-inverse.disabled,
.btn-inverse[disabled],
fieldset[disabled] .btn-inverse,
.btn-inverse.disabled:hover,
.btn-inverse[disabled]:hover,
fieldset[disabled] .btn-inverse:hover,
.btn-inverse.disabled:focus,
.btn-inverse[disabled]:focus,
fieldset[disabled] .btn-inverse:focus,
.btn-inverse.disabled.focus,
.btn-inverse[disabled].focus,
fieldset[disabled] .btn-inverse.focus,
.btn-inverse.disabled:active,
.btn-inverse[disabled]:active,
fieldset[disabled] .btn-inverse:active,
.btn-inverse.disabled.active,
.btn-inverse[disabled].active,
fieldset[disabled] .btn-inverse.active {
  background-color: #37474F;
  border-color: #37474F;
}
.btn-inverse .badge {
  color: #37474F;
  background-color: #fff;
}
.btn-flat {
  border-color: transparent !important;
  border-radius: 2px;
  background-color: transparent;
  text-transform: uppercase;
}
.btn-flat:active,
.btn-flat.active {
  box-shadow: 0 0 0 #000;
}
.btn-flat.btn-primary {
  color: #e2edee;
}
.btn-flat.btn-primary:hover,
.btn-flat.btn-primary:focus {
  color: #fff;
}
.btn-flat.btn-primary[disabled],
.btn-flat.btn-primary.disabled {
  color: rgba(68, 138, 255, 0.75) !important;
}
.btn-flat.btn-success {
  color: #4CAF50;
}
.btn-flat.btn-success:hover,
.btn-flat.btn-success:focus {
  color: #fff;
}
.btn-flat.btn-success[disabled],
.btn-flat.btn-success.disabled {
  color: rgba(76, 175, 80, 0.75) !important;
}
.btn-flat.btn-danger {
  color: #F44336;
}
.btn-flat.btn-danger:hover,
.btn-flat.btn-danger:focus {
  color: #fff;
}
.btn-flat.btn-danger[disabled],
.btn-flat.btn-danger.disabled {
  color: rgba(244, 67, 54, 0.75) !important;
}
.btn-flat.btn-warning {
  color: #FFB300;
}
.btn-flat.btn-warning:hover,
.btn-flat.btn-warning:focus {
  color: #fff;
}
.btn-flat.btn-warning[disabled],
.btn-flat.btn-warning.disabled {
  color: rgba(255, 179, 0, 0.75) !important;
}
.btn-flat.btn-info {
  color: #03A9F4;
}
.btn-flat.btn-info:hover,
.btn-flat.btn-info:focus {
  color: #fff;
}
.btn-flat.btn-info[disabled],
.btn-flat.btn-info.disabled {
  color: rgba(3, 169, 244, 0.75) !important;
}
.btn-flat.btn-inverse {
  color: #37474F;
}
.btn-flat.btn-inverse:hover,
.btn-flat.btn-inverse:focus {
  color: #fff;
}
.btn-flat.btn-inverse[disabled],
.btn-flat.btn-inverse.disabled {
  color: rgba(55, 71, 79, 0.75) !important;
}
.btn-flat.text-white {
  color: #fff !important;
}
.btn-flat[disabled],
.btn-flat.disabled {
  background-color: transparent !important;
}
.btn-flat.btn-flat-icon {
  border-radius: 50%;
  font-size: 24px;
  height: 32px;
  width: 32px;
  padding: 0;
  overflow: hidden;
  color: inherit !important;
}
.btn-flat.btn-flat-icon > em {
  line-height: 32px;
}
.btn-flat.btn-flat-icon:hover,
.btn-flat.btn-flat-icon:focus,
.btn-flat.btn-flat-icon:active {
  background-color: rgba(158, 158, 158, 0.2) !important;
}
.btn-raised {
  border: 0;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.14), 0 2px 2px 0 rgba(0, 0, 0, 0.098), 0 1px 5px 0 rgba(0, 0, 0, 0.014);
}
.btn-raised:hover,
.btn-raised:active,
.btn-raised.active {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4) !important;
}
.pull-right > .btn-flat-icon {
  margin-top: -4px;
}
.btn-circle {
  width: 46px;
  height: 46px;
  overflow: hidden;
  border-radius: 50% !important;
  line-height: 46px;
  padding: 0;
  text-align: center;
}
.btn-outline {
  background-color: transparent;
  border-color: #fff;
}
.btn-outline:hover,
.btn-outline:focus {
  background-color: #fff;
  color: #e2edee;
}
.btn-xl {
  padding: 20px 16px;
  font-size: 18px;
}
.btn-square {
  border-radius: 0;
}
.btn-pill-left,
.btn-oval {
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  padding-left: 18px;
}
.btn-pill-right,
.btn-oval {
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  padding-right: 18px;
}
.btn-labeled {
  padding-top: 0;
  padding-bottom: 0;
}
.btn-label {
  position: relative;
  background: transparent;
  display: inline-block;
  padding: 6px 16px;
  left: -16px;
  border-radius: 3px 0 0 3px;
}
.btn-label.btn-label-right {
  left: auto;
  right: -16px;
  border-radius: 0 3px 3px 0;
}
.btn-label:after {
  content: "";
  position: absolute;
  top: 5px;
  bottom: 5px;
  right: 0;
  width: 1px;
  background-color: rgba(0, 0, 0, 0.1);
}
.btn-label.btn-label-right {
  text-indent: -2px;
}
.btn-label.btn-label-right:after {
  left: 0;
  right: auto;
}
.btn-lg .btn-label {
  padding: 10px 20px;
  left: -20px;
  border-radius: 5px 0 0 5px;
}
.btn-lg .btn-label.btn-label-right {
  left: auto;
  right: -20px;
  border-radius: 0 5px 5px 0;
}
.btn-sm .btn-label {
  padding: 5px 10px;
  left: -10px;
  border-radius: 2px 0 0 2px;
}
.btn-sm .btn-label.btn-label-right {
  left: auto;
  right: -10px;
  border-radius: 0 2px 2px 0;
}
.btn-xs .btn-label {
  padding: 1px 5px;
  left: -5px;
  border-radius: 2px 0 0 2px;
}
.btn-xs .btn-label.btn-label-right {
  left: auto;
  right: -5px;
  border-radius: 0 2px 2px 0;
}
.btn-fw {
  min-width: 80px;
}
.btn-fw.btn-sm {
  min-width: 40px;
}
.btn-fw.btn-md {
  min-width: 60px;
}
.btn-fw.btn-lg {
  min-width: 140px;
}
.pagination-rounded li > a,
.pagination-rounded li > span {
  border-radius: 50% !important;
  margin-right: 4px;
  margin-left: 4px;
  width: 35px;
  height: 35px;
  line-height: 35px;
  padding: 0;
  text-align: center;
}
.pagination-rounded li > a > span,
.pagination-rounded li > span > span {
  position: relative;
  top: -1px;
}
.pagination-rounded.pagination-lg li > a,
.pagination-rounded.pagination-lg li > span {
  width: 44px;
  height: 44px;
  line-height: 44px;
}
.pull-left.btn-group,
.pull-right.btn-group {
  position: relative;
  z-index: 1;
}

/**
 * Dropdowns Extras
 */
.dropdown-lg > .dropdown-menu {
  min-width: 200px;
}
.dropdown-list > .dropdown-menu {
  padding: 0;
  min-width: 220px;
}
.dropdown-list .list-group {
  margin: 0;
}
.dropdown-list .list-group-item {
  border-radius: 0;
  border-left: 0;
  border-right: 0;
}
.dropdown-list .list-group-item:first-child {
  border-top: 0;
}
.dropdown-list .list-group-item:last-child {
  border-bottom: 0;
}
.dropdown > a {
  position: relative;
}
.dropdown > a > .label {
  position: absolute;
  top: 0;
  right: 0;
  padding: 2px 5px;
}
@media only screen and (min-width: 768px) {
  .dropdown > a > .label {
    top: 10px;
  }
}
.dropdown-menu-header {
  padding: 10px 15px;
  background-color: #fafafa;
  border-bottom: 1px solid #ddd;
}
.md-dropdown-menu {
  display: block;
  opacity: 0;
  padding: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform-origin: top left;
  transform-origin: top left;
}
.md-dropdown-menu.pull-right,
.md-dropdown-menu.dropdown-menu-right {
  -webkit-transform-origin: top right;
  transform-origin: top right;
}
.md-dropdown-menu > li {
  height: 48px;
}
.md-dropdown-menu > li > a {
  padding: 0 14px;
  line-height: 48px;
}
.open > .md-dropdown-menu {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.dropup .md-dropdown-menu {
  -webkit-transform-origin: bottom left;
  transform-origin: bottom left;
}
.dropup .md-dropdown-menu.pull-right,
.dropup .md-dropdown-menu.dropdown-menu-right {
  -webkit-transform-origin: bottom right;
  transform-origin: bottom right;
}

/**
 * Rows - Extras
 */
.row {
  margin-right: -4px;
  margin-left: -4px;
}
.row .col-lg-1,
.row .col-lg-10,
.row .col-lg-11,
.row .col-lg-12,
.row .col-lg-2,
.row .col-lg-3,
.row .col-lg-4,
.row .col-lg-5,
.row .col-lg-6,
.row .col-lg-7,
.row .col-lg-8,
.row .col-lg-9,
.row .col-md-1,
.row .col-md-10,
.row .col-md-11,
.row .col-md-12,
.row .col-md-2,
.row .col-md-3,
.row .col-md-4,
.row .col-md-5,
.row .col-md-6,
.row .col-md-7,
.row .col-md-8,
.row .col-md-9,
.row .col-sm-1,
.row .col-sm-10,
.row .col-sm-11,
.row .col-sm-12,
.row .col-sm-2,
.row .col-sm-3,
.row .col-sm-4,
.row .col-sm-5,
.row .col-sm-6,
.row .col-sm-7,
.row .col-sm-8,
.row .col-sm-9,
.row .col-xs-1,
.row .col-xs-10,
.row .col-xs-11,
.row .col-xs-12,
.row .col-xs-2,
.row .col-xs-3,
.row .col-xs-4,
.row .col-xs-5,
.row .col-xs-6,
.row .col-xs-7,
.row .col-xs-8,
.row .col-xs-9 {
  padding-right: 4px;
  padding-left: 4px;
}
@media only screen and (min-width: 480px) {
  .row {
    margin-right: -8px;
    margin-left: -8px;
  }
  .row .col-lg-1,
  .row .col-lg-10,
  .row .col-lg-11,
  .row .col-lg-12,
  .row .col-lg-2,
  .row .col-lg-3,
  .row .col-lg-4,
  .row .col-lg-5,
  .row .col-lg-6,
  .row .col-lg-7,
  .row .col-lg-8,
  .row .col-lg-9,
  .row .col-md-1,
  .row .col-md-10,
  .row .col-md-11,
  .row .col-md-12,
  .row .col-md-2,
  .row .col-md-3,
  .row .col-md-4,
  .row .col-md-5,
  .row .col-md-6,
  .row .col-md-7,
  .row .col-md-8,
  .row .col-md-9,
  .row .col-sm-1,
  .row .col-sm-10,
  .row .col-sm-11,
  .row .col-sm-12,
  .row .col-sm-2,
  .row .col-sm-3,
  .row .col-sm-4,
  .row .col-sm-5,
  .row .col-sm-6,
  .row .col-sm-7,
  .row .col-sm-8,
  .row .col-sm-9,
  .row .col-xs-1,
  .row .col-xs-10,
  .row .col-xs-11,
  .row .col-xs-12,
  .row .col-xs-2,
  .row .col-xs-3,
  .row .col-xs-4,
  .row .col-xs-5,
  .row .col-xs-6,
  .row .col-xs-7,
  .row .col-xs-8,
  .row .col-xs-9 {
    padding-right: 8px;
    padding-left: 8px;
  }
}
@media only screen and (min-width: 992px) {
  .row {
    margin-right: -12px;
    margin-left: -12px;
  }
  .row .col-lg-1,
  .row .col-lg-10,
  .row .col-lg-11,
  .row .col-lg-12,
  .row .col-lg-2,
  .row .col-lg-3,
  .row .col-lg-4,
  .row .col-lg-5,
  .row .col-lg-6,
  .row .col-lg-7,
  .row .col-lg-8,
  .row .col-lg-9,
  .row .col-md-1,
  .row .col-md-10,
  .row .col-md-11,
  .row .col-md-12,
  .row .col-md-2,
  .row .col-md-3,
  .row .col-md-4,
  .row .col-md-5,
  .row .col-md-6,
  .row .col-md-7,
  .row .col-md-8,
  .row .col-md-9,
  .row .col-sm-1,
  .row .col-sm-10,
  .row .col-sm-11,
  .row .col-sm-12,
  .row .col-sm-2,
  .row .col-sm-3,
  .row .col-sm-4,
  .row .col-sm-5,
  .row .col-sm-6,
  .row .col-sm-7,
  .row .col-sm-8,
  .row .col-sm-9,
  .row .col-xs-1,
  .row .col-xs-10,
  .row .col-xs-11,
  .row .col-xs-12,
  .row .col-xs-2,
  .row .col-xs-3,
  .row .col-xs-4,
  .row .col-xs-5,
  .row .col-xs-6,
  .row .col-xs-7,
  .row .col-xs-8,
  .row .col-xs-9 {
    padding-right: 12px;
    padding-left: 12px;
  }
}
.row-table {
  display: table;
  table-layout: fixed;
  height: 100%;
  width: 100%;
  margin: 0;
}
.row-table > [class*="col-"] {
  display: table-cell;
  float: none;
  table-layout: fixed;
  vertical-align: middle;
}
.row-flush > [class*="col-"] {
  padding-left: 0;
  padding-right: 0;
}
.t-grid .t-row {
  width: 100%;
  display: table;
  table-layout: fixed;
}
.t-grid .t-col,
.t-grid .t-cell {
  display: table-cell;
}

/**
 * Modals
 */
.modal-header,
.modal-footer {
  border-color: rgba(162, 162, 162, 0.12);
}
.modal.modal-right .modal-dialog,
.modal.modal-left .modal-dialog,
.modal.modal-bottom .modal-dialog,
.modal.modal-top .modal-dialog {
  position: absolute;
  margin: 0;
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: -webkit-transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1), -webkit-transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.modal.modal-right .modal-dialog > .modal-content,
.modal.modal-left .modal-dialog > .modal-content,
.modal.modal-bottom .modal-dialog > .modal-content,
.modal.modal-top .modal-dialog > .modal-content {
  border-radius: 0;
  border: 0;
}
.modal.modal-right.fade.in .modal-dialog,
.modal.modal-left.fade.in .modal-dialog,
.modal.modal-bottom.fade.in .modal-dialog,
.modal.modal-top.fade.in .modal-dialog {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}
.modal.fade.modal-right .modal-dialog,
.modal.fade.modal-left .modal-dialog,
.modal.fade.modal-bottom .modal-dialog,
.modal.fade.modal-top .modal-dialog {
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: -webkit-transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1), -webkit-transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.modal.modal-right .modal-dialog {
  right: 0;
  bottom: 0;
  top: 0;
  width: 240px;
}
.modal.modal-right .modal-dialog > .modal-content {
  min-height: 100%;
}
.modal.modal-right.modal-auto-size .modal-dialog {
  width: 80%;
}
@media only screen and (min-width: 768px) {
  .modal.modal-right.modal-auto-size .modal-dialog {
    width: 50%;
  }
}
.modal.modal-right.fade .modal-dialog {
  -webkit-transform: translate(100%, 0);
  transform: translate(100%, 0);
}
.modal.modal-left .modal-dialog {
  left: 0;
  bottom: 0;
  top: 0;
  width: 240px;
}
.modal.modal-left .modal-dialog > .modal-content {
  min-height: 100%;
}
.modal.modal-left.modal-auto-size .modal-dialog {
  width: 80%;
}
@media only screen and (min-width: 768px) {
  .modal.modal-left.modal-auto-size .modal-dialog {
    width: 50%;
  }
}
.modal.modal-left.fade .modal-dialog {
  -webkit-transform: translate(-100%, 0);
  transform: translate(-100%, 0);
}
.modal.modal-top .modal-dialog {
  right: 0;
  left: 0;
  top: 0;
  width: 100%;
}
.modal.modal-top .modal-dialog > .modal-content {
  width: 100%;
}
.modal.modal-top.fade .modal-dialog {
  -webkit-transform: translate(0, -100%);
  transform: translate(0, -100%);
}
.modal.modal-bottom .modal-dialog {
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
}
.modal.modal-bottom .modal-dialog > .modal-content {
  width: 100%;
}
.modal.modal-bottom.fade .modal-dialog {
  -webkit-transform: translate(0, 100%);
  transform: translate(0, 100%);
}
.modal-content {
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.14), 0 0 2px 2px rgba(0, 0, 0, 0.098), 0 0 5px 1px rgba(0, 0, 0, 0.084);
}
.modal-backdrop {
  opacity: 0;
  will-change: opacity;
}
.modal-backdrop.in {
  -webkit-transition: opacity 0.28s ease-in-out;
  transition: opacity 0.28s ease-in-out;
}
.modal-backdrop-light .modal-backdrop {
  background-color: #fff;
}
.modal-backdrop-light .modal-backdrop.in {
  opacity: 0.9;
  filter: alpha(opacity=90);
}
.modal-backdrop-soft .modal-backdrop.in {
  opacity: 0.3;
  filter: alpha(opacity=30);
}
.modal-backdrop {
  position: fixed;
  bottom: 0;
}

/**
 * Spinners (Loader.CSS)
 */
.loader-primary .ball-pulse > div,
.loader-primary .ball-grid-pulse > div,
.loader-primary .square-spin > div,
.loader-primary .ball-pulse-rise > div,
.loader-primary .ball-rotate > div,
.loader-primary .ball-rotate > div:before,
.loader-primary .ball-rotate > div:after,
.loader-primary .cube-transition > div,
.loader-primary .ball-zig-zag > div,
.loader-primary .ball-zig-zag-deflect > div,
.loader-primary .ball-triangle-path > div,
.loader-primary .ball-scale > div,
.loader-primary .line-scale > div,
.loader-primary .line-scale-party > div,
.loader-primary .ball-scale-multiple > div,
.loader-primary .ball-pulse-sync > div,
.loader-primary .ball-beat > div,
.loader-primary .line-scale-pulse-out > div,
.loader-primary .line-scale-pulse-out-rapid > div,
.loader-primary .ball-spin-fade-loader > div,
.loader-primary .line-spin-fade-loader > div,
.loader-primary .triangle-skew-spin > div,
.loader-primary .ball-grid-beat > div,
.loader-primary .semi-circle-spin > div {
  background-color: #e2edee;
}
.loader-primary .ball-clip-rotate-multiple {
  width: 1px;
}
.loader-primary .ball-clip-rotate-multiple > div {
  border-color: #e2edee transparent #e2edee transparent;
}
.loader-primary .ball-clip-rotate-multiple > div:last-child {
  border-color: transparent #e2edee transparent #e2edee;
}
.loader-primary .ball-clip-rotate > div {
  border-color: #e2edee;
  border-bottom-color: transparent;
}
.loader-primary .ball-clip-rotate-pulse > div:first-child {
  background-color: #e2edee;
  top: 5px;
  left: -8px;
}
.loader-primary .ball-clip-rotate-pulse > div:last-child {
  border-color: #e2edee transparent #e2edee transparent;
}
.loader-primary .square-spin > div,
.loader-primary .ball-scale-ripple > div,
.loader-primary .ball-scale-ripple-multiple > div {
  border-color: #e2edee;
}
.loader-primary .pacman > div:first-of-type {
  border-top-color: #e2edee;
  border-left-color: #e2edee;
  border-bottom-color: #e2edee;
}
.loader-primary .pacman > div:nth-child(2) {
  border-top-color: #e2edee;
  border-left-color: #e2edee;
  border-bottom-color: #e2edee;
}
.loader-primary .pacman > div:nth-child(3),
.loader-primary .pacman > div:nth-child(4),
.loader-primary .pacman > div:nth-child(5),
.loader-primary .pacman > div:nth-child(6) {
  background-color: #e2edee;
}
.loader {
  display: inline-block;
}
.loader-demo {
  padding: 20px 0;
  height: 120px;
  text-align: center;
}
.loader-demo > .loader-inner {
  display: inline-block;
}

/**
 * Themes
 */
.theme-1 .layout-container > aside {
  background-color: #fff;
  color: #6C7177;
}
.theme-1 .layout-container > aside > .sidebar-header {
  background-color: #133d55;
  color: #fff;
}
.theme-1 .layout-container > header {
  background-color: #133d55;
  color: #fff;
}
.theme-2 .layout-container > aside {
  background-color: #263238;
  color: #fff;
}
.theme-2 .layout-container > aside > .sidebar-header {
  background-color: #263238;
  color: #fff;
}
.theme-2 .layout-container > header {
  background-color: #005829;
  color: #fff;
}
.theme-2 .layout-container > main {
  background-color: #ECEFF1;
}
.theme-3 {
  background-color: #2f3c43;
}
.theme-3 .layout-container > aside {
  background-color: #263238;
  color: #fff;
}
.theme-3 .layout-container > aside > .sidebar-header {
  background-color: #263238;
  color: #fff;
}
.theme-3 .layout-container > header {
  background-color: #37474F;
  color: #fff;
}
.theme-3 .layout-container > main {
  background-color: #2f3c43;
  color: #f1f1f1;
}
.theme-3 .layout-container > main .bg-white:not([class*='bg-']),
.theme-3 .layout-container > main .panel:not([class*='bg-']),
.theme-3 .layout-container > main .card:not([class*='bg-']),
.theme-3 .layout-container > main .panel-default > .panel-heading:not([class*='bg-']) {
  background-color: #35444c;
  color: inherit;
}
.theme-3 .layout-container > main .bg-white {
  background-color: #35444c;
  color: inherit;
}
.theme-3 .layout-container > main .well,
.theme-3 .layout-container > main .jumbotron,
.theme-3 .layout-container > main .breadcrumb,
.theme-3 .layout-container > main .modal-content {
  background-color: #35444c;
  color: inherit;
}
.theme-3 .layout-container > main .btn-default {
  color: #fff;
  background-color: #37474F;
  border-color: #2d3940;
}
.theme-3 .layout-container > main .btn-default:focus,
.theme-3 .layout-container > main .btn-default.focus {
  color: #fff;
  background-color: #2f3c43;
  border-color: #242f34;
}
.theme-3 .layout-container > main .btn-default:hover {
  color: #fff;
  background-color: #2f3c43;
  border-color: #242f34;
}
.theme-3 .layout-container > main .btn-default:active,
.theme-3 .layout-container > main .btn-default.active,
.open > .dropdown-toggle.theme-3 .layout-container > main .btn-default {
  color: #fff;
  background-color: #263137;
  border-color: #2a373d;
}
.theme-3 .layout-container > main .btn-default:active:hover,
.theme-3 .layout-container > main .btn-default.active:hover,
.open > .dropdown-toggle.theme-3 .layout-container > main .btn-default:hover,
.theme-3 .layout-container > main .btn-default:active:focus,
.theme-3 .layout-container > main .btn-default.active:focus,
.open > .dropdown-toggle.theme-3 .layout-container > main .btn-default:focus,
.theme-3 .layout-container > main .btn-default:active.focus,
.theme-3 .layout-container > main .btn-default.active.focus,
.open > .dropdown-toggle.theme-3 .layout-container > main .btn-default.focus {
  color: #fff;
  background-color: #263137;
  border-color: #2a373d;
}
.theme-3 .layout-container > main .btn-default:active,
.theme-3 .layout-container > main .btn-default.active,
.open > .dropdown-toggle.theme-3 .layout-container > main .btn-default {
  background-image: none;
}
.theme-3 .layout-container > main .btn-default.disabled,
.theme-3 .layout-container > main .btn-default[disabled],
fieldset[disabled] .theme-3 .layout-container > main .btn-default,
.theme-3 .layout-container > main .btn-default.disabled:hover,
.theme-3 .layout-container > main .btn-default[disabled]:hover,
fieldset[disabled] .theme-3 .layout-container > main .btn-default:hover,
.theme-3 .layout-container > main .btn-default.disabled:focus,
.theme-3 .layout-container > main .btn-default[disabled]:focus,
fieldset[disabled] .theme-3 .layout-container > main .btn-default:focus,
.theme-3 .layout-container > main .btn-default.disabled.focus,
.theme-3 .layout-container > main .btn-default[disabled].focus,
fieldset[disabled] .theme-3 .layout-container > main .btn-default.focus,
.theme-3 .layout-container > main .btn-default.disabled:active,
.theme-3 .layout-container > main .btn-default[disabled]:active,
fieldset[disabled] .theme-3 .layout-container > main .btn-default:active,
.theme-3 .layout-container > main .btn-default.disabled.active,
.theme-3 .layout-container > main .btn-default[disabled].active,
fieldset[disabled] .theme-3 .layout-container > main .btn-default.active {
  background-color: #37474F;
  border-color: #2d3940;
}
.theme-3 .layout-container > main .btn-default .badge {
  color: #37474F;
  background-color: #fff;
}
.theme-3 .layout-container > main .open > .dropdown-toggle.btn-default,
.theme-3 .layout-container > main .open > .dropdown-toggle.btn-default:hover,
.theme-3 .layout-container > main .open > .dropdown-toggle.btn-default:active,
.theme-3 .layout-container > main .open > .dropdown-toggle.btn-default.active,
.theme-3 .layout-container > main .open > .dropdown-toggle.btn-default:focus {
  color: #fff;
  background-color: #37474F;
  border-color: #2d3940;
}
.theme-3 .layout-container > main .form-control {
  color: inherit;
  background-color: rgba(240, 240, 240, 0.1);
  border-color: rgba(240, 240, 240, 0.12);
}
.theme-3 .layout-container > main .form-control:focus {
  border-color: rgba(240, 240, 240, 0.36);
}
.theme-3 .layout-container > main .form-control[disabled],
.theme-3 .layout-container > main .form-control[readonly],
.theme-3 .layout-container > main fieldset[disabled] .form-control {
  background-color: rgba(240, 240, 240, 0.1);
}
.theme-3 .layout-container > main select:not([multiple]) option {
  color: #6C7177;
}
.theme-3 .layout-container > main .input-group-addon,
.theme-3 .layout-container > main .input-group-button {
  background-color: transparent;
  border: 1px solid rgba(162, 162, 162, 0.26);
  color: inherit;
}
.theme-3 .layout-container > main .nav-tabs > li.active {
  color: #fff;
}
.theme-3 .layout-container > main .note-editor .note-editing-area .note-editable {
  background-color: rgba(240, 240, 240, 0.1);
}
.theme-3 .layout-container > main .photo {
  background-color: #37474F;
}
.theme-3 .sidebar-header-logo > svg,
.theme-3 .nav-icon > svg,
.theme-3 .sidebar-header-logo > em,
.theme-3 .nav-icon > em {
  color: #e2edee !important;
  fill: #4CAF50 !important;
}
.theme-4 .layout-container > aside {
  background-color: #263238;
  color: #fff;
}
.theme-4 .layout-container > aside > .sidebar-header {
  background-color: #263238;
  color: #fff;
}
.theme-4 .layout-container > header {
  background-color: #4CAF50;
  color: #fff;
}
.theme-4 .layout-container > main {
  background-color: #ECEFF1;
}
.theme-4 .sidebar-header-logo > svg,
.theme-4 .nav-icon > svg,
.theme-4 .sidebar-header-logo > em,
.theme-4 .nav-icon > em {
  color: #4CAF50 !important;
  fill: #a3d7a5 !important;
}
.theme-5 .layout-container > aside {
  background-color: #263238;
  color: #fff;
}
.theme-5 .layout-container > aside > .sidebar-header {
  background-color: #263238;
  color: #fff;
}
.theme-5 .layout-container > header {
  background-color: #03A9F4;
  color: #fff;
}
.theme-5 .layout-container > main {
  background-color: #ECEFF1;
}
.theme-5 .sidebar-header-logo > svg,
.theme-5 .nav-icon > svg,
.theme-5 .sidebar-header-logo > em,
.theme-5 .nav-icon > em {
  color: #03A9F4 !important;
  fill: #79d4fd !important;
}
.theme-6 .layout-container > aside {
  background-color: #263238;
  color: #fff;
}
.theme-6 .layout-container > aside > .sidebar-header {
  background-color: #263238;
  color: #fff;
}
.theme-6 .layout-container > header {
  background-color: #9575CD;
  color: #fff;
}
.theme-6 .layout-container > main {
  background-color: #ECEFF1;
}
.theme-6 .sidebar-header-logo > svg,
.theme-6 .nav-icon > svg,
.theme-6 .sidebar-header-logo > em,
.theme-6 .nav-icon > em {
  color: #9575CD !important;
  fill: #ddd3ef !important;
}
.theme-7 .layout-container > aside {
  background-color: #fff;
  color: #6C7177;
}
.theme-7 .layout-container > aside > .sidebar-header {
  background-color: #455A64;
  color: #fff;
}
.theme-7 .layout-container > header {
  background-color: #455A64;
  color: #fff;
}
.theme-7 .layout-container > main {
  background-color: #ECEFF1;
}
.theme-8 .layout-container > aside {
  background-color: #263238;
  color: #fff;
}
.theme-8 .layout-container > aside > .sidebar-header {
  background-color: #263238;
  color: #fff;
}
.theme-8 .layout-container > header {
  background-color: #fff;
  color: #6C7177;
}
.theme-8 .layout-container > main {
  background-color: #ECEFF1;
}
.theme-8 .sidebar-header-logo > svg,
.theme-8 .nav-icon > svg,
.theme-8 .sidebar-header-logo > em,
.theme-8 .nav-icon > em {
  color: #e2edee !important;
  fill: #f1f1f1 !important;
}
.theme-9 .layout-container > aside {
  background-color: #fff;
  color: #6C7177;
}
.theme-9 .layout-container > aside > .sidebar-header {
  background-color: #fff;
  color: #6C7177;
}
.theme-9 .layout-container > header {
  background-color: #fff;
  color: #6C7177;
}
.theme-9 .layout-container > main {
  background-color: #ECEFF1;
}
.theme-9 .sidebar-header-logo > svg,
.theme-9 .nav-icon > svg,
.theme-9 .sidebar-header-logo > em,
.theme-9 .nav-icon > em {
  color: #738c98 !important;
  fill: #90A4AE !important;
}
.preview-theme-1 .preview-header {
  background-color: #005829;
}
.preview-theme-1 .preview-sidebar {
  background-color: #fff;
}
.preview-theme-1 .preview-content {
  background-color: #f1f2f3;
}
.preview-theme-2 .preview-header {
  background-color: #005829;
}
.preview-theme-2 .preview-sidebar {
  background-color: #263238;
}
.preview-theme-2 .preview-content {
  background-color: #ECEFF1;
}
.preview-theme-3 .preview-header {
  background-color: #37474F;
}
.preview-theme-3 .preview-sidebar {
  background-color: #263238;
}
.preview-theme-3 .preview-content {
  background-color: #37474F;
}
.preview-theme-4 .preview-header {
  background-color: #4CAF50;
}
.preview-theme-4 .preview-sidebar {
  background-color: #fff;
}
.preview-theme-4 .preview-content {
  background-color: #ECEFF1;
}
.preview-theme-5 .preview-header {
  background-color: #03A9F4;
}
.preview-theme-5 .preview-sidebar {
  background-color: #fff;
}
.preview-theme-5 .preview-content {
  background-color: #ECEFF1;
}
.preview-theme-6 .preview-header {
  background-color: #9575CD;
}
.preview-theme-6 .preview-sidebar {
  background-color: #fff;
}
.preview-theme-6 .preview-content {
  background-color: #ECEFF1;
}
.preview-theme-7 .preview-header {
  background-color: #455A64;
}
.preview-theme-7 .preview-sidebar {
  background-color: #fff;
}
.preview-theme-7 .preview-content {
  background-color: #ECEFF1;
}
.preview-theme-8 .preview-header {
  background-color: #fff;
}
.preview-theme-8 .preview-sidebar {
  background-color: #263238;
}
.preview-theme-8 .preview-content {
  background-color: #ECEFF1;
}
.preview-theme-9 .preview-header {
  background-color: #fff;
}
.preview-theme-9 .preview-sidebar {
  background-color: #fff;
}
.preview-theme-9 .preview-content {
  background-color: #ECEFF1;
}

/**
 * Typography
 */
h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
  font-weight: 400;
  line-height: 1.35;
  letter-spacing: -0.02em;
  opacity: 0.54;
  font-size: 0.6em;
  color: inherit;
}
h1 > small,
h2 > small,
h3 > small,
h4 > small,
h5 > small,
h6 > small {
  margin-left: 5px;
}
small {
  color: inherit;
}
h1 {
  font-size: 56px;
  font-weight: 400;
  line-height: 1.35;
  letter-spacing: -0.02em;
  margin-top: 24px;
  margin-bottom: 24px;
}
h2 {
  font-size: 45px;
  font-weight: 400;
  line-height: 48px;
  margin-top: 24px;
  margin-bottom: 24px;
}
h3 {
  font-size: 34px;
  font-weight: 400;
  line-height: 40px;
  margin-top: 24px;
  margin-bottom: 24px;
}
h4 {
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 24px;
  margin-bottom: 16px;
}
h5 {
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.02em;
  margin-top: 24px;
  margin-bottom: 16px;
}
h6 {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.04em;
  margin-top: 24px;
  margin-bottom: 16px;
}

/**
 * Custom form elements
 *    - Checkbox
 *    - Radios
 */
.c-checkbox,
.c-radio {
  margin-right: 4px;
}
.c-checkbox input,
.c-radio input {
  opacity: 0;
  position: absolute;
  margin-left: 0 !important;
}
.c-checkbox span,
.c-radio span {
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin-left: -20px;
  width: 20px;
  height: 20px;
  border-radius: 2px;
  border: 1px solid rgba(162, 162, 162, 0.36);
  margin-right: 5px;
  text-align: center;
}
.c-checkbox span:before,
.c-radio span:before {
  margin-left: 0;
}
.c-checkbox:hover span,
.c-radio:hover span {
  border-color: #e2edee;
}
.c-radio span,
.c-checkbox-rounded span {
  border-radius: 500px;
}
.c-checkbox span:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  text-align: center !important;
  font-size: 12px;
  line-height: 18px;
  vertical-align: middle;
}
.c-checkbox input[type=checkbox]:checked + span:before {
  color: #fff;
  opacity: 1;
  -webkit-transition: color 0.3s ease-out;
  transition: color 0.3s ease-out;
}
.c-checkbox input[type=checkbox]:checked + span {
  border-color: #e2edee;
  background-color: #e2edee;
}
.c-checkbox input[type=checkbox]:disabled + span {
  border-color: rgba(162, 162, 162, 0.12) !important;
  background-color: rgba(162, 162, 162, 0.12) !important;
  cursor: not-allowed;
}
.c-radio span:before {
  position: absolute;
  content: "";
  width: 10px;
  height: 10px;
  top: 50%;
  left: 50%;
  margin-top: -5px;
  margin-left: -5px;
  border-radius: 500px;
  opacity: 0;
}
.c-radio input[type=radio]:checked + span:before {
  color: #fff;
  opacity: 1;
  -webkit-transition: color 0.3s ease-out;
  transition: color 0.3s ease-out;
}
.c-radio input[type=radio]:checked + span {
  border-color: #e2edee;
  background-color: rgba(162, 162, 162, 0.12);
}
.c-radio input[type=radio]:checked + span:before {
  background-color: #e2edee;
}
.c-radio input[type=radio]:disabled + span {
  border-color: rgba(162, 162, 162, 0.12) !important;
  cursor: not-allowed;
}
.c-radio input[type=radio]:disabled + span:before {
  background-color: rgba(162, 162, 162, 0.12);
}

/**
 * Forms - Note Editor
 */
.note-area > textarea {
  background-image: -webkit-linear-gradient(rgba(162, 162, 162, 0.26) 0.1em, transparent 0.1em);
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0.1em, rgba(162, 162, 162, 0.26)), color-stop(0.1em, transparent));
  background-image: linear-gradient(rgba(162, 162, 162, 0.26) 0.1em, transparent 0.1em);
  background-size: 100% 20px;
  background-color: transparent !important;
  line-height: 20px;
  margin-top: 5px;
  padding: 0;
  padding-bottom: 1px;
  border: none !important;
}
.note-area.note-area-margin {
  position: relative;
}
.note-area.note-area-margin > textarea {
  padding-left: 55px;
}
.note-area.note-area-margin:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 45px;
  width: 5px;
  background-color: rgba(255, 179, 0, 0.5);
}

/**
 * Forms - Switch
 */
.switch .form-control {
  padding-top: 7px;
  margin-bottom: 0;
}
.switch * {
  cursor: pointer;
}
.switch input {
  opacity: 0;
  position: absolute;
  z-index: -1;
}
.switch {
  margin-right: 8px;
}
.switch span {
  position: relative;
  display: inline-block;
  width: 35px;
  height: 15px;
  background-color: #9e9e9e;
  border: 0;
  border-radius: 8px;
  vertical-align: middle;
  -webkit-transition: all 0.2s cubic-bezier(0.35, 0, 0.25, 1) 0.1s;
  transition: all 0.2s cubic-bezier(0.35, 0, 0.25, 1) 0.1s;
}
.switch span:after {
  content: "";
  position: absolute;
  background-color: #fff;
  top: -2px;
  left: 0;
  height: 20px;
  width: 20px;
  border: 0;
  border-radius: 400px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.14), 0 2px 2px 0 rgba(0, 0, 0, 0.098), 0 1px 5px 0 rgba(0, 0, 0, 0.084);
  -webkit-transition: all 0.3s cubic-bezier(0.35, 0, 0.25, 1);
  transition: all 0.3s cubic-bezier(0.35, 0, 0.25, 1);
}
.switch.switch-primary input:checked + span {
  background-color: #e2edee;
}
.switch.switch-primary input:checked + span:after {
  background-color: #e2edee;
}
.switch.switch-primary.switch-warn input:checked + span {
  background-color: rgba(68, 138, 255, 0.5);
}
.switch.switch-info input:checked + span {
  background-color: #03A9F4;
}
.switch.switch-info input:checked + span:after {
  background-color: #03A9F4;
}
.switch.switch-info.switch-warn input:checked + span {
  background-color: rgba(3, 169, 244, 0.5);
}
.switch.switch-danger input:checked + span {
  background-color: #F44336;
}
.switch.switch-danger input:checked + span:after {
  background-color: #F44336;
}
.switch.switch-danger.switch-warn input:checked + span {
  background-color: rgba(244, 67, 54, 0.5);
}
.switch.switch-warning input:checked + span {
  background-color: #FFB300;
}
.switch.switch-warning input:checked + span:after {
  background-color: #FFB300;
}
.switch.switch-warning.switch-warn input:checked + span {
  background-color: rgba(255, 179, 0, 0.5);
}
.switch.switch-success input:checked + span {
  background-color: #4CAF50;
}
.switch.switch-success input:checked + span:after {
  background-color: #4CAF50;
}
.switch.switch-success.switch-warn input:checked + span {
  background-color: rgba(76, 175, 80, 0.5);
}
.switch.switch-purple input:checked + span {
  background-color: #7E57C2;
}
.switch.switch-purple input:checked + span:after {
  background-color: #7E57C2;
}
.switch.switch-purple.switch-warn input:checked + span {
  background-color: rgba(126, 87, 194, 0.5);
}
.switch input:checked + span {
  background-color: #EC407A;
}
.switch input:checked + span:after {
  background-color: #EC407A;
}
.switch.switch-warn input:checked + span {
  background-color: rgba(236, 64, 122, 0.5);
}
.switch input:checked + span:after {
  left: 50%;
}
.switch input:disabled + span {
  background-color: rgba(158, 158, 158, 0.5) !important;
}
.switch input:disabled + span:after {
  background-color: #fff !important;
}

/**
 * Layout Variants
 */
.sidebar-offcanvas .sidebar-container {
  top: 65px;
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}
.sidebar-offcanvas .sidebar-container .sidebar-header {
  box-shadow: 0 0 0 #000;
}
.sidebar-offcanvas .header-container {
  z-index: 30;
}
.sidebar-offcanvas .main-container,
.sidebar-offcanvas .header-container {
  margin-left: 0;
}
.sidebar-offcanvas.offcanvas-visible .sidebar-container {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.no-csstransforms3d .sidebar-offcanvas .sidebar-offcanvas .sidebar-container {
  margin-left: -240px;
}
.no-csstransforms3d .sidebar-offcanvas .sidebar-offcanvas.offcanvas-visible .sidebar-container {
  margin-left: 0;
}

/**
 * Main Layout
 */
[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak {
  display: none !important;
}
[ng-click],
[data-ng-click],
.nav,
.pagination,
.carousel,
.panel-title a {
  cursor: pointer;
}
html,
body {
  width: 100%;
  height: 100%;
  background-color: #f1f2f3;
  min-width: 320px;
  direction: ltr;
}
body {
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #6C7177;
}
.layout-container {
  position: relative;
  width: 100%;
  height: 100%;
}
.page-container,
.page-container > div {
  width: 100%;
  height: 100%;
}
.main-container {
  position: relative;
  height: calc(100% - 65px);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
@media only screen and (min-width: 992px) {
  .main-container {
    margin-left: 240px;
  }
}
.main-container > section {
  min-height: calc(100% - 60px);
}
.content-heading {
  padding: 16px;
  box-shadow: 0 1px 0 rgba(20, 20, 20, 0.075);
}
footer {
  left: 0;
  right: 0;
  bottom: 0;
  height: 60px;
  border-top: 1px solid rgba(160, 160, 160, 0.12);
  padding: 20px;
  z-index: 109;
}
.container-full {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.container-full > .row {
  margin: 0;
}
.container-full > .row > [class*="col-"]:first-child {
  padding-left: 0;
}
.container-full > .row > [class*="col-"]:last-child {
  padding-right: 0;
}
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xs {
  padding: 8px;
  width: auto;
}
@media only screen and (min-width: 480px) {
  .container-fluid,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xs {
    padding: 16px;
  }
}
@media only screen and (min-width: 992px) {
  .container-fluid,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xs {
    padding: 24px;
  }
}
.container-lg {
  max-width: 1200px;
}
.container-md {
  max-width: 992px;
}
.container-sm {
  max-width: 768px;
}
.container-xs {
  max-width: 480px;
}
@media only screen and (max-width: 1199px) {
  .container-unwrap {
    padding: 0;
  }
  .container-unwrap > .row {
    margin: 0;
  }
  .container-unwrap > .row > [class*="col-"] {
    padding-left: 0;
    padding-right: 0;
  }
  .container-unwrap .card {
    margin-bottom: 0;
  }
}
.container-overlap {
  position: relative;
  padding: 32px 16px 64px 16px;
}
.container-overlap + .container-fluid,
.container-overlap + .container-lg,
.container-overlap + .container-md,
.container-overlap + .container-sm,
.container-overlap + .container-xs {
  padding-top: 0;
  margin-top: -32px;
}
.container-overlap + .container-fluid .push-down,
.container-overlap + .container-lg .push-down,
.container-overlap + .container-md .push-down,
.container-overlap + .container-sm .push-down,
.container-overlap + .container-xs .push-down {
  display: block;
  height: 48px;
}
.container-overlap:before {
  background-color: inherit;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

/**
 * Grid Masonry Deck
 */
/* clear fix */
.grid:after {
  content: '';
  display: block;
  clear: both;
}
/* ---- Masonry grid -- customize percentage values ---- */
.grid-sizer,
.grid-item {
  width: 50%;
}
@media only screen and (min-width: 768px) {
  .grid-sizer,
  .grid-item {
    width: 33.3333%;
  }
}
@media only screen and (min-width: 992px) {
  .grid-sizer,
  .grid-item {
    width: 25%;
  }
}
.grid-item {
  float: left;
}
.photo {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  margin: 0 10px 10px 0;
  position: relative;
  -webkit-transition: box-shadow 0.2s ease;
  transition: box-shadow 0.2s ease;
}
.photo:hover {
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.14), 0 6px 10px 0 rgba(0, 0, 0, 0.098), 0 1px 18px 0 rgba(0, 0, 0, 0.014);
}
.photo .photo-loader {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding-top: 40%;
  z-index: 1;
}
.photo img {
  position: relative;
  z-index: 2;
}
.photo .photo-wrapper {
  min-height: 100px;
}
.photo .photo-description {
  padding: 15px;
}

/**
 * Material Lists
 */
.mda-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.mda-list > .mda-list-item {
  padding: 0 22px;
}
.mda-list > .mda-list-item:before,
.mda-list > .mda-list-item:after {
  content: " ";
  display: table;
}
.mda-list > .mda-list-item:after {
  clear: both;
}
.mda-list > .mda-list-item > .mda-list-item-img,
.mda-list > .mda-list-item > .mda-list-item-icon,
.mda-list > .mda-list-item > .mda-list-item-initial {
  float: left;
  width: 48px;
  height: 48px;
  margin-top: 20px;
  margin-bottom: 8px;
  margin-right: 20px;
  border-radius: 50%;
}
.mda-list > .mda-list-item > .mda-list-item-icon {
  line-height: 42px;
  text-align: center;
}
.mda-list > .mda-list-item > .mda-list-item-icon > em,
.mda-list > .mda-list-item > .mda-list-item-icon > i {
  line-height: inherit;
}
.mda-list > .mda-list-item > .mda-list-item-initial {
  line-height: 50px;
  text-align: center;
  font-size: 22px;
  font-weight: 300;
}
.mda-list > .mda-list-item > .mda-list-item-text {
  float: left;
  padding: 20px 0;
}
.mda-list > .mda-list-item > .mda-list-item-text.mda-2-line {
  padding-top: 26px;
}
.mda-list > .mda-list-item > .mda-list-item-text h3 {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: .01em;
  margin: 0 0 6px;
  line-height: .75em;
}
.mda-list > .mda-list-item > .mda-list-item-text h4 {
  font-size: 14px;
  letter-spacing: .01em;
  font-weight: 400;
  margin: 10px 0 5px;
  line-height: .75em;
}
.mda-list > .mda-list-item > .mda-list-item-text p {
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  line-height: 1.6em;
}
.mda-list > .mda-list-item > .mda-list-item-img + .mda-list-item-text,
.mda-list > .mda-list-item > .mda-list-item-initial + .mda-list-item-text {
  width: calc(100% - 68px);
}
.mda-list.mda-list-bordered > .mda-list-item {
  border-bottom: 1px solid rgba(162, 162, 162, 0.16);
}
.card > .mda-list-bordered > .mda-list-item:last-child {
  border-bottom: 0;
}

/**
 * Nestable
 */
.dd {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  max-width: 600px;
  list-style: none;
  font-size: 13px;
  line-height: 20px;
}
.dd-list {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
}
.dd-list .dd-list {
  padding-left: 30px;
}
.dd-collapsed .dd-list {
  display: none;
}
.dd-item,
.dd-empty,
.dd-placeholder {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  min-height: 20px;
  font-size: 13px;
  line-height: 20px;
}
.dd-handle {
  display: block;
  margin: 5px 0;
  padding: 5px 0;
  text-decoration: none;
  color: inherit;
  border-radius: 3px;
}
.dd-item > button {
  display: block;
  position: relative;
  cursor: pointer;
  float: left;
  width: 25px;
  height: 30px;
  margin: 5px 0;
  padding: 0;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  border: 0;
  background: transparent;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  font-weight: bold;
}
.dd-item > button:before {
  content: '+';
  display: block;
  position: absolute;
  width: 100%;
  text-align: center;
  text-indent: 0;
}
.dd-item > button[data-action="collapse"]:before {
  content: '-';
}
.dd-placeholder,
.dd-empty {
  margin: 5px 0;
  padding: 0;
  min-height: 30px;
  border: 1px dashed #b6bcbf;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}
.dd-empty {
  border: 1px dashed #bbb;
  min-height: 100px;
  background-color: #e5e5e5;
  background-size: 60px 60px;
  background-position: 0 0, 30px 30px;
}
.dd-dragel {
  position: absolute;
  pointer-events: none;
  z-index: 9999;
}
.dd-dragel > .dd-item .dd-handle {
  margin-top: 0;
}
/**
 * Nestable Extras
 */
.nestable-lists {
  display: block;
  clear: both;
  padding: 30px 0;
  width: 100%;
  border: 0;
  border-top: 2px solid #ddd;
  border-bottom: 2px solid #ddd;
}
@media only screen and (min-width: 700px) {
  .dd + .dd {
    margin-left: 2%;
  }
}
.dd-hover > .dd-handle {
  background: #2ea8e5 !important;
}
/**
 * Nestable Draggable Handles
 */
.dd3-content {
  display: block;
  margin: 5px 0;
  padding: 10px 10px 10px 50px;
  text-decoration: none;
  border: 1px solid #ebebeb;
  background: #fff;
  border-radius: 3px;
}
.dd-dragel > .dd3-item > .dd3-content {
  margin: 0;
}
.dd3-item > button {
  margin-left: 40px;
}
.dd3-handle {
  position: absolute;
  margin: 0;
  left: 0;
  top: 0;
  cursor: pointer;
  width: 40px;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  border: 1px solid #ebebeb;
  background: #fff;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.dd3-handle:before {
  content: '\2261';
  display: block;
  position: absolute;
  left: 0;
  top: 10px;
  width: 100%;
  text-align: center;
  text-indent: 0;
  color: #ccc;
  font-size: 20px;
  font-weight: normal;
}
.dd3-handle:hover {
  background: #f7f7f7;
}

/**
 * Float Button
 */
.floatbutton {
  position: relative;
  z-index: 1000;
}
.floatbutton .mfb-component__button--child,
.floatbutton .mfb-component__button--main {
  color: #fff;
}

/**
 * Dropzone
 */
.dropzone {
  border: 0;
}
.dropzone .dz-default.dz-message {
  font-size: 35px;
}
.dropzone .dz-preview.dz-image-preview {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.14), 0 2px 2px 0 rgba(0, 0, 0, 0.098), 0 1px 5px 0 rgba(0, 0, 0, 0.014);
}
.dropzone .dz-preview.dz-image-preview .dz-image {
  border-radius: 1px;
}
.dropzone .dz-preview.dz-image-preview .dz-details {
  position: relative;
  line-height: 1;
  padding: 6px 4px;
  margin: 0;
  opacity: 1;
  font-size: 14px;
}
.dropzone .dz-preview .dz-image img {
  -webkit-transition: -webkit-transform 0.2s ease;
  transition: -webkit-transform 0.2s ease;
  transition: transform 0.2s ease;
  transition: transform 0.2s ease, -webkit-transform 0.2s ease;
}
.dropzone .dz-preview:hover .dz-image img {
  -webkit-filter: none;
  filter: none;
  -webkit-transform: scale(2);
  transform: scale(2);
  -webkit-transition: -webkit-transform 0.5s ease;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
}
.dropzone .dz-preview.dz-file-preview .dz-image {
  border-radius: 1px;
  background-image: none;
  background-color: #ECEFF1;
}
.dropzone .dz-filename {
  display: none;
}
.dropzone .dz-preview .dz-remove {
  color: #a2a2a2;
  font-size: 12px;
}
.dropzone .dz-preview .dz-remove:hover {
  color: #F44336;
  text-decoration: none;
}
.dropzone .dz-preview .dz-error-message {
  top: 130px;
  border-radius: 2px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.dropzone .dz-preview .dz-error-message:after {
  display: none;
}
.dropzone .dz-preview.dz-error:hover .dz-error-message {
  top: 100px;
}

/**
 * Form Editors (Summernote)
 */
.note-editor .note-editing-area .note-editable {
  color: inherit !important;
}
.note-editor .note-statusbar {
  background-color: rgba(162, 162, 162, 0.26) !important;
}
.note-editor.note-frame {
  border-color: rgba(162, 162, 162, 0.26) !important;
}
.note-editor .dropdown-menu {
  max-height: 280px;
  overflow: auto;
}
.summernote-air + .note-editor .note-editing-area .note-editable {
  background-color: transparent !important;
}
@font-face {
  font-family: "summernote";
  font-style: normal;
  font-weight: normal;
  src: url(/static/media/summernote.9cd6e041.eot);
  src: url(/static/media/summernote.9cd6e041.eot?#iefix) format("embedded-opentype"), url(/static/media/summernote.df36508b.woff) format("woff"), url(/static/media/summernote.fce9a0f2.ttf) format("truetype");
}

/**
 * Forms - Advanced plugins
 */
.noUi-handle {
  border-radius: 50%;
  border: 0;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  background-color: #e2edee;
}
.noUi-horizontal .noUi-handle {
  width: 24px;
  height: 24px;
  top: -10px;
}
.noUi-horizontal .noUi-handle:before,
.noUi-horizontal .noUi-handle:after {
  display: none;
}
.noUi-connect {
  background-color: #e2edee;
}
.noUi-horizontal {
  height: 4px;
  border: 0;
  background-color: #ECEFF1;
}
.noUi-target.noUi-connect {
  background-color: #e2edee;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
}
.ui-slider-success .noUi-handle {
  background-color: #4CAF50;
}
.ui-slider-success .noUi-connect {
  background-color: #4CAF50;
}
.ui-slider-success.noUi-target.noUi-connect {
  background-color: #4CAF50;
}
.ui-slider-info .noUi-handle {
  background-color: #03A9F4;
}
.ui-slider-info .noUi-connect {
  background-color: #03A9F4;
}
.ui-slider-info.noUi-target.noUi-connect {
  background-color: #03A9F4;
}
.ui-slider-warning .noUi-handle {
  background-color: #FFB300;
}
.ui-slider-warning .noUi-connect {
  background-color: #FFB300;
}
.ui-slider-warning.noUi-target.noUi-connect {
  background-color: #FFB300;
}
.ui-slider-danger .noUi-handle {
  background-color: #F44336;
}
.ui-slider-danger .noUi-connect {
  background-color: #F44336;
}
.ui-slider-danger.noUi-target.noUi-connect {
  background-color: #F44336;
}
.ui-slider-inverse .noUi-handle {
  background-color: #37474F;
}
.ui-slider-inverse .noUi-connect {
  background-color: #37474F;
}
.ui-slider-inverse.noUi-target.noUi-connect {
  background-color: #37474F;
}
/* Select2
   ----------------------------------- */
.select2-container--default .select2-selection--single,
.select2-container--default .select2-selection--multiple {
  position: relative;
  background-color: transparent;
  color: inherit;
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.14);
  min-height: 32px;
}
.select2-container--default .select2-selection--single:after,
.select2-container--default .select2-selection--multiple:after {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 50%;
  right: 50%;
  height: 2px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: inherit;
}
.select2-container--open.select2-container--default .select2-selection--single,
.select2-container--focus.select2-container--default .select2-selection--single,
.select2-container--open.select2-container--default .select2-selection--multiple,
.select2-container--focus.select2-container--default .select2-selection--multiple {
  border: 0;
}
.select2-container--open.select2-container--default .select2-selection--single:after,
.select2-container--focus.select2-container--default .select2-selection--single:after,
.select2-container--open.select2-container--default .select2-selection--multiple:after,
.select2-container--focus.select2-container--default .select2-selection--multiple:after {
  background-color: #e2edee;
  left: 0;
  right: 0;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice {
  border-radius: 20px;
  border: 0;
  background-color: rgba(162, 162, 162, 0.12);
  padding: 4px 12px;
  margin: 0 8px 4px 0 !important;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice,
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  color: inherit;
}
.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 0;
  border-bottom: 1px solid #aaa;
}
.select2-dropdown {
  top: 1px;
  -webkit-transform-origin: top left;
  transform-origin: top left;
  -webkit-animation: zoomIn 0.2s ease;
  animation: zoomIn 0.2s ease;
}
.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #E0E0E0;
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #E0E0E0;
  color: #263238;
}
/* Clockpicker
   ----------------------------------- */
.clockpicker-popover {
  -webkit-transform-origin: top left;
  transform-origin: top left;
  -webkit-animation: zoomIn 0.2s ease;
  animation: zoomIn 0.2s ease;
}
.clockpicker-popover .arrow {
  display: none;
}
.clockpicker-popover.popover.bottom {
  margin-top: 1px;
}
/* Colorpicker
   -----------------------------------*/
.colorpicker {
  -webkit-transform-origin: top right;
  transform-origin: top right;
  -webkit-animation: zoomIn 0.2s ease;
  animation: zoomIn 0.2s ease;
}
.colorpicker:before,
.colorpicker:after {
  display: none;
}
.colorpicker-2x .colorpicker-saturation {
  width: 200px;
  height: 200px;
}
.colorpicker-2x .colorpicker-hue,
.colorpicker-2x .colorpicker-alpha {
  width: 30px;
  height: 200px;
}
.colorpicker-2x .colorpicker-color,
.colorpicker-2x .colorpicker-color div {
  height: 30px;
}
/* Datepicker
   -----------------------------------*/
.ui-datepicker-responsive {
  max-width: 100%;
  overflow: auto;
}
.ui-datepicker {
  background-color: transparent;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.14), 0 2px 2px 0 rgba(0, 0, 0, 0.098), 0 1px 5px 0 rgba(0, 0, 0, 0.014);
}
.ui-datepicker.ui-datepicker-popup {
  box-shadow: 0 0 0 #000;
}
.ui-datepicker .datepicker-inline {
  display: inline-block;
  padding: 0;
  width: 100%;
}
.ui-datepicker .datepicker table {
  width: 100%;
}
.ui-datepicker .datepicker table tr td,
.ui-datepicker .datepicker table tr th {
  border-radius: 0;
  padding: 16px 12px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  font-size: 12px;
}
.ui-datepicker .datepicker tbody tr td {
  padding: 12px 12px;
}
.ui-datepicker .datepicker table tr td.day:hover {
  background: rgba(162, 162, 162, 0.12);
}
.ui-datepicker .datepicker table thead tr:last-child th {
  padding: 0;
  line-height: 36px;
  font-size: 85%;
}
.ui-datepicker .datepicker thead tr:first-child th:hover,
.ui-datepicker .datepicker tfoot tr th:hover {
  background-image: none;
  background-color: #E91E63;
  color: #fff;
}
.ui-datepicker .datepicker table tr td.active:hover,
.ui-datepicker .datepicker table tr td.active:hover:hover,
.ui-datepicker .datepicker table tr td.active.disabled:hover,
.ui-datepicker .datepicker table tr td.active.disabled:hover:hover,
.ui-datepicker .datepicker table tr td.active:focus,
.ui-datepicker .datepicker table tr td.active:hover:focus,
.ui-datepicker .datepicker table tr td.active.disabled:focus,
.ui-datepicker .datepicker table tr td.active.disabled:hover:focus,
.ui-datepicker .datepicker table tr td.active:active,
.ui-datepicker .datepicker table tr td.active:hover:active,
.ui-datepicker .datepicker table tr td.active.disabled:active,
.ui-datepicker .datepicker table tr td.active.disabled:hover:active,
.ui-datepicker .datepicker table tr td.active.active,
.ui-datepicker .datepicker table tr td.active:hover.active,
.ui-datepicker .datepicker table tr td.active.disabled.active,
.ui-datepicker .datepicker table tr td.active.disabled:hover.active,
.ui-datepicker .open .dropdown-toggle.datepicker table tr td.active,
.ui-datepicker .open .dropdown-toggle.datepicker table tr td.active:hover,
.ui-datepicker .open .dropdown-toggle.datepicker table tr td.active.disabled,
.ui-datepicker .open .dropdown-toggle.datepicker table tr td.active.disabled:hover {
  background-image: none;
  background-color: #E91E63;
  color: #fff;
}
.ui-datepicker .datepicker table tr td span.active:hover,
.ui-datepicker .datepicker table tr td span.active:hover:hover,
.ui-datepicker .datepicker table tr td span.active.disabled:hover,
.ui-datepicker .datepicker table tr td span.active.disabled:hover:hover,
.ui-datepicker .datepicker table tr td span.active:focus,
.ui-datepicker .datepicker table tr td span.active:hover:focus,
.ui-datepicker .datepicker table tr td span.active.disabled:focus,
.ui-datepicker .datepicker table tr td span.active.disabled:hover:focus,
.ui-datepicker .datepicker table tr td span.active:active,
.ui-datepicker .datepicker table tr td span.active:hover:active,
.ui-datepicker .datepicker table tr td span.active.disabled:active,
.ui-datepicker .datepicker table tr td span.active.disabled:hover:active,
.ui-datepicker .datepicker table tr td span.active.active,
.ui-datepicker .datepicker table tr td span.active:hover.active,
.ui-datepicker .datepicker table tr td span.active.disabled.active,
.ui-datepicker .datepicker table tr td span.active.disabled:hover.active,
.ui-datepicker .open .dropdown-toggle.datepicker table tr td span.active,
.ui-datepicker .open .dropdown-toggle.datepicker table tr td span.active:hover,
.ui-datepicker .open .dropdown-toggle.datepicker table tr td span.active.disabled,
.ui-datepicker .open .dropdown-toggle.datepicker table tr td span.active.disabled:hover,
.ui-datepicker .datepicker .datepicker-switch:hover,
.ui-datepicker .datepicker .prev:hover,
.ui-datepicker .datepicker .next:hover,
.ui-datepicker .datepicker tfoot tr th:hover {
  background-image: none;
  background-color: #E91E63;
  color: #fff;
}
.ui-datepicker.mda-form-control,
.ui-datepicker.mda-input-group {
  box-shadow: 0 0 0 #000;
}
.ui-datepicker .input-daterange .input-group-addon {
  background: transparent;
  border: 0 !important;
  vertical-align: baseline;
}
.datepicker.dropdown-menu {
  margin: 0;
  padding: 0 0 5px 0;
  border: 0;
  -webkit-animation: zoomIn 0.2s ease;
  animation: zoomIn 0.2s ease;
}
.datepicker.dropdown-menu.datepicker-orient-top {
  -webkit-transform-origin: top left;
  transform-origin: top left;
}
.datepicker.dropdown-menu.datepicker-orient-bottom {
  -webkit-transform-origin: bottom left;
  transform-origin: bottom left;
}
.datepicker.dropdown-menu table tr td,
.datepicker.dropdown-menu table tr th {
  border-radius: 0;
}
.datepicker.dropdown-menu:after,
.datepicker.dropdown-menu:before {
  display: none;
}
.dp-theme-primary .datepicker table thead tr:first-child th {
  background-color: #e2edee;
  color: #fff;
}
.dp-theme-primary .datepicker table thead tr:last-child th {
  background-color: #2a7aff;
  color: #fff;
}
.dp-theme-primary .datepicker thead tr:first-child th:hover,
.dp-theme-primary .datepicker tfoot tr th:hover {
  background-color: #e2edee;
  color: #fff;
}
.dp-theme-primary .datepicker table tr td.active:hover,
.dp-theme-primary .datepicker table tr td.active:hover:hover,
.dp-theme-primary .datepicker table tr td.active.disabled:hover,
.dp-theme-primary .datepicker table tr td.active.disabled:hover:hover,
.dp-theme-primary .datepicker table tr td.active:focus,
.dp-theme-primary .datepicker table tr td.active:hover:focus,
.dp-theme-primary .datepicker table tr td.active.disabled:focus,
.dp-theme-primary .datepicker table tr td.active.disabled:hover:focus,
.dp-theme-primary .datepicker table tr td.active:active,
.dp-theme-primary .datepicker table tr td.active:hover:active,
.dp-theme-primary .datepicker table tr td.active.disabled:active,
.dp-theme-primary .datepicker table tr td.active.disabled:hover:active,
.dp-theme-primary .datepicker table tr td.active.active,
.dp-theme-primary .datepicker table tr td.active:hover.active,
.dp-theme-primary .datepicker table tr td.active.disabled.active,
.dp-theme-primary .datepicker table tr td.active.disabled:hover.active,
.dp-theme-primary .open .dropdown-toggle.datepicker table tr td.active,
.dp-theme-primary .open .dropdown-toggle.datepicker table tr td.active:hover,
.dp-theme-primary .open .dropdown-toggle.datepicker table tr td.active.disabled,
.dp-theme-primary .open .dropdown-toggle.datepicker table tr td.active.disabled:hover,
.dp-theme-primary .datepicker .datepicker-switch:hover,
.dp-theme-primary .datepicker .prev:hover,
.dp-theme-primary .datepicker .next:hover,
.dp-theme-primary .datepicker tfoot tr th:hover {
  background-color: #e2edee;
  color: #fff;
}
.dp-theme-primary.datepicker-standalone .datepicker .datepicker-switch,
.dp-theme-primary.datepicker-standalone .datepicker .prev,
.dp-theme-primary.datepicker-standalone .datepicker .next,
.dp-theme-primary.datepicker-standalone .datepicker tfoot tr th {
  background-color: #e2edee;
  color: #fff;
}
.dp-theme-primary .datepicker table tr td span.active:hover,
.dp-theme-primary .datepicker table tr td span.active:hover:hover,
.dp-theme-primary .datepicker table tr td span.active.disabled:hover,
.dp-theme-primary .datepicker table tr td span.active.disabled:hover:hover,
.dp-theme-primary .datepicker table tr td span.active:focus,
.dp-theme-primary .datepicker table tr td span.active:hover:focus,
.dp-theme-primary .datepicker table tr td span.active.disabled:focus,
.dp-theme-primary .datepicker table tr td span.active.disabled:hover:focus,
.dp-theme-primary .datepicker table tr td span.active:active,
.dp-theme-primary .datepicker table tr td span.active:hover:active,
.dp-theme-primary .datepicker table tr td span.active.disabled:active,
.dp-theme-primary .datepicker table tr td span.active.disabled:hover:active,
.dp-theme-primary .datepicker table tr td span.active.active,
.dp-theme-primary .datepicker table tr td span.active:hover.active,
.dp-theme-primary .datepicker table tr td span.active.disabled.active,
.dp-theme-primary .datepicker table tr td span.active.disabled:hover.active,
.dp-theme-primary .open .dropdown-toggle.datepicker table tr td span.active,
.dp-theme-primary .open .dropdown-toggle.datepicker table tr td span.active:hover,
.dp-theme-primary .open .dropdown-toggle.datepicker table tr td span.active.disabled,
.dp-theme-primary .open .dropdown-toggle.datepicker table tr td span.active.disabled:hover {
  background-color: #e2edee;
  color: #fff;
}
.dp-theme-success .datepicker table thead tr:first-child th {
  background-color: #4CAF50;
  color: #fff;
}
.dp-theme-success .datepicker table thead tr:last-child th {
  background-color: #449d48;
  color: #fff;
}
.dp-theme-success .datepicker thead tr:first-child th:hover,
.dp-theme-success .datepicker tfoot tr th:hover {
  background-color: #4CAF50;
  color: #fff;
}
.dp-theme-success .datepicker table tr td.active:hover,
.dp-theme-success .datepicker table tr td.active:hover:hover,
.dp-theme-success .datepicker table tr td.active.disabled:hover,
.dp-theme-success .datepicker table tr td.active.disabled:hover:hover,
.dp-theme-success .datepicker table tr td.active:focus,
.dp-theme-success .datepicker table tr td.active:hover:focus,
.dp-theme-success .datepicker table tr td.active.disabled:focus,
.dp-theme-success .datepicker table tr td.active.disabled:hover:focus,
.dp-theme-success .datepicker table tr td.active:active,
.dp-theme-success .datepicker table tr td.active:hover:active,
.dp-theme-success .datepicker table tr td.active.disabled:active,
.dp-theme-success .datepicker table tr td.active.disabled:hover:active,
.dp-theme-success .datepicker table tr td.active.active,
.dp-theme-success .datepicker table tr td.active:hover.active,
.dp-theme-success .datepicker table tr td.active.disabled.active,
.dp-theme-success .datepicker table tr td.active.disabled:hover.active,
.dp-theme-success .open .dropdown-toggle.datepicker table tr td.active,
.dp-theme-success .open .dropdown-toggle.datepicker table tr td.active:hover,
.dp-theme-success .open .dropdown-toggle.datepicker table tr td.active.disabled,
.dp-theme-success .open .dropdown-toggle.datepicker table tr td.active.disabled:hover,
.dp-theme-success .datepicker .datepicker-switch:hover,
.dp-theme-success .datepicker .prev:hover,
.dp-theme-success .datepicker .next:hover,
.dp-theme-success .datepicker tfoot tr th:hover {
  background-color: #4CAF50;
  color: #fff;
}
.dp-theme-success.datepicker-standalone .datepicker .datepicker-switch,
.dp-theme-success.datepicker-standalone .datepicker .prev,
.dp-theme-success.datepicker-standalone .datepicker .next,
.dp-theme-success.datepicker-standalone .datepicker tfoot tr th {
  background-color: #4CAF50;
  color: #fff;
}
.dp-theme-success .datepicker table tr td span.active:hover,
.dp-theme-success .datepicker table tr td span.active:hover:hover,
.dp-theme-success .datepicker table tr td span.active.disabled:hover,
.dp-theme-success .datepicker table tr td span.active.disabled:hover:hover,
.dp-theme-success .datepicker table tr td span.active:focus,
.dp-theme-success .datepicker table tr td span.active:hover:focus,
.dp-theme-success .datepicker table tr td span.active.disabled:focus,
.dp-theme-success .datepicker table tr td span.active.disabled:hover:focus,
.dp-theme-success .datepicker table tr td span.active:active,
.dp-theme-success .datepicker table tr td span.active:hover:active,
.dp-theme-success .datepicker table tr td span.active.disabled:active,
.dp-theme-success .datepicker table tr td span.active.disabled:hover:active,
.dp-theme-success .datepicker table tr td span.active.active,
.dp-theme-success .datepicker table tr td span.active:hover.active,
.dp-theme-success .datepicker table tr td span.active.disabled.active,
.dp-theme-success .datepicker table tr td span.active.disabled:hover.active,
.dp-theme-success .open .dropdown-toggle.datepicker table tr td span.active,
.dp-theme-success .open .dropdown-toggle.datepicker table tr td span.active:hover,
.dp-theme-success .open .dropdown-toggle.datepicker table tr td span.active.disabled,
.dp-theme-success .open .dropdown-toggle.datepicker table tr td span.active.disabled:hover {
  background-color: #4CAF50;
  color: #fff;
}
.dp-theme-warning .datepicker table thead tr:first-child th {
  background-color: #FFB300;
  color: #fff;
}
.dp-theme-warning .datepicker table thead tr:last-child th {
  background-color: #e6a100;
  color: #fff;
}
.dp-theme-warning .datepicker thead tr:first-child th:hover,
.dp-theme-warning .datepicker tfoot tr th:hover {
  background-color: #FFB300;
  color: #fff;
}
.dp-theme-warning .datepicker table tr td.active:hover,
.dp-theme-warning .datepicker table tr td.active:hover:hover,
.dp-theme-warning .datepicker table tr td.active.disabled:hover,
.dp-theme-warning .datepicker table tr td.active.disabled:hover:hover,
.dp-theme-warning .datepicker table tr td.active:focus,
.dp-theme-warning .datepicker table tr td.active:hover:focus,
.dp-theme-warning .datepicker table tr td.active.disabled:focus,
.dp-theme-warning .datepicker table tr td.active.disabled:hover:focus,
.dp-theme-warning .datepicker table tr td.active:active,
.dp-theme-warning .datepicker table tr td.active:hover:active,
.dp-theme-warning .datepicker table tr td.active.disabled:active,
.dp-theme-warning .datepicker table tr td.active.disabled:hover:active,
.dp-theme-warning .datepicker table tr td.active.active,
.dp-theme-warning .datepicker table tr td.active:hover.active,
.dp-theme-warning .datepicker table tr td.active.disabled.active,
.dp-theme-warning .datepicker table tr td.active.disabled:hover.active,
.dp-theme-warning .open .dropdown-toggle.datepicker table tr td.active,
.dp-theme-warning .open .dropdown-toggle.datepicker table tr td.active:hover,
.dp-theme-warning .open .dropdown-toggle.datepicker table tr td.active.disabled,
.dp-theme-warning .open .dropdown-toggle.datepicker table tr td.active.disabled:hover,
.dp-theme-warning .datepicker .datepicker-switch:hover,
.dp-theme-warning .datepicker .prev:hover,
.dp-theme-warning .datepicker .next:hover,
.dp-theme-warning .datepicker tfoot tr th:hover {
  background-color: #FFB300;
  color: #fff;
}
.dp-theme-warning.datepicker-standalone .datepicker .datepicker-switch,
.dp-theme-warning.datepicker-standalone .datepicker .prev,
.dp-theme-warning.datepicker-standalone .datepicker .next,
.dp-theme-warning.datepicker-standalone .datepicker tfoot tr th {
  background-color: #FFB300;
  color: #fff;
}
.dp-theme-warning .datepicker table tr td span.active:hover,
.dp-theme-warning .datepicker table tr td span.active:hover:hover,
.dp-theme-warning .datepicker table tr td span.active.disabled:hover,
.dp-theme-warning .datepicker table tr td span.active.disabled:hover:hover,
.dp-theme-warning .datepicker table tr td span.active:focus,
.dp-theme-warning .datepicker table tr td span.active:hover:focus,
.dp-theme-warning .datepicker table tr td span.active.disabled:focus,
.dp-theme-warning .datepicker table tr td span.active.disabled:hover:focus,
.dp-theme-warning .datepicker table tr td span.active:active,
.dp-theme-warning .datepicker table tr td span.active:hover:active,
.dp-theme-warning .datepicker table tr td span.active.disabled:active,
.dp-theme-warning .datepicker table tr td span.active.disabled:hover:active,
.dp-theme-warning .datepicker table tr td span.active.active,
.dp-theme-warning .datepicker table tr td span.active:hover.active,
.dp-theme-warning .datepicker table tr td span.active.disabled.active,
.dp-theme-warning .datepicker table tr td span.active.disabled:hover.active,
.dp-theme-warning .open .dropdown-toggle.datepicker table tr td span.active,
.dp-theme-warning .open .dropdown-toggle.datepicker table tr td span.active:hover,
.dp-theme-warning .open .dropdown-toggle.datepicker table tr td span.active.disabled,
.dp-theme-warning .open .dropdown-toggle.datepicker table tr td span.active.disabled:hover {
  background-color: #FFB300;
  color: #fff;
}
.dp-theme-info .datepicker table thead tr:first-child th {
  background-color: #03A9F4;
  color: #fff;
}
.dp-theme-info .datepicker table thead tr:last-child th {
  background-color: #0398db;
  color: #fff;
}
.dp-theme-info .datepicker thead tr:first-child th:hover,
.dp-theme-info .datepicker tfoot tr th:hover {
  background-color: #03A9F4;
  color: #fff;
}
.dp-theme-info .datepicker table tr td.active:hover,
.dp-theme-info .datepicker table tr td.active:hover:hover,
.dp-theme-info .datepicker table tr td.active.disabled:hover,
.dp-theme-info .datepicker table tr td.active.disabled:hover:hover,
.dp-theme-info .datepicker table tr td.active:focus,
.dp-theme-info .datepicker table tr td.active:hover:focus,
.dp-theme-info .datepicker table tr td.active.disabled:focus,
.dp-theme-info .datepicker table tr td.active.disabled:hover:focus,
.dp-theme-info .datepicker table tr td.active:active,
.dp-theme-info .datepicker table tr td.active:hover:active,
.dp-theme-info .datepicker table tr td.active.disabled:active,
.dp-theme-info .datepicker table tr td.active.disabled:hover:active,
.dp-theme-info .datepicker table tr td.active.active,
.dp-theme-info .datepicker table tr td.active:hover.active,
.dp-theme-info .datepicker table tr td.active.disabled.active,
.dp-theme-info .datepicker table tr td.active.disabled:hover.active,
.dp-theme-info .open .dropdown-toggle.datepicker table tr td.active,
.dp-theme-info .open .dropdown-toggle.datepicker table tr td.active:hover,
.dp-theme-info .open .dropdown-toggle.datepicker table tr td.active.disabled,
.dp-theme-info .open .dropdown-toggle.datepicker table tr td.active.disabled:hover,
.dp-theme-info .datepicker .datepicker-switch:hover,
.dp-theme-info .datepicker .prev:hover,
.dp-theme-info .datepicker .next:hover,
.dp-theme-info .datepicker tfoot tr th:hover {
  background-color: #03A9F4;
  color: #fff;
}
.dp-theme-info.datepicker-standalone .datepicker .datepicker-switch,
.dp-theme-info.datepicker-standalone .datepicker .prev,
.dp-theme-info.datepicker-standalone .datepicker .next,
.dp-theme-info.datepicker-standalone .datepicker tfoot tr th {
  background-color: #03A9F4;
  color: #fff;
}
.dp-theme-info .datepicker table tr td span.active:hover,
.dp-theme-info .datepicker table tr td span.active:hover:hover,
.dp-theme-info .datepicker table tr td span.active.disabled:hover,
.dp-theme-info .datepicker table tr td span.active.disabled:hover:hover,
.dp-theme-info .datepicker table tr td span.active:focus,
.dp-theme-info .datepicker table tr td span.active:hover:focus,
.dp-theme-info .datepicker table tr td span.active.disabled:focus,
.dp-theme-info .datepicker table tr td span.active.disabled:hover:focus,
.dp-theme-info .datepicker table tr td span.active:active,
.dp-theme-info .datepicker table tr td span.active:hover:active,
.dp-theme-info .datepicker table tr td span.active.disabled:active,
.dp-theme-info .datepicker table tr td span.active.disabled:hover:active,
.dp-theme-info .datepicker table tr td span.active.active,
.dp-theme-info .datepicker table tr td span.active:hover.active,
.dp-theme-info .datepicker table tr td span.active.disabled.active,
.dp-theme-info .datepicker table tr td span.active.disabled:hover.active,
.dp-theme-info .open .dropdown-toggle.datepicker table tr td span.active,
.dp-theme-info .open .dropdown-toggle.datepicker table tr td span.active:hover,
.dp-theme-info .open .dropdown-toggle.datepicker table tr td span.active.disabled,
.dp-theme-info .open .dropdown-toggle.datepicker table tr td span.active.disabled:hover {
  background-color: #03A9F4;
  color: #fff;
}
.dp-theme-danger .datepicker table thead tr:first-child th {
  background-color: #F44336;
  color: #fff;
}
.dp-theme-danger .datepicker table thead tr:last-child th {
  background-color: #f32c1e;
  color: #fff;
}
.dp-theme-danger .datepicker thead tr:first-child th:hover,
.dp-theme-danger .datepicker tfoot tr th:hover {
  background-color: #F44336;
  color: #fff;
}
.dp-theme-danger .datepicker table tr td.active:hover,
.dp-theme-danger .datepicker table tr td.active:hover:hover,
.dp-theme-danger .datepicker table tr td.active.disabled:hover,
.dp-theme-danger .datepicker table tr td.active.disabled:hover:hover,
.dp-theme-danger .datepicker table tr td.active:focus,
.dp-theme-danger .datepicker table tr td.active:hover:focus,
.dp-theme-danger .datepicker table tr td.active.disabled:focus,
.dp-theme-danger .datepicker table tr td.active.disabled:hover:focus,
.dp-theme-danger .datepicker table tr td.active:active,
.dp-theme-danger .datepicker table tr td.active:hover:active,
.dp-theme-danger .datepicker table tr td.active.disabled:active,
.dp-theme-danger .datepicker table tr td.active.disabled:hover:active,
.dp-theme-danger .datepicker table tr td.active.active,
.dp-theme-danger .datepicker table tr td.active:hover.active,
.dp-theme-danger .datepicker table tr td.active.disabled.active,
.dp-theme-danger .datepicker table tr td.active.disabled:hover.active,
.dp-theme-danger .open .dropdown-toggle.datepicker table tr td.active,
.dp-theme-danger .open .dropdown-toggle.datepicker table tr td.active:hover,
.dp-theme-danger .open .dropdown-toggle.datepicker table tr td.active.disabled,
.dp-theme-danger .open .dropdown-toggle.datepicker table tr td.active.disabled:hover,
.dp-theme-danger .datepicker .datepicker-switch:hover,
.dp-theme-danger .datepicker .prev:hover,
.dp-theme-danger .datepicker .next:hover,
.dp-theme-danger .datepicker tfoot tr th:hover {
  background-color: #F44336;
  color: #fff;
}
.dp-theme-danger.datepicker-standalone .datepicker .datepicker-switch,
.dp-theme-danger.datepicker-standalone .datepicker .prev,
.dp-theme-danger.datepicker-standalone .datepicker .next,
.dp-theme-danger.datepicker-standalone .datepicker tfoot tr th {
  background-color: #F44336;
  color: #fff;
}
.dp-theme-danger .datepicker table tr td span.active:hover,
.dp-theme-danger .datepicker table tr td span.active:hover:hover,
.dp-theme-danger .datepicker table tr td span.active.disabled:hover,
.dp-theme-danger .datepicker table tr td span.active.disabled:hover:hover,
.dp-theme-danger .datepicker table tr td span.active:focus,
.dp-theme-danger .datepicker table tr td span.active:hover:focus,
.dp-theme-danger .datepicker table tr td span.active.disabled:focus,
.dp-theme-danger .datepicker table tr td span.active.disabled:hover:focus,
.dp-theme-danger .datepicker table tr td span.active:active,
.dp-theme-danger .datepicker table tr td span.active:hover:active,
.dp-theme-danger .datepicker table tr td span.active.disabled:active,
.dp-theme-danger .datepicker table tr td span.active.disabled:hover:active,
.dp-theme-danger .datepicker table tr td span.active.active,
.dp-theme-danger .datepicker table tr td span.active:hover.active,
.dp-theme-danger .datepicker table tr td span.active.disabled.active,
.dp-theme-danger .datepicker table tr td span.active.disabled:hover.active,
.dp-theme-danger .open .dropdown-toggle.datepicker table tr td span.active,
.dp-theme-danger .open .dropdown-toggle.datepicker table tr td span.active:hover,
.dp-theme-danger .open .dropdown-toggle.datepicker table tr td span.active.disabled,
.dp-theme-danger .open .dropdown-toggle.datepicker table tr td span.active.disabled:hover {
  background-color: #F44336;
  color: #fff;
}
.dp-theme-pink .datepicker table thead tr:first-child th {
  background-color: #E91E63;
  color: #fff;
}
.dp-theme-pink .datepicker table thead tr:last-child th {
  background-color: #d81557;
  color: #fff;
}
.dp-theme-pink .datepicker thead tr:first-child th:hover,
.dp-theme-pink .datepicker tfoot tr th:hover {
  background-color: #E91E63;
  color: #fff;
}
.dp-theme-pink .datepicker table tr td.active:hover,
.dp-theme-pink .datepicker table tr td.active:hover:hover,
.dp-theme-pink .datepicker table tr td.active.disabled:hover,
.dp-theme-pink .datepicker table tr td.active.disabled:hover:hover,
.dp-theme-pink .datepicker table tr td.active:focus,
.dp-theme-pink .datepicker table tr td.active:hover:focus,
.dp-theme-pink .datepicker table tr td.active.disabled:focus,
.dp-theme-pink .datepicker table tr td.active.disabled:hover:focus,
.dp-theme-pink .datepicker table tr td.active:active,
.dp-theme-pink .datepicker table tr td.active:hover:active,
.dp-theme-pink .datepicker table tr td.active.disabled:active,
.dp-theme-pink .datepicker table tr td.active.disabled:hover:active,
.dp-theme-pink .datepicker table tr td.active.active,
.dp-theme-pink .datepicker table tr td.active:hover.active,
.dp-theme-pink .datepicker table tr td.active.disabled.active,
.dp-theme-pink .datepicker table tr td.active.disabled:hover.active,
.dp-theme-pink .open .dropdown-toggle.datepicker table tr td.active,
.dp-theme-pink .open .dropdown-toggle.datepicker table tr td.active:hover,
.dp-theme-pink .open .dropdown-toggle.datepicker table tr td.active.disabled,
.dp-theme-pink .open .dropdown-toggle.datepicker table tr td.active.disabled:hover,
.dp-theme-pink .datepicker .datepicker-switch:hover,
.dp-theme-pink .datepicker .prev:hover,
.dp-theme-pink .datepicker .next:hover,
.dp-theme-pink .datepicker tfoot tr th:hover {
  background-color: #E91E63;
  color: #fff;
}
.dp-theme-pink.datepicker-standalone .datepicker .datepicker-switch,
.dp-theme-pink.datepicker-standalone .datepicker .prev,
.dp-theme-pink.datepicker-standalone .datepicker .next,
.dp-theme-pink.datepicker-standalone .datepicker tfoot tr th {
  background-color: #E91E63;
  color: #fff;
}
.dp-theme-pink .datepicker table tr td span.active:hover,
.dp-theme-pink .datepicker table tr td span.active:hover:hover,
.dp-theme-pink .datepicker table tr td span.active.disabled:hover,
.dp-theme-pink .datepicker table tr td span.active.disabled:hover:hover,
.dp-theme-pink .datepicker table tr td span.active:focus,
.dp-theme-pink .datepicker table tr td span.active:hover:focus,
.dp-theme-pink .datepicker table tr td span.active.disabled:focus,
.dp-theme-pink .datepicker table tr td span.active.disabled:hover:focus,
.dp-theme-pink .datepicker table tr td span.active:active,
.dp-theme-pink .datepicker table tr td span.active:hover:active,
.dp-theme-pink .datepicker table tr td span.active.disabled:active,
.dp-theme-pink .datepicker table tr td span.active.disabled:hover:active,
.dp-theme-pink .datepicker table tr td span.active.active,
.dp-theme-pink .datepicker table tr td span.active:hover.active,
.dp-theme-pink .datepicker table tr td span.active.disabled.active,
.dp-theme-pink .datepicker table tr td span.active.disabled:hover.active,
.dp-theme-pink .open .dropdown-toggle.datepicker table tr td span.active,
.dp-theme-pink .open .dropdown-toggle.datepicker table tr td span.active:hover,
.dp-theme-pink .open .dropdown-toggle.datepicker table tr td span.active.disabled,
.dp-theme-pink .open .dropdown-toggle.datepicker table tr td span.active.disabled:hover {
  background-color: #E91E63;
  color: #fff;
}
.dp-theme-purple .datepicker table thead tr:first-child th {
  background-color: #7E57C2;
  color: #fff;
}
.dp-theme-purple .datepicker table thead tr:last-child th {
  background-color: #7044bb;
  color: #fff;
}
.dp-theme-purple .datepicker thead tr:first-child th:hover,
.dp-theme-purple .datepicker tfoot tr th:hover {
  background-color: #7E57C2;
  color: #fff;
}
.dp-theme-purple .datepicker table tr td.active:hover,
.dp-theme-purple .datepicker table tr td.active:hover:hover,
.dp-theme-purple .datepicker table tr td.active.disabled:hover,
.dp-theme-purple .datepicker table tr td.active.disabled:hover:hover,
.dp-theme-purple .datepicker table tr td.active:focus,
.dp-theme-purple .datepicker table tr td.active:hover:focus,
.dp-theme-purple .datepicker table tr td.active.disabled:focus,
.dp-theme-purple .datepicker table tr td.active.disabled:hover:focus,
.dp-theme-purple .datepicker table tr td.active:active,
.dp-theme-purple .datepicker table tr td.active:hover:active,
.dp-theme-purple .datepicker table tr td.active.disabled:active,
.dp-theme-purple .datepicker table tr td.active.disabled:hover:active,
.dp-theme-purple .datepicker table tr td.active.active,
.dp-theme-purple .datepicker table tr td.active:hover.active,
.dp-theme-purple .datepicker table tr td.active.disabled.active,
.dp-theme-purple .datepicker table tr td.active.disabled:hover.active,
.dp-theme-purple .open .dropdown-toggle.datepicker table tr td.active,
.dp-theme-purple .open .dropdown-toggle.datepicker table tr td.active:hover,
.dp-theme-purple .open .dropdown-toggle.datepicker table tr td.active.disabled,
.dp-theme-purple .open .dropdown-toggle.datepicker table tr td.active.disabled:hover,
.dp-theme-purple .datepicker .datepicker-switch:hover,
.dp-theme-purple .datepicker .prev:hover,
.dp-theme-purple .datepicker .next:hover,
.dp-theme-purple .datepicker tfoot tr th:hover {
  background-color: #7E57C2;
  color: #fff;
}
.dp-theme-purple.datepicker-standalone .datepicker .datepicker-switch,
.dp-theme-purple.datepicker-standalone .datepicker .prev,
.dp-theme-purple.datepicker-standalone .datepicker .next,
.dp-theme-purple.datepicker-standalone .datepicker tfoot tr th {
  background-color: #7E57C2;
  color: #fff;
}
.dp-theme-purple .datepicker table tr td span.active:hover,
.dp-theme-purple .datepicker table tr td span.active:hover:hover,
.dp-theme-purple .datepicker table tr td span.active.disabled:hover,
.dp-theme-purple .datepicker table tr td span.active.disabled:hover:hover,
.dp-theme-purple .datepicker table tr td span.active:focus,
.dp-theme-purple .datepicker table tr td span.active:hover:focus,
.dp-theme-purple .datepicker table tr td span.active.disabled:focus,
.dp-theme-purple .datepicker table tr td span.active.disabled:hover:focus,
.dp-theme-purple .datepicker table tr td span.active:active,
.dp-theme-purple .datepicker table tr td span.active:hover:active,
.dp-theme-purple .datepicker table tr td span.active.disabled:active,
.dp-theme-purple .datepicker table tr td span.active.disabled:hover:active,
.dp-theme-purple .datepicker table tr td span.active.active,
.dp-theme-purple .datepicker table tr td span.active:hover.active,
.dp-theme-purple .datepicker table tr td span.active.disabled.active,
.dp-theme-purple .datepicker table tr td span.active.disabled:hover.active,
.dp-theme-purple .open .dropdown-toggle.datepicker table tr td span.active,
.dp-theme-purple .open .dropdown-toggle.datepicker table tr td span.active:hover,
.dp-theme-purple .open .dropdown-toggle.datepicker table tr td span.active.disabled,
.dp-theme-purple .open .dropdown-toggle.datepicker table tr td span.active.disabled:hover {
  background-color: #7E57C2;
  color: #fff;
}

/**
 * Material Forms
 */
.mda-form-group {
  position: relative;
  padding: 0 0 24px 0;
}
.mda-form-control {
  position: relative;
  padding-top: 18px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.14);
}
.mda-form-control > input,
.mda-form-control > textarea,
.mda-form-control > select {
  padding: 2px;
  height: 34px;
  background-color: transparent !important;
  border: 0;
  border-radius: 0;
  box-shadow: none;
}
.mda-form-control > input:focus ~ label,
.mda-form-control > textarea:focus ~ label,
.mda-form-control > select:focus ~ label,
.mda-form-control > input.focus ~ label,
.mda-form-control > textarea.focus ~ label,
.mda-form-control > select.focus ~ label {
  top: 0!important;
  font-size: .85em!important;
  color: #e2edee;
  opacity: 1;
}
.mda-form-control > input ~ label,
.mda-form-control > textarea ~ label,
.mda-form-control > select ~ label {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  display: inline-block;
  font-size: .85em;
  opacity: .5;
  font-weight: 500;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.mda-form-control > input.disabled,
.mda-form-control > textarea.disabled,
.mda-form-control > select.disabled,
.mda-form-control > input[disabled],
.mda-form-control > textarea[disabled],
.mda-form-control > select[disabled] {
  background-color: transparent;
  opacity: .5;
  cursor: not-allowed;
}
.mda-form-control.mda-form-control-dark {
  border-bottom-color: rgba(255, 255, 255, 0.26);
}
.mda-form-control.mda-form-control-dark > input,
.mda-form-control.mda-form-control-dark > textarea,
.mda-form-control.mda-form-control-dark > select {
  color: #fff;
}
.mda-form-control.mda-form-control-dark > input::-moz-placeholder,
.mda-form-control.mda-form-control-dark > textarea::-moz-placeholder,
.mda-form-control.mda-form-control-dark > select::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
  opacity: 1;
}
.mda-form-control.mda-form-control-dark > input:-ms-input-placeholder,
.mda-form-control.mda-form-control-dark > textarea:-ms-input-placeholder,
.mda-form-control.mda-form-control-dark > select:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.mda-form-control.mda-form-control-dark > input::-webkit-input-placeholder,
.mda-form-control.mda-form-control-dark > textarea::-webkit-input-placeholder,
.mda-form-control.mda-form-control-dark > select::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.mda-form-control.mda-form-control-dark > input:focus ~ label,
.mda-form-control.mda-form-control-dark > textarea:focus ~ label,
.mda-form-control.mda-form-control-dark > select:focus ~ label,
.mda-form-control.mda-form-control-dark > input.focus ~ label,
.mda-form-control.mda-form-control-dark > textarea.focus ~ label,
.mda-form-control.mda-form-control-dark > select.focus ~ label {
  color: #fff !important;
}
.mda-form-group-lg .mda-form-control > input {
  height: 48px;
}
.mda-form-group-lg.float-label .mda-form-control > input ~ label {
  top: 28px;
  font-size: 1.5em;
}
.mda-form-control > textarea {
  height: auto;
}
.mda-form-control-line {
  position: absolute;
  bottom: -1px;
  left: 50%;
  right: 50%;
  height: 2px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.mda-form-control > input:focus ~ .mda-form-control-line,
.mda-form-control > textarea:focus ~ .mda-form-control-line,
.mda-form-control > select:focus ~ .mda-form-control-line {
  background-color: #e2edee;
  left: 0;
  right: 0;
}
.mda-form-control.mda-form-control-dark > input:focus ~ .mda-form-control-line,
.mda-form-control.mda-form-control-dark > textarea:focus ~ .mda-form-control-line,
.mda-form-control.mda-form-control-dark > select:focus ~ .mda-form-control-line {
  background-color: #fff;
}
.mda-form-msg {
  position: absolute;
  bottom: 0;
  font-size: .85em;
  line-height: 24px;
  font-weight: 300;
  color: #90A4AE;
}
.mda-form-msg.right {
  right: 0;
}
.form-validate .mda-form-control .form-control.error ~ .mda-form-control-line {
  background-color: #F44336;
}
.form-validate .mda-form-control .form-control.error:focus ~ label,
.form-validate .mda-form-control .form-control.error.focus ~ label,
.form-validate .mda-form-control .form-control.error ~ .mda-form-msg {
  color: #F44336;
}
.form-validate .mda-form-control .form-control.valid ~ .mda-form-control-line,
.form-validate .mda-form-control .form-control.valid:focus ~ .mda-form-control-line {
  background-color: #4CAF50;
}
.form-validate .mda-form-control .form-control.valid:focus ~ label,
.form-validate .mda-form-control .form-control.valid:focus:focus ~ label,
.form-validate .mda-form-control .form-control.valid.focus ~ label,
.form-validate .mda-form-control .form-control.valid:focus.focus ~ label {
  color: #4CAF50;
}
.form-validate label.error {
  color: #F44336;
}
.form-validate label.valid {
  color: #4CAF50;
}
.float-label .mda-form-control > input ~ label,
.float-label .mda-form-control > textarea ~ label {
  top: 25px;
  margin-bottom: 0;
  font-size: 1em;
  pointer-events: none;
}
.float-label .mda-form-control > input.valid ~ label,
.float-label .mda-form-control > textarea.valid ~ label,
.float-label .mda-form-control > input.error ~ label,
.float-label .mda-form-control > textarea.error ~ label,
.float-label .mda-form-control > input.has-value ~ label,
.float-label .mda-form-control > textarea.has-value ~ label {
  top: 0;
  font-size: .85em;
}
.mda-input-group {
  position: relative;
}
.mda-input-group .mda-form-control > input {
  padding-left: 36px;
}
.mda-input-group .mda-form-control > label {
  margin-left: 36px;
}
.mda-input-group .form-control {
  float: none;
}
.mda-input-group > .mda-input-group-addon {
  position: absolute;
  top: 25px;
  left: 2px;
}
.mda-input-group .mda-form-group + .input-group-btn,
.mda-input-group .input-group-btn + .mda-form-group {
  padding-left: 16px;
}
.mda-checkbox,
.mda-radio {
  position: relative;
  display: inline-block;
  padding-left: 20px;
  margin: 0;
  cursor: pointer;
}
.mda-checkbox input,
.mda-radio input {
  position: absolute;
  z-index: 1;
  width: 20px;
  height: 20px;
  margin-left: -20px;
  cursor: pointer;
  opacity: 0;
}
.mda-checkbox input:checked + em:before,
.mda-radio input:checked + em:before {
  background-color: inherit;
  border-width: 0;
}
.mda-checkbox input:checked + span .active,
.mda-radio input:checked + span .active {
  display: inherit;
}
.mda-checkbox input[type="checkbox"]:checked + em:after,
.mda-radio input[type="checkbox"]:checked + em:after {
  position: absolute;
  top: 2px;
  left: 6px;
  display: table;
  width: 6px;
  height: 12px;
  border: 2px solid;
  border-top: 0;
  border-left: 0;
  content: ' ';
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.mda-checkbox input[type="radio"]:checked + em:after,
.mda-radio input[type="radio"]:checked + em:after {
  content: ' ';
  position: absolute;
  top: 3px;
  left: 3px;
  display: table;
  width: 12px;
  height: 12px;
  background: inherit;
  border-radius: 50%;
  border: 2px solid #fff;
}
.mda-checkbox input[type="radio"] + em,
.mda-radio input[type="radio"] + em,
.mda-checkbox input[type="radio"] + em:before,
.mda-radio input[type="radio"] + em:before {
  border-radius: 50%;
}
.mda-checkbox input[disabled] + em:before,
fieldset[disabled] .mda-checkbox input + em:before,
.mda-radio input[disabled] + em:before,
fieldset[disabled] .mda-radio input + em:before {
  border-color: rgba(0, 0, 0, 0.26);
}
.mda-checkbox input[disabled]:checked + em,
.mda-radio input[disabled]:checked + em {
  background-color: rgba(0, 0, 0, 0.26);
}
.mda-radio input[disabled]:checked + em:after {
  background-color: transparent;
}
.mda-checkbox input[disabled]:checked + em:before {
  background-color: rgba(0, 0, 0, 0.26);
}
.mda-checkbox > em,
.mda-radio > em {
  position: relative;
  display: inline-block;
  width: 0;
  height: 18px;
  margin-top: -2px;
  margin-right: 28px;
  margin-left: -20px;
  line-height: 1;
  vertical-align: middle;
}
.mda-checkbox > em:before,
.mda-radio > em:before {
  content: "";
  position: absolute;
  width: 18px;
  height: 100%;
  border: 2px solid rgba(162, 162, 162, 0.56);
  border-radius: 2px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.mda-checkbox > em.empty:after,
.mda-radio > em.empty:after {
  display: none !important;
}

/**
 * Forms Validation
 */
.form-validate .form-control.error {
  border-color: #F44336;
}
.form-validate .form-control.valid,
.form-validate .form-control.valid:focus {
  border-color: #4CAF50;
}
.form-validate .has-error .form-control {
  border-color: #F44336 !important;
}
.form-validate .c-checkbox input.error + span,
.form-validate .c-radio input.error + span {
  border-color: #F44336;
}

/* ========================================================================
   Component: form-wizard.less
 ========================================================================== */
.wizard,
.tabcontrol {
  display: block;
  width: 100%;
  overflow: hidden;
}
.wizard a,
.tabcontrol a {
  outline: 0;
}
.wizard ul,
.tabcontrol ul {
  list-style: none !important;
  padding: 0;
  margin: 0;
}
.wizard ul > li,
.tabcontrol ul > li {
  display: block;
  padding: 0;
}
.wizard > .steps .current-info,
.tabcontrol > .steps .current-info {
  position: absolute;
  left: -999em;
}
.wizard > .content > .title,
.tabcontrol > .content > .title {
  position: absolute;
  left: -999em;
}
.wizard > .steps {
  position: relative;
  display: block;
  width: 100%;
}
.wizard > .steps .number {
  font-size: 1.429em;
}
.wizard > .steps > ul > li {
  width: 25%;
}
.wizard > .content {
  background-color: transparent;
  display: block;
  margin: 0.5em;
  min-height: 300px;
  overflow: hidden;
  position: relative;
  width: auto;
  border-radius: 5px;
}
.wizard > .content > .body {
  float: left;
  position: absolute;
  width: 100%;
  height: 95%;
  padding: 2.5% 0;
}
.wizard > .content > .body ul {
  list-style: disc !important;
}
.wizard > .content > .body ul > li {
  display: list-item;
}
.wizard > .content > .body > iframe {
  border: 0 none;
  width: 100%;
  height: 100%;
}
.wizard > .content > .body input {
  display: block;
}
.wizard > .content > .body input.error {
  border-color: #F44336;
}
.wizard > .content > .body input.valid {
  border-color: #4CAF50;
}
.wizard > .content > .body label {
  display: inline-block;
  margin-bottom: 0.5em;
}
.wizard > .content > .body label.error {
  color: #F44336;
  display: block !important;
  padding: 0;
}
.wizard > .content > .body label.valid {
  border-color: #4CAF50;
}
.wizard > .actions {
  position: relative;
  display: block;
  text-align: right;
  width: 100%;
}
.wizard > .actions > ul {
  display: inline-block;
  text-align: right;
}
.wizard > .actions > ul > li {
  margin: 0 0.5em;
}
.wizard.vertical > .steps {
  display: inline;
  float: left;
  width: 30%;
}
.wizard.vertical > .steps > ul > li {
  float: none;
  width: 100%;
}
.wizard.vertical > .steps .current a,
.wizard.vertical > .steps .current a:hover,
.wizard.vertical > .steps .current a:active {
  border-left: 2px solid #e2edee;
  border-bottom: 0;
}
.wizard.vertical > .content {
  display: inline;
  float: left;
  margin: 0 2.5% 0.5em 2.5%;
  width: 65%;
}
.wizard.vertical > .actions {
  display: inline;
  float: right;
  margin: 0 2.5%;
  width: 95%;
}
.wizard.vertical > .actions > ul > li {
  margin: 0 0 0 1em;
}
.wizard > .steps > ul > li,
.wizard > .actions > ul > li {
  float: left;
}
.wizard > .steps a,
.wizard > .steps a:hover,
.wizard > .steps a:active {
  display: block;
  width: auto;
  margin: 0 0.5em 0.5em;
  padding: 1em 1em;
  text-decoration: none;
  border-radius: 0;
  font-weight: bold;
  color: inherit;
}
.wizard > .steps a > small,
.wizard > .steps a:hover > small,
.wizard > .steps a:active > small {
  font-weight: normal;
  color: #a2a2a2;
}
.wizard > .steps .disabled a,
.wizard > .steps .disabled a:hover,
.wizard > .steps .disabled a:active {
  background: transparent;
  color: inherit;
  cursor: default;
}
.wizard > .steps .current a,
.wizard > .steps .current a:hover,
.wizard > .steps .current a:active {
  border-bottom: 2px solid #e2edee;
  cursor: default;
}
.wizard > .steps .done a,
.wizard > .steps .done a:hover,
.wizard > .steps .done a:active {
  background: transparent;
  color: inherit;
}
.wizard > .actions a,
.wizard > .actions a:hover,
.wizard > .actions a:active {
  display: inline-block;
  padding: 6px 16px;
  font-size: 13px;
  border-radius: 3px;
  text-decoration: none;
  color: #fff;
  background: #e2edee;
  border: 0;
}
.wizard > .actions .disabled a,
.wizard > .actions .disabled a:hover,
.wizard > .actions .disabled a:active {
  color: #6C7177;
  background: #ccc;
  border: 0;
}
.tabcontrol > .steps {
  position: relative;
  display: block;
  width: 100%;
}
.tabcontrol > .steps > ul {
  position: relative;
  margin: 6px 0 0 0;
  top: 1px;
  z-index: 1;
}
.tabcontrol > .steps > ul > li {
  float: left;
  margin: 5px 2px 0 0;
  padding: 1px;
  -webkit-border-top-left-radius: 5px;
  -webkit-border-top-right-radius: 5px;
  -moz-border-radius-topleft: 5px;
  -moz-border-radius-topright: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.tabcontrol > .steps > ul > li > a {
  color: #5f5f5f;
  display: inline-block;
  border: 0 none;
  margin: 0;
  padding: 10px 30px;
  text-decoration: none;
}
.tabcontrol > .steps > ul > li > a:hover {
  text-decoration: none;
}
.tabcontrol > .steps > ul > li:hover {
  background: #edecec;
  border: 1px solid #bbb;
  padding: 0;
}
.tabcontrol > .steps > ul > li.current {
  background: #fff;
  border: 1px solid #bbb;
  border-bottom: 0 none;
  padding: 0 0 1px 0;
  margin-top: 0;
}
.tabcontrol > .steps > ul > li.current > a {
  padding: 15px 30px 10px 30px;
}
.tabcontrol > .content {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 300px;
  overflow: hidden;
  border-top: 1px solid #bbb;
  padding-top: 20px;
}
.tabcontrol > .content > .body {
  float: left;
  position: absolute;
  width: 95%;
  height: 95%;
  padding: 2.5%;
}
.tabcontrol > .content > .body ul {
  list-style: disc !important;
}
.tabcontrol > .content > .body ul > li {
  display: list-item;
}

/**
 * Forms Xeditable
 */
[data-type="textarea"] + .editable-container.editable-inline {
  width: 100%;
}
[data-type="textarea"] + .editable-container.editable-inline .editableform .control-group,
[data-type="textarea"] + .editable-container.editable-inline .editable-input,
[data-type="textarea"] + .editable-container.editable-inline .editableform textarea.form-control {
  width: 100%;
}

/**
 * Header
 */
.header-container {
  position: relative;
  background-color: #fff;
  z-index: 1;
}
@media only screen and (min-width: 992px) {
  .header-container {
    margin-left: 240px;
  }
}
.header-container > nav {
  min-height: 65px;
  line-height: 64px;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.14);
}
.header-container > nav:before,
.header-container > nav:after {
  content: " ";
  display: table;
}
.header-container > nav:after {
  clear: both;
}
.header-container > nav > .header-title {
  display: none;
  position: relative;
  float: left;
  margin: 0;
  padding: 0 16px;
  font-size: 20px;
  line-height: inherit;
  letter-spacing: .02em;
  font-weight: 400;
}
@media (min-width: 340px) {
  .header-container > nav > .header-title {
    display: block;
  }
}
@media (max-width: 420px) {
  .header-container > nav > .header-title {
    max-width: 140px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
.header-container > nav > ul {
  float: left;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.header-container > nav > ul:before,
.header-container > nav > ul:after {
  content: " ";
  display: table;
}
.header-container > nav > ul:after {
  clear: both;
}
.header-container > nav > ul > li {
  float: left;
}
.header-container > nav > ul > li > a {
  display: block;
  padding: 0 16px;
  text-decoration: none;
  color: inherit;
}
.header-container > nav > ul > li > a > em {
  font-size: 24px;
  vertical-align: -24%;
  line-height: 1;
}
.header-container > nav > ul > li > a:hover {
  background-color: rgba(0, 0, 0, 0.025);
}
#offcanvas-toggler {
  display: none;
}
.sidebar-offcanvas #offcanvas-toggler {
  display: block;
}
.header-form-search {
  position: relative;
  margin: 0;
  overflow: hidden;
  -webkit-transition: width 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  transition: width 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}
.header-form-search .btn,
.header-form-search .form-control {
  margin: 0;
  border: 0;
  background-color: transparent;
  box-shadow: 0 0 0 #000;
}
.header-form-search .btn {
  padding-top: 0;
  padding-bottom: 0;
}
.header-form-search .form-control:focus + .focus-bottom-line {
  left: 40px;
  right: 40px;
  visibility: visible;
}
.header-form-search .focus-bottom-line {
  content: '';
  position: absolute;
  bottom: 0;
  left: 45%;
  right: 45%;
  height: 2px;
  visibility: hidden;
  background-color: rgba(120, 120, 120, 0.15);
  -webkit-transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

/**
 * Header menu links (animated hamburguer toggles)
 */
.header-container > nav > ul > li > .menu-link {
  height: 65px;
  padding-top: 25px;
}
.menu-link-close {
  display: block;
  position: relative;
}
.menu-link-close span {
  position: relative;
  display: block;
}
.menu-link-close span,
.menu-link-close span:before,
.menu-link-close span:after {
  width: 18px;
  height: 0;
  border-bottom: 2px solid;
  will-change: transform;
  -webkit-transition: -webkit-transform 0.4s;
  transition: -webkit-transform 0.4s;
  transition: transform 0.4s;
  transition: transform 0.4s, -webkit-transform 0.4s;
}
.menu-link-close span {
  -webkit-transition: border-color 0.4s;
  transition: border-color 0.4s;
}
.menu-link-close span:before,
.menu-link-close span:after {
  content: " ";
  position: absolute;
}
.menu-link-close span:before {
  top: 6px;
}
.menu-link-close span:after {
  top: 12px;
}
.active .menu-link-close span {
  border-color: transparent;
}
.active .menu-link-close span:before {
  -webkit-transform: rotate(45deg) translate(1px, 0px);
  transform: rotate(45deg) translate(1px, 0px);
}
.active .menu-link-close span:after {
  -webkit-transform: rotate(-45deg) translate(4px, -3px);
  transform: rotate(-45deg) translate(4px, -3px);
}
.menu-link-slide {
  display: block;
  position: relative;
}
.menu-link-slide span {
  position: relative;
  display: block;
}
.menu-link-slide span,
.menu-link-slide span:before,
.menu-link-slide span:after {
  width: 18px;
  height: 0;
  border-bottom: 2px solid;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.menu-link-slide span:before,
.menu-link-slide span:after {
  content: " ";
  position: absolute;
}
.menu-link-slide span:before {
  top: 7px;
  left: 0;
  width: 60%;
  will-change: left;
}
.menu-link-slide span:after {
  top: 14px;
}
.menu-link-slide em {
  position: absolute;
  top: 4px;
  right: 0;
  width: 0;
  height: 0;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 4px solid;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  will-change: right, transform;
}
.active .menu-link-slide span:before {
  left: 40%;
}
.active .menu-link-slide span em {
  right: 80%;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.menu-link-arrow {
  display: block;
  position: relative;
}
.menu-link-arrow span {
  position: relative;
  display: block;
  will-change: transform;
  height: 14px;
  width: 18px;
  -webkit-transition: -webkit-transform 0.4s;
  transition: -webkit-transform 0.4s;
  transition: transform 0.4s;
  transition: transform 0.4s, -webkit-transform 0.4s;
}
.menu-link-arrow em,
.menu-link-arrow em:before,
.menu-link-arrow em:after {
  content: " ";
  position: absolute;
  display: block;
  width: 18px;
  height: 0;
  border-bottom: 2px solid;
  will-change: transform;
  -webkit-transition: -webkit-transform 0.4s;
  transition: -webkit-transform 0.4s;
  transition: transform 0.4s;
  transition: transform 0.4s, -webkit-transform 0.4s;
}
.menu-link-arrow em {
  -webkit-transition: border-color 0.4s;
  transition: border-color 0.4s;
}
.menu-link-arrow em:before {
  top: -6px;
}
.menu-link-arrow em {
  top: 6px;
}
.menu-link-arrow em:after {
  top: 6px;
}
.active .menu-link-arrow span {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.active .menu-link-arrow span em:before {
  width: 12px;
  transform: translateX(6px) translateY(1px) rotate(45deg);
  -webkit-transform: translateX(6px) translateY(1px) rotate(45deg);
}
.active .menu-link-arrow span em:after {
  width: 12px;
  transform: translateX(6px) translateY(-1px) rotate(-45deg);
  -webkit-transform: translateX(6px) translateY(-1px) rotate(-45deg);
}

/**
 * Google Maps
 */
.gmap,
.angular-google-map-container {
  height: 300px;
}
.gmap.gmap-sm,
.angular-google-map-container.gmap-sm {
  height: 150px;
}
.fh .angular-google-map,
.fh .angular-google-map-container {
  height: 100% !important;
}

/**
 * Vector Maps
 */
.vector-map {
  height: 400px;
}
.vector-map svg {
  width: 100%;
}
.vector-map .jvectormap-container {
  background-color: transparent !important;
}
.vector-map .jvectormap-zoomin,
.vector-map .jvectormap-zoomout {
  position: absolute;
  left: 10px;
  width: 22px;
  height: 22px;
  border-radius: 2px;
  background: #515253;
  padding: 5px;
  color: white;
  cursor: pointer;
  line-height: 10px;
  text-align: center;
}
.vector-map .jvectormap-zoomin {
  top: 10px;
}
.vector-map .jvectormap-zoomout {
  top: 30px;
}
.jvectormap-label {
  position: absolute;
  display: none;
  border: solid 1px #313232;
  border-radius: 2px;
  background: #313232;
  color: white;
  padding: 3px 6px;
  opacity: 0.9;
  z-index: 11;
}

/**
 * Timeline
 */
.timeline,
.timeline-alt {
  list-style: none;
  padding: 0 0 20px;
  position: relative;
  z-index: 0;
}
.timeline:before,
.timeline-alt:before {
  position: absolute;
  top: 0;
  bottom: 0;
  content: " ";
  width: 1px;
  background-color: rgba(162, 162, 162, 0.36);
  left: 10px;
}
.timeline .timeline-end,
.timeline-alt .timeline-end {
  float: none;
  clear: both;
}
.timeline > li,
.timeline-alt > li {
  margin-bottom: 20px;
}
.timeline > li:before,
.timeline-alt > li:before,
.timeline > li:after,
.timeline-alt > li:after {
  content: " ";
  display: table;
}
.timeline > li:after,
.timeline-alt > li:after {
  clear: both;
}
.timeline > li.timeline-separator,
.timeline-alt > li.timeline-separator {
  position: relative;
  float: none;
  clear: both;
  margin: 0 auto;
  padding: 40px 0;
}
.timeline > li.timeline-separator:first-child,
.timeline-alt > li.timeline-separator:first-child {
  padding-top: 0;
}
.timeline > li.timeline-separator:before,
.timeline-alt > li.timeline-separator:before {
  content: attr(data-datetime);
  display: inline-block;
  width: 120px;
  text-align: center;
  padding: 0 20px;
  line-height: 30px;
  background-color: #607D8B;
  color: #fff;
}
.timeline > li > .timeline-panel,
.timeline-alt > li > .timeline-panel {
  margin-left: 40px;
}
.timeline > li > .timeline-date,
.timeline-alt > li > .timeline-date {
  margin: -20px 0 0;
  display: block;
  height: 20px;
  line-height: 20px;
  font-size: 13px;
}
.timeline > li > .timeline-date > time:after,
.timeline-alt > li > .timeline-date > time:after {
  content: attr(datetime);
}
.timeline > li > .timeline-badge,
.timeline-alt > li > .timeline-badge {
  position: absolute;
  left: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  line-height: 20px;
  font-size: 1.2em;
  text-align: center;
  color: #fff;
  z-index: 100;
}
.timeline > li > .timeline-badge:after,
.timeline-alt > li > .timeline-badge:after {
  content: "";
  background-color: #ffffff;
  position: absolute;
  top: 5px;
  right: 5px;
  bottom: 5px;
  left: 5px;
  display: block;
  border-radius: 50%;
}
.timeline-title {
  margin-top: 0;
  color: inherit;
}
.timeline-body > p,
.timeline-body > ul {
  margin-bottom: 0;
}
.timeline-body > p + p {
  margin-top: 5px;
}
@media only screen and (min-width: 768px) {
  .timeline:before {
    left: 50%;
    margin-left: 10px;
  }
  .timeline > li {
    float: left;
    clear: left;
    width: 48%;
  }
  .timeline > li.timeline-inverted {
    float: right;
    clear: right;
    margin-top: 50px;
  }
  .timeline > li.timeline-separator {
    text-align: center;
  }
  .timeline > li.timeline-separator:before {
    margin-left: 20px;
  }
  .timeline > li > .timeline-badge {
    left: 50%;
    margin-left: 0;
  }
  .timeline > li > .timeline-date {
    float: none;
    position: absolute;
    width: 40%;
    left: 50%;
    top: 10px;
    margin-left: 30px;
    margin-top: -10px;
  }
  .timeline > li > .timeline-panel {
    margin: 0;
  }
  .timeline > li > .timeline-panel:before {
    border-left-width: 15px;
    border-right-width: 0;
    top: 26px;
    right: -15px;
    left: auto;
  }
  .timeline > li > .timeline-panel:after {
    border-left-width: 14px;
    border-right-width: 0;
    top: 27px;
    right: -14px;
    left: auto;
  }
  .timeline > li.timeline-inverted > .timeline-panel {
    padding-left: 20px;
  }
  .timeline > li.timeline-inverted .timeline-date {
    left: auto;
    right: 50%;
    width: auto;
    margin-left: 0;
    margin-right: 20px;
  }
}

/**
 * Ripple
 */
.ripple {
  position: relative;
  overflow: hidden;
}
.ripple > .md-ripple {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  bottom: 0;
  right: 0;
  overflow: hidden;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.06);
  border-radius: 50%;
  -webkit-transform: scale(0.0001, 0.0001);
  transform: scale(0.0001, 0.0001);
  opacity: 1;
  width: 0;
  height: 0;
}
.ripple > .md-ripple.md-ripple-animate {
  -webkit-transition: -webkit-transform 0.5s cubic-bezier(0, 0, 0.2, 1), opacity 0.6s cubic-bezier(0, 0, 0.2, 1);
  -webkit-transition: opacity 0.6s cubic-bezier(0, 0, 0.2, 1), -webkit-transform 0.5s cubic-bezier(0, 0, 0.2, 1);
  transition: opacity 0.6s cubic-bezier(0, 0, 0.2, 1), -webkit-transform 0.5s cubic-bezier(0, 0, 0.2, 1);
  transition: transform 0.5s cubic-bezier(0, 0, 0.2, 1), opacity 0.6s cubic-bezier(0, 0, 0.2, 1);
  transition: transform 0.5s cubic-bezier(0, 0, 0.2, 1), opacity 0.6s cubic-bezier(0, 0, 0.2, 1), -webkit-transform 0.5s cubic-bezier(0, 0, 0.2, 1);
  opacity: 0;
  -webkit-transform: scale(2);
  transform: scale(2);
}
.ripple.btn-oval > .md-ripple.md-ripple-animate,
.ripple.btn-pill-left > .md-ripple.md-ripple-animate,
.ripple.btn-pill-right > .md-ripple.md-ripple-animate,
.ripple.btn-flat-icon > .md-ripple.md-ripple-animate,
.ripple.btn-circle > .md-ripple.md-ripple-animate {
  -webkit-transform: scale(0.99);
  transform: scale(0.99);
}

/**
 * Settings
 */
.setting-color {
  padding: 0 5px;
}
.setting-color > label {
  display: block;
  position: relative;
  height: 30px;
  margin: 0 10px;
  border-radius: 1px;
  cursor: pointer;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.14), 0 2px 2px 0 rgba(0, 0, 0, 0.098), 0 1px 5px 0 rgba(0, 0, 0, 0.014);
}
.setting-color > label:first-child {
  margin-left: 0;
}
.setting-color > label:last-child {
  margin-right: 0;
}
.setting-color > label .t-grid,
.setting-color > label .t-row {
  max-width: 60px;
  height: 100%;
  margin: 0;
}
.setting-color > label > .color {
  display: block;
  height: 18px;
}
.setting-color > label > .split {
  display: block;
}
.setting-color > label > .split:before,
.setting-color > label > .split:after {
  content: " ";
  display: table;
}
.setting-color > label > .split:after {
  clear: both;
}
.setting-color > label > .split > .color {
  display: block;
  height: 37.5px;
}
.setting-color > label > .split > .color:first-child {
  float: left;
  width: 70%;
}
.setting-color > label > .split > .color:last-child {
  float: right;
  width: 30%;
}
.setting-color > label > .ion-checkmark-round {
  position: absolute;
  display: block;
  right: -5px;
  top: -10px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  text-align: center;
  font-size: 14px;
  color: #4CAF50;
  background-color: #fff;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.14), 0 2px 2px 0 rgba(0, 0, 0, 0.098), 0 1px 5px 0 rgba(0, 0, 0, 0.014);
  -webkit-transition: -webkit-transform 0.2s ease;
  transition: -webkit-transform 0.2s ease;
  transition: transform 0.2s ease;
  transition: transform 0.2s ease, -webkit-transform 0.2s ease;
  -webkit-transform: scale(0);
  transform: scale(0);
}
.setting-color > label > input[type="radio"] {
  position: absolute;
  visibility: hidden;
}
.setting-color > label > input[type="radio"]:checked + .ion-checkmark-round {
  opacity: 1 !important;
  -webkit-transform: scale(1);
  transform: scale(1);
}

/**
 * Sidebar
 */
.sidebar-container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 240px;
  overflow-y: auto;
  overflow-x: visible;
  -webkit-overflow-scrolling: touch;
  background-color: #263238;
  color: rgba(255, 255, 255, 0.75);
  z-index: 20;
}
.sidebar-container a.sb-link {
  color: rgba(255, 255, 255, 0.75);
  text-decoration: none;
}
.sidebar-header {
  height: 65px;
  padding: 0 16px;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.14);
}
.sidebar-header > .sidebar-header-logo {
  display: inline-block;
  height: 100%;
  line-height: 65px;
  text-decoration: none;
  color: inherit;
  font-size: 22px;
  letter-spacing: .023em;
}
.sidebar-header > .sidebar-header-logo > img,
.sidebar-header > .sidebar-header-logo > svg {
  display: inline-block;
  width: 24px;
  height: 24px;
  vertical-align: -5%;
}
.sidebar-header > .sidebar-header-logo > img + .sidebar-header-logo-text,
.sidebar-header > .sidebar-header-logo > svg + .sidebar-header-logo-text {
  margin-left: 16px;
}
.sidebar-header > .sidebar-header-logo > svg {
  fill: #fff;
}
.sidebar-header > .sidebar-header-logo-text {
  font-weight: 500;
  font-size: 20px;
  vertical-align: middle;
}
.sidebar-header + .sidebar-content {
  height: calc(100% - 65px);
  overflow: auto;
}
.sidebar-content {
  border-right: 1px solid rgba(0, 0, 0, 0.11);
}
.sidebar-toolbar {
  position: relative;
  z-index: -1;
  background-color: inherit;
  background-repeat: no-repeat;
  background-position: left top;
  background-size: 100%;
  padding: 60px 20px;
}
.sidebar-toolbar + .sidebar-nav {
  padding-top: 0;
}
.sidebar-container {
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
  -webkit-transition: -webkit-transform 0.28s ease-in-out;
  transition: -webkit-transform 0.28s ease-in-out;
  transition: transform 0.28s ease-in-out;
  transition: transform 0.28s ease-in-out, -webkit-transform 0.28s ease-in-out;
  will-change: transform;
}
@media only screen and (min-width: 992px) {
  .sidebar-container {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.sidebar-layout-obfuscator {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
  visibility: hidden;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.3);
  will-change: opacity;
  -webkit-transition: opacity .28s ease-in-out, visibility .28s ease-in-out;
  transition: opacity .28s ease-in-out, visibility .28s ease-in-out;
}
@media only screen and (min-width: 992px) {
  .sidebar-layout-obfuscator {
    display: none;
  }
}
@media only screen and (max-width: 991px) {
  .sidebar-visible {
    overflow: hidden;
  }
  .sidebar-visible .sidebar-container {
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .sidebar-visible .sidebar-layout-obfuscator {
    opacity: 1;
    visibility: visible;
  }
}
.no-csstransforms3d .sidebar-container {
  margin-left: -240px;
}
@media only screen and (min-width: 992px) {
  .no-csstransforms3d .sidebar-container {
    margin-left: 0;
  }
}
@media only screen and (max-width: 1199px) {
  .no-csstransforms3d .sidebar-visible .sidebar-container {
    margin-left: 0;
  }
}
.sidebar-nav {
  padding-top: 16px;
  font-weight: 500;
  background-color: inherit;
}
.sidebar-nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.sidebar-nav ul > li > a {
  display: block;
  padding: 0 16px;
  height: 50px;
  line-height: 50px;
  text-decoration: none;
  letter-spacing: 0.0125em;
  color: inherit;
  overflow: hidden;
}
.sidebar-nav ul > li > a > .nav-icon {
  position: relative;
  display: inline-block;
  width: 1em;
  height: 1em;
  margin-right: 16px;
  text-align: center;
  font-size: 24px;
  vertical-align: middle;
}
.sidebar-nav ul > li > a > .nav-icon > em,
.sidebar-nav ul > li > a > .nav-icon > img,
.sidebar-nav ul > li > a > .nav-icon > svg {
  position: absolute;
  display: inline-block;
  color: inherit;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  line-height: 1;
  color: #e2edee;
  fill: #64B5F6;
}
.sidebar-nav ul > li > a > .nav-label,
.sidebar-nav ul > li > a > .nav-caret {
  line-height: inherit;
}
.sidebar-nav ul > li > a > .nav-label {
  margin-right: 16px;
  margin-top: -2px;
}
.sidebar-nav ul > li > a > .nav-caret {
  margin-left: 11px;
}
.sidebar-nav ul > li > a > .nav-caret + .nav-label {
  margin-right: 0;
}
.sidebar-nav ul > li > a:hover,
.sidebar-nav ul > li > a:focus {
  background-color: rgba(162, 162, 162, 0.09);
}
.sidebar-nav ul > li > a:hover > .nav-icon em,
.sidebar-nav ul > li > a:focus > .nav-icon em {
  color: #e2edee;
}
.sidebar-nav ul > li.active {
  background-color: rgba(162, 162, 162, 0.026);
}
.sidebar-nav ul > li.active > a > .nav-icon em {
  color: #e2edee;
}
.sidebar-nav ul > li.active > ul li.active a {
  background-color: rgba(162, 162, 162, 0.026);
}
.sidebar-nav ul > li ul > li > a {
  padding-left: 72px;
}
.sidebar-nav ul > li ul > li ul > li > a {
  padding-left: 88px;
}
.sidebar-nav ul > li > ul {
  height: 0;
  overflow: hidden;
  -webkit-transition: height 0.55s cubic-bezier(0.35, 0, 0.25, 1);
  transition: height 0.55s cubic-bezier(0.35, 0, 0.25, 1);
}
.sidebar-nav ul > li > ul > li > a {
  margin-left: -20px;
  opacity: 0;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
  -webkit-transition-property: opacity, margin-left;
  transition-property: opacity, margin-left;
}
.sidebar-nav ul > li > a > .nav-caret {
  -webkit-transition: -webkit-transform 0.2s ease;
  transition: -webkit-transform 0.2s ease;
  transition: transform 0.2s ease;
  transition: transform 0.2s ease, -webkit-transform 0.2s ease;
}
.sidebar-nav ul > li.active > ul {
  height: auto;
}
.sidebar-nav ul > li.active > ul > li > a {
  opacity: 1;
  margin-left: 0;
}
.sidebar-nav ul > li.active > a > .nav-caret {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

/**
 * Tables - Bootgrid
 */
.bootgrid .table > thead > tr > td.active,
.bootgrid .table > tbody > tr > td.active,
.bootgrid .table > tfoot > tr > td.active,
.bootgrid .table > thead > tr > th.active,
.bootgrid .table > tbody > tr > th.active,
.bootgrid .table > tfoot > tr > th.active,
.bootgrid .table > thead > tr.active > td,
.bootgrid .table > tbody > tr.active > td,
.bootgrid .table > tfoot > tr.active > td,
.bootgrid .table > thead > tr.active > th,
.bootgrid .table > tbody > tr.active > th,
.bootgrid .table > tfoot > tr.active > th {
  background-color: #ECEFF1;
}
.bootgrid-header .search .form-control {
  border: 0 !important;
  border-bottom: 1px solid rgba(162, 162, 162, 0.12) !important;
  border-radius: 0;
  background-color: transparent !important;
}
.bootgrid-header .search .form-control:focus {
  border-bottom-color: #e2edee !important;
}
.bootgrid-header .search .input-group-addon {
  border: 0 !important;
  background-color: transparent;
}
.bootgrid-header .search.form-group {
  float: left;
}
.bootgrid-header .dropdown > .btn {
  border: 0;
}
.bootgrid-header .dropdown > .btn:hover,
.bootgrid-header .dropdown > .btn:focus {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.14), 0 2px 2px 0 rgba(0, 0, 0, 0.098), 0 1px 5px 0 rgba(0, 0, 0, 0.014);
}
.bootgrid-header .dropdown .dropdown-item {
  color: #555;
}
.bootgrid-header .dropdown .active > .dropdown-item {
  color: #fff;
}
.bootgrid-table th > .column-header-anchor {
  color: inherit;
}
.bootgrid-table th:hover,
.bootgrid-table th:active {
  background-color: rgba(162, 162, 162, 0.12);
}
.bootgrid .table > thead > tr > td.active,
.bootgrid .table > tbody > tr > td.active,
.bootgrid .table > tfoot > tr > td.active,
.bootgrid .table > thead > tr > th.active,
.bootgrid .table > tbody > tr > th.active,
.bootgrid .table > tfoot > tr > th.active,
.bootgrid .table > thead > tr.active > td,
.bootgrid .table > tbody > tr.active > td,
.bootgrid .table > tfoot > tr.active > td,
.bootgrid .table > thead > tr.active > th,
.bootgrid .table > tbody > tr.active > th,
.bootgrid .table > tfoot > tr.active > th {
  background-color: rgba(162, 162, 162, 0.12);
}

/**
 * Tables - Datatables
 */
table.dataTable {
  border-bottom: 0;
}
table.dataTable,
table.dataTable th,
table.dataTable td {
  box-sizing: border-box !important;
}
table.dataTable tbody tr {
  background-color: transparent !important;
}
table.dataTable tbody tr:nth-of-type(odd) {
  background-color: rgba(162, 162, 162, 0.06) !important;
}
table.dataTable thead th,
table.dataTable thead td {
  border-bottom: 0 !important;
}
.dataTables_wrapper .dataTables_filter input,
.dataTables_wrapper select,
.dataTables_wrapper .form-control {
  box-shadow: none;
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  background-color: transparent !important;
  background-image: none;
  border-radius: 0;
  border: 0;
  border-bottom: 1px solid rgba(162, 162, 162, 0.16);
}
.dataTables_wrapper .dataTables_filter input:focus,
.dataTables_wrapper select:focus,
.dataTables_wrapper .form-control:focus {
  border-bottom-color: #03A9F4 !important;
}
.dataTables_wrapper > .dataTables_paginate .paginate_button {
  background-image: none !important;
  background-color: rgba(162, 162, 162, 0.26) !important;
  border: 0 !important;
  border-radius: 50%;
  -webkit-transition: none;
  transition: none;
}
.dataTables_wrapper > .dataTables_paginate .paginate_button:hover {
  background-color: #03A9F4 !important;
  color: #fff !important;
}
.dataTables_wrapper .dataTables_length,
.dataTables_wrapper .dataTables_filter,
.dataTables_wrapper .dataTables_info,
.dataTables_wrapper .dataTables_processing,
.dataTables_wrapper .dataTables_paginate,
.dataTables_wrapper .dataTables_paginate .paginate_button {
  color: inherit !important;
}
.dataTables_wrapper .dataTables_paginate .paginate_button.current {
  background-color: #03A9F4 !important;
  color: #fff !important;
}
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  color: #fff !important;
}
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
  background-color: rgba(162, 162, 162, 0.26) !important;
  color: inherit !important;
}
table.dataTable.no-footer {
  border-bottom: 0;
}
table.dataTable tfoot th,
table.dataTable tfoot td {
  border-top: 0;
}
.card .dataTable {
  width: 100% !important;
}
.card .dataTables_wrapper > .row,
.panel .dataTables_wrapper > .row {
  margin: 0;
}


/**
 * Utilities
 */
.m0 {
  margin: 0 !important;
}
.ml0 {
  margin-left: 0 !important;
}
.mr0 {
  margin-right: 0 !important;
}
.mt0 {
  margin-top: 0 !important;
}
.mb0 {
  margin-bottom: 0 !important;
}
.m {
  margin: 16px !important;
}
.ml {
  margin-left: 16px !important;
}
.mr {
  margin-right: 16px !important;
}
.mt {
  margin-top: 16px !important;
}
.mb {
  margin-bottom: 16px !important;
}
.m-sm {
  margin: 8px !important;
}
.ml-sm {
  margin-left: 8px !important;
}
.mr-sm {
  margin-right: 8px !important;
}
.mt-sm {
  margin-top: 8px !important;
}
.mb-sm {
  margin-bottom: 8px !important;
}
.m-lg {
  margin: 24px !important;
}
.ml-lg {
  margin-left: 24px !important;
}
.mr-lg {
  margin-right: 24px !important;
}
.mt-lg {
  margin-top: 24px !important;
}
.mb-lg {
  margin-bottom: 24px !important;
}
.m-xl {
  margin: 32px !important;
}
.ml-xl {
  margin-left: 32px !important;
}
.mr-xl {
  margin-right: 32px !important;
}
.mt-xl {
  margin-top: 32px !important;
}
.mb-xl {
  margin-bottom: 32px !important;
}
.mv {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}
.mh {
  margin-left: 16px !important;
  margin-right: 16px !important;
}
.mv-xl {
  margin-top: 32px !important;
  margin-bottom: 32px !important;
}
.mh-xl {
  margin-left: 32px !important;
  margin-right: 32px !important;
}
.mv-lg {
  margin-top: 24px !important;
  margin-bottom: 24px !important;
}
.mh-lg {
  margin-left: 24px !important;
  margin-right: 24px !important;
}
.mv-sm {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}
.mh-sm {
  margin-left: 8px !important;
  margin-right: 8px !important;
}
.p0 {
  padding: 0 !important;
}
.pl0 {
  padding-left: 0 !important;
}
.pr0 {
  padding-right: 0 !important;
}
.pt0 {
  padding-top: 0 !important;
}
.pb0 {
  padding-bottom: 0 !important;
}
.pv0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.ph0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.p {
  padding: 16px !important;
}
.pl {
  padding-left: 16px !important;
}
.pr {
  padding-right: 16px !important;
}
.pt {
  padding-top: 16px !important;
}
.pb {
  padding-bottom: 16px !important;
}
.p-sm {
  padding: 4px !important;
}
.pl-sm {
  padding-left: 4px !important;
}
.pr-sm {
  padding-right: 4px !important;
}
.pt-sm {
  padding-top: 4px !important;
}
.pb-sm {
  padding-bottom: 4px !important;
}
.p-lg {
  padding: 24px !important;
}
.pl-lg {
  padding-left: 24px !important;
}
.pr-lg {
  padding-right: 24px !important;
}
.pt-lg {
  padding-top: 24px !important;
}
.pb-lg {
  padding-bottom: 24px !important;
}
.p-xl {
  padding: 32px !important;
}
.pl-xl {
  padding-left: 32px !important;
}
.pr-xl {
  padding-right: 32px !important;
}
.pt-xl {
  padding-top: 32px !important;
}
.pb-xl {
  padding-bottom: 32px !important;
}
.pv {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}
.ph {
  padding-left: 16px !important;
  padding-right: 16px !important;
}
.pv-xl {
  padding-top: 32px !important;
  padding-bottom: 32px !important;
}
.ph-xl {
  padding-left: 32px !important;
  padding-right: 32px !important;
}
.pv-lg {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}
.ph-lg {
  padding-left: 24px !important;
  padding-right: 24px !important;
}
.pv-sm {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}
.ph-sm {
  padding-left: 4px !important;
  padding-right: 4px !important;
}
.fh {
  height: 100% !important;
}
@media only screen and (min-width: 768px) {
  .fh-sm {
    height: 100% !important;
  }
}
@media only screen and (min-width: 992px) {
  .fh-md {
    height: 100% !important;
  }
}
@media only screen and (min-width: 1200px) {
  .fh-lg {
    height: 100% !important;
  }
}
.fw {
  width: 100% !important;
}
@media only screen and (min-width: 768px) {
  .fw-sm {
    width: 100% !important;
  }
}
@media only screen and (min-width: 992px) {
  .fw-md {
    width: 100% !important;
  }
}
@media only screen and (min-width: 1200px) {
  .fw-lg {
    width: 100% !important;
  }
}
.va-middle {
  vertical-align: middle !important;
}
.va-top {
  vertical-align: top !important;
}
.va-bottom {
  vertical-align: bottom !important;
}
table.va-middle td {
  vertical-align: middle !important;
}
table.va-top td {
  vertical-align: top !important;
}
table.va-bottom td {
  vertical-align: bottom !important;
}
.b0 {
  border-width: 0 !important;
}
.bl0 {
  border-left-width: 0 !important;
}
.br0 {
  border-right-width: 0 !important;
}
.bt0 {
  border-top-width: 0 !important;
}
.bb0 {
  border-bottom-width: 0 !important;
}
.br {
  border-right: 1px solid rgba(162, 162, 162, 0.32);
}
.bl {
  border-left: 1px solid rgba(162, 162, 162, 0.32);
}
.bt {
  border-top: 1px solid rgba(162, 162, 162, 0.32);
}
.bb {
  border-bottom: 1px solid rgba(162, 162, 162, 0.32);
}
.b,
.ba {
  border-right: 1px solid rgba(162, 162, 162, 0.32);
  border-left: 1px solid rgba(162, 162, 162, 0.32);
  border-top: 1px solid rgba(162, 162, 162, 0.32);
  border-bottom: 1px solid rgba(162, 162, 162, 0.32);
}
.spr {
  margin-right: .25em;
}
.spl {
  margin-left: .25em;
}
.text-white {
  color: #fff;
}
.text-alpha {
  color: rgba(255, 255, 255, 0.5);
}
.text-alpha-inverse {
  color: rgba(0, 0, 0, 0.5);
}
.text-gray-darker {
  color: #263238;
}
.text-gray-dark {
  color: #455A64;
}
.text-gray {
  color: #607D8B;
}
.text-gray-light {
  color: #90A4AE;
}
.text-gray-lighter {
  color: #ECEFF1;
}
.text-inherit {
  color: inherit !important;
}
.text-soft {
  color: rgba(162, 162, 162, 0.66);
}
.text-light {
  color: rgba(255, 255, 255, 0.74);
}
.text-sm {
  font-size: 11.9px;
}
.text-md {
  font-size: 26.6px;
}
.text-lg {
  font-size: 42px;
}
.lh1 {
  line-height: 1;
}
.text-nowrap {
  white-space: nowrap;
}
.text-thin {
  font-weight: 100 !important;
}
.text-bold {
  font-weight: bold !important;
}
.text-normal {
  font-weight: normal !important;
}
.text-normal p {
  font-weight: normal;
}
.text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: bottom;
}
.inline {
  display: inline-block !important;
}
.block-center {
  margin: 0 auto;
}
.block {
  display: block !important;
}
.bg-white {
  background-color: #fff;
  color: inherit !important;
}
.bg-primary {
  background-color: #e2edee;
  color: #fff !important;
}
.bg-primary .text-muted {
  color: rgba(255, 255, 255, 0.5);
}
.bg-primary small {
  color: inherit;
}
.bg-success {
  background-color: #4CAF50;
  color: #fff !important;
}
.bg-success .text-muted {
  color: rgba(255, 255, 255, 0.5);
}
.bg-success small {
  color: inherit;
}
.bg-info {
  background-color: #03A9F4;
  color: #fff !important;
}
.bg-info .text-muted {
  color: rgba(255, 255, 255, 0.5);
}
.bg-info small {
  color: inherit;
}
.bg-warning {
  background-color: #FFB300;
  color: #fff !important;
}
.bg-warning .text-muted {
  color: rgba(255, 255, 255, 0.5);
}
.bg-warning small {
  color: inherit;
}
.bg-danger {
  background-color: #F44336;
  color: #fff !important;
}
.bg-danger .text-muted {
  color: rgba(255, 255, 255, 0.5);
}
.bg-danger small {
  color: inherit;
}
.bg-gray-darker {
  background-color: #263238;
  color: #fff !important;
}
.bg-gray-dark {
  background-color: #455A64;
  color: #fff !important;
}
.bg-gray {
  background-color: #607D8B;
  color: #6C7177 !important;
}
.bg-gray-light {
  background-color: #90A4AE;
  color: #6C7177 !important;
}
.bg-gray-lighter {
  background-color: #ECEFF1;
  color: #6C7177 !important;
}
.bg-transparent {
  background-color: transparent !important;
}
.bg-fade {
  background-color: transparent !important;
  background-image: -webkit-linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5) 55%, #000000);
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), color-stop(55%, rgba(0, 0, 0, 0.5)), to(#000000));
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5) 55%, #000000);
  background-repeat: no-repeat;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#ff000000', GradientType=0);
}
.bg-cover {
  background-size: cover;
}
.bg-full {
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}
.bg-center {
  background-position: center center;
  background-size: cover;
}
.thumb8 {
  width: 8px !important;
  height: 8px !important;
}
.thumb16 {
  width: 16px !important;
  height: 16px !important;
}
.thumb24 {
  width: 24px !important;
  height: 24px !important;
}
.thumb32 {
  width: 32px !important;
  height: 32px !important;
}
.thumb40 {
  width: 40px !important;
  height: 40px !important;
}
.thumb48 {
  width: 48px !important;
  height: 48px !important;
}
.thumb64 {
  width: 64px !important;
  height: 64px !important;
}
.thumb80 {
  width: 80px !important;
  height: 80px !important;
}
.thumb96 {
  width: 96px !important;
  height: 96px !important;
}
.thumb128 {
  width: 128px !important;
  height: 128px !important;
}
.initial8 {
  width: 8px !important;
  height: 8px !important;
  line-height: 8px;
  text-align: center;
  border-radius: 50%;
}
.initial16 {
  width: 16px !important;
  height: 16px !important;
  line-height: 16px;
  text-align: center;
  border-radius: 50%;
}
.initial24 {
  width: 24px !important;
  height: 24px !important;
  line-height: 24px;
  text-align: center;
  border-radius: 50%;
}
.initial32 {
  width: 32px !important;
  height: 32px !important;
  line-height: 32px;
  text-align: center;
  border-radius: 50%;
}
.initial40 {
  width: 40px !important;
  height: 40px !important;
  line-height: 40px;
  text-align: center;
  border-radius: 50%;
}
.initial48 {
  width: 48px !important;
  height: 48px !important;
  line-height: 48px;
  text-align: center;
  border-radius: 50%;
}
.initial64 {
  width: 64px !important;
  height: 64px !important;
  line-height: 64px;
  text-align: center;
  border-radius: 50%;
}
.initial80 {
  width: 80px !important;
  height: 80px !important;
  line-height: 80px;
  text-align: center;
  border-radius: 50%;
}
.initial96 {
  width: 96px !important;
  height: 96px !important;
  line-height: 96px;
  text-align: center;
  border-radius: 50%;
}
.initial128 {
  width: 128px !important;
  height: 128px !important;
  line-height: 128px;
  text-align: center;
  border-radius: 50%;
}
.table-fixed {
  table-layout: fixed;
  width: 100%;
}
.shadow-clear {
  box-shadow: 0 0 0 #000 !important;
}
.radius-clear {
  border-radius: 0 !important;
}
.rounded {
  border-radius: 100px;
}
.item-grab {
  cursor: -webkit-grab;
  cursor: -moz-grab;
}
.item-grabbing {
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
}
.clickable {
  cursor: pointer !important;
}
.rel-wrapper {
  position: relative !important;
}
.oh {
  overflow: hidden;
}
.oa {
  overflow: auto;
}
.icon-18 {
  font-size: 18px;
  vertical-align: -18%;
}
.icon-24 {
  font-size: 24px;
  vertical-align: -24%;
}
.icon-36 {
  font-size: 36px;
  vertical-align: -36%;
}
.icon-48 {
  font-size: 48px;
  vertical-align: -48%;
}
.icon-2x {
  font-size: 2em;
}
.icon-3x {
  font-size: 3em;
}
.icon-4x {
  font-size: 4em;
}
.icon-5x {
  font-size: 5em;
}
.icon-lg {
  font-size: 1.33333333em;
  line-height: 0.75em;
  vertical-align: -15%;
}
.icon-fw {
  display: inline-block;
  width: 1.28571429em;
  text-align: center;
}
.icon-dark {
  color: rgba(0, 0, 0, 0.54);
}
.icon-dark.icon-inactive {
  color: rgba(0, 0, 0, 0.26);
}
.icon-light {
  color: #ffffff;
}
.icon-light.icon-inactive {
  color: rgba(255, 255, 255, 0.3);
}
.wd-xxs {
  width: 60px;
}
.wd-xs {
  width: 90px;
}
.wd-sm {
  width: 150px;
}
.wd-sd {
  width: 200px;
}
.wd-md {
  width: 240px;
}
.wd-lg {
  width: 280px;
}
.wd-xl {
  width: 320px;
}
.wd-xxl {
  width: 360px;
}
.wd-wide {
  width: 100%;
}
.wd-auto {
  width: auto;
}
.wd-zero {
  width: 0;
}
.link-unstyled {
  text-decoration: none !important;
  outline: none !important;
}
textarea.no-resize {
  resize: none;
  max-width: 100%;
  min-width: 100%;
}
.shadow-z0 {
  box-shadow: 0 0 0 #000;
}
.shadow-z1 {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.14), 0 2px 2px 0 rgba(0, 0, 0, 0.098), 0 1px 5px 0 rgba(0, 0, 0, 0.014);
}
.shadow-z2 {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.14), 0 4px 5px 0 rgba(0, 0, 0, 0.098), 0 1px 10px 0 rgba(0, 0, 0, 0.014);
}
.shadow-z3 {
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.14), 0 6px 10px 0 rgba(0, 0, 0, 0.098), 0 1px 18px 0 rgba(0, 0, 0, 0.014);
}
.shadow-z4 {
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.14), 0 8px 10px 1px rgba(0, 0, 0, 0.098), 0 3px 14px 2px rgba(0, 0, 0, 0.014);
}
.shadow-z5 {
  box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.14), 0 16px 24px 2px rgba(0, 0, 0, 0.098), 0 6px 30px 5px rgba(0, 0, 0, 0.014);
}
.image-list > a {
  margin-left: -8px;
}
.image-list > a:first-child {
  margin-left: 0;
}
.image-list > a:last-of-type {
  margin-right: 8px;
}
.list-group-unstyle > .list-group-item {
  border-left: 0;
  border-right: 0;
}
.list-group-unstyle > .list-group-item:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.list-group-unstyle > .list-group-item:last-child {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.icons-list {
  margin: 40px auto;
  padding-left: 0;
  width: 100%;
  list-style: none;
  text-align: center;
  font-size: 1px;
}
.icons-list > li {
  position: relative;
  z-index: 0;
  display: inline-block;
  padding: 22px;
  width: 84px;
  border-radius: 4px;
  list-style: none;
  text-align: center;
  font-weight: normal;
  font-size: 32px;
  cursor: pointer;
}
.icons-list > li:hover {
  background-color: rgba(255, 255, 255, 0.9);
  color: #03A9F4;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.14), 0 4px 5px 0 rgba(0, 0, 0, 0.098), 0 1px 10px 0 rgba(0, 0, 0, 0.014);
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.reader-block p {
  margin: 16px auto;
  font-size: 16px;
  line-height: 28px;
  font-weight: normal;
}
.reader-block p > img {
  max-width: 100%;
  margin: 32px auto;
}
.box-placeholder {
  margin-bottom: 15px;
  padding: 20px;
  border: 1px dashed rgba(162, 162, 162, 0.26);
  background: transparent;
  color: inherit;
}
.box-placeholder > :last-child {
  margin-bottom: 0;
}
.box-placeholder.box-placeholder-lg {
  padding-top: 80px;
  padding-bottom: 80px;
}

body {
  margin: 0;
  padding: 0;
  font-family: kiro, sans-serif !important;
}

/* old color: #2196f3;*/

.sidebar-nav li a.selected {
  color: #133d55;
}
.sidebar-nav li a .glyphicon {
  margin-right: 10px;
}
tr.clickable td {
  cursor: pointer;
}
td.clickable {
  text-decoration: underline;
}
tr.clickable td.clickable {
  text-decoration: none;
}
td.clickable:hover {
  cursor: pointer;
  background-color: #eee;
}
tr.inactive td {
  color: #ccc;
}
.light-blue-background, tr.light-blue-background td, tr.light-blue-background th {
  background-color: #e3f2fd;
}

.image-gallery{
  background-size: cover;
  background-position: center;
  height: 100px;
  position: relative;
  overflow: hidden;
}

.image-gallery span{
  background: rgba(0,0,0,.6);
  position: absolute;
  right: 5px;
  bottom: 5px;
  border-radius: 2px;
  color: #fff;
  padding: 2px;
}
.image-gallery-no-images{
  font-style: italic;
  text-align: center;
}

.card .negative-margin {
  margin-left: -15px;
}
.table > tbody > tr > td.registration-thumbnail-container {
  padding-right: 0;
}
.registration-thumbnail {
  background: #eee center;
  background-size: cover;
  height: 80px;
  width: 80px;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  float: left;
}
.registration-thumbnail div{
  background: rgba(0,0,0,.5);
  color: #fff;
  position: absolute;
  bottom: 0;
  width: 100%;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 2px 5px;
  text-align: center;
  font-size: 8pt;
}
.registration-thumbnail img {
  max-width: 100%;
  max-height: 100%;
}
.registration-number {
  font-size: 90%;
  color: #777;
  margin-right: 6px;
}
.registration-date {
  font-size: 90%;
  color: #777;
}
.registration-edits {
  font-size: 90%;
  color: #777;
  font-style: italic;
}
.callback-required {
  color: #ff5722;
  font-weight: bold;
}
.callback-image {
  width: 14px;
  margin-left: 4px;
  margin-top: -2px;
}
.priority-circle {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 5px;
}

.registration-container {
  /*position: fixed;*/
}
.registration-container .registration-thumbnail {
  margin-right: 5px;
  cursor: pointer;
}
.registration-details-table {
  width: 100%;
}
.registration-details-table th {
  /*border-top: 1px solid rgba(0, 0, 0, 0.045);*/
  background-color: rgba(162, 162, 162, 0.06);
  padding: 8px 8px 0px 8px;
  font-size: 95%;
  /*font-weight: normal;*/
  /*font-style: italic;*/
}
.registration-details-table td {
  background-color: rgba(162, 162, 162, 0.06);
  padding: 0px 8px 8px 8px;
  font-size: 95%;
  vertical-align: top;
  /*font-weight: bold;*/
}
.registration-description-summary {
  margin-top: 8px;
  font-size: 90%;
  color: #555;
}
.registration-description, .registration-reaction {
  background-color: rgba(162, 162, 162, 0.06);
  white-space: pre-line;
  padding: 6px;
}
.registration-container .registration-description, .registration-container .registration-reaction {
  margin: 20px 0;
}
.registration-container h4{
  font-size: 8pt;
  margin: 0;
  font-weight: bold;
  text-transform: uppercase;
  color: #666;
}
.registration-container textarea {
  width: 100%;
  min-height: 100px;
}
.registration-image {
  clear: both;
}
.registration-image img {
  margin-top: 10px;
  max-width: 100%;
  cursor: pointer;
}
.spinner-centered {
  /*margin-top: 20px;*/
  display: block;
  margin: 0 auto;
}
.spinner-centered.bg-white {
  padding: 10px;
  border-radius: 10px;
}

/** Toolbar **/
.toolbar {
  background-color: #133d55;
  color: #fff;
  padding: 10px;
  font-size: 110%;
  min-height: 50px;
}
.toolbar .light {
  padding-top: 5px;
  /*text-align: center;*/
  font-style: italic;
  height: 40px;
}
.toolbar .light span {
  opacity: 0.8;
}
.toolbar .title {
  padding-top: 5px;
  opacity: 0.8;
  float: left;
}
.toolbar .title strong {
  opacity: 1;
}
.toolbar select {
  color: #000;
  font-style: normal;
}
.toolbar .search input {
  width: 70%;
  color: #000;
}
.toolbar .search button {
  color: #000;
}
.bottombar{
  /*background: #90A4AE;*/
  padding: 10px 10px 15px;
  text-align: center;
  line-height: 28px;
  /*color: #ECEFF1;*/
}
.bottombar .light{
  color: #555;
  /*color: #CFD8DC;*/
}

img.checkbox {
  width: 20px;
}
.table.users td {
  word-break: break-all;
}
.table-condensed.table >tbody > tr > td {
  padding: 5px;
}
