body {
  margin: 0;
  padding: 0;
  font-family: kiro, sans-serif !important;
}

/* old color: #2196f3;*/

.sidebar-nav li a.selected {
  color: #133d55;
}
.sidebar-nav li a .glyphicon {
  margin-right: 10px;
}
tr.clickable td {
  cursor: pointer;
}
td.clickable {
  text-decoration: underline;
}
tr.clickable td.clickable {
  text-decoration: none;
}
td.clickable:hover {
  cursor: pointer;
  background-color: #eee;
}
tr.inactive td {
  color: #ccc;
}
.light-blue-background, tr.light-blue-background td, tr.light-blue-background th {
  background-color: #e3f2fd;
}

.image-gallery{
  background-size: cover;
  background-position: center;
  height: 100px;
  position: relative;
  overflow: hidden;
}

.image-gallery span{
  background: rgba(0,0,0,.6);
  position: absolute;
  right: 5px;
  bottom: 5px;
  border-radius: 2px;
  color: #fff;
  padding: 2px;
}
.image-gallery-no-images{
  font-style: italic;
  text-align: center;
}

.card .negative-margin {
  margin-left: -15px;
}
.table > tbody > tr > td.registration-thumbnail-container {
  padding-right: 0;
}
.registration-thumbnail {
  background: #eee center;
  background-size: cover;
  height: 80px;
  width: 80px;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  float: left;
}
.registration-thumbnail div{
  background: rgba(0,0,0,.5);
  color: #fff;
  position: absolute;
  bottom: 0;
  width: 100%;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 2px 5px;
  text-align: center;
  font-size: 8pt;
}
.registration-thumbnail img {
  max-width: 100%;
  max-height: 100%;
}
.registration-number {
  font-size: 90%;
  color: #777;
  margin-right: 6px;
}
.registration-date {
  font-size: 90%;
  color: #777;
}
.registration-edits {
  font-size: 90%;
  color: #777;
  font-style: italic;
}
.callback-required {
  color: #ff5722;
  font-weight: bold;
}
.callback-image {
  width: 14px;
  margin-left: 4px;
  margin-top: -2px;
}
.priority-circle {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 5px;
}

.registration-container {
  /*position: fixed;*/
}
.registration-container .registration-thumbnail {
  margin-right: 5px;
  cursor: pointer;
}
.registration-details-table {
  width: 100%;
}
.registration-details-table th {
  /*border-top: 1px solid rgba(0, 0, 0, 0.045);*/
  background-color: rgba(162, 162, 162, 0.06);
  padding: 8px 8px 0px 8px;
  font-size: 95%;
  /*font-weight: normal;*/
  /*font-style: italic;*/
}
.registration-details-table td {
  background-color: rgba(162, 162, 162, 0.06);
  padding: 0px 8px 8px 8px;
  font-size: 95%;
  vertical-align: top;
  /*font-weight: bold;*/
}
.registration-description-summary {
  margin-top: 8px;
  font-size: 90%;
  color: #555;
}
.registration-description, .registration-reaction {
  background-color: rgba(162, 162, 162, 0.06);
  white-space: pre-line;
  padding: 6px;
}
.registration-container .registration-description, .registration-container .registration-reaction {
  margin: 20px 0;
}
.registration-container h4{
  font-size: 8pt;
  margin: 0;
  font-weight: bold;
  text-transform: uppercase;
  color: #666;
}
.registration-container textarea {
  width: 100%;
  min-height: 100px;
}
.registration-image {
  clear: both;
}
.registration-image img {
  margin-top: 10px;
  max-width: 100%;
  cursor: pointer;
}
.spinner-centered {
  /*margin-top: 20px;*/
  display: block;
  margin: 0 auto;
}
.spinner-centered.bg-white {
  padding: 10px;
  border-radius: 10px;
}

/** Toolbar **/
.toolbar {
  background-color: #133d55;
  color: #fff;
  padding: 10px;
  font-size: 110%;
  min-height: 50px;
}
.toolbar .light {
  padding-top: 5px;
  /*text-align: center;*/
  font-style: italic;
  height: 40px;
}
.toolbar .light span {
  opacity: 0.8;
}
.toolbar .title {
  padding-top: 5px;
  opacity: 0.8;
  float: left;
}
.toolbar .title strong {
  opacity: 1;
}
.toolbar select {
  color: #000;
  font-style: normal;
}
.toolbar .search input {
  width: 70%;
  color: #000;
}
.toolbar .search button {
  color: #000;
}
.bottombar{
  /*background: #90A4AE;*/
  padding: 10px 10px 15px;
  text-align: center;
  line-height: 28px;
  /*color: #ECEFF1;*/
}
.bottombar .light{
  color: #555;
  /*color: #CFD8DC;*/
}

img.checkbox {
  width: 20px;
}
.table.users td {
  word-break: break-all;
}
.table-condensed.table >tbody > tr > td {
  padding: 5px;
}